<template>
    
    <div class="card text-center d-flex justify-content-center" style="height: 100vh; overflow-y: hidden;" >
       
        <div class="">
           <h1>403 FORBIDDEN ERROR</h1> 
            
           <h1 class="text-danger">Sorry, You do not have permission to access this page </h1> 
           <button class="btn btn-dark mt-4 btn-lg" @click="goBack">Go Back</button>
        </div>
       
      </div>

    
</template>
<script>
export default {
    name: "ForbiddenPage",

    methods:{
        goBack(){
            this.$router.back()
        }
    }
}
</script>
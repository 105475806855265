
<style scoped>
:deep(.modal-container) {
  display: grid;
  justify-content: center;
  align-items: center;
  width: 100%;
}

:deep(.modal-content) {
  position: relative;
  display: flex;
  flex-direction: column;

  margin: 0 1rem;
  padding: 1rem;
  border: 1px solid #e2e8f0;
  border-radius: 0.25rem;
  background: #fff;
}

.modal__title {
  font-weight: 700;
  text-align: center;
  text-decoration: underline;
  color: #fcb913;
  font-size: 30px;
  text-transform: uppercase;
  font-family: "Barlow Condensed", sans-serif !important;
}

.modal__content {
  overflow-y: auto;
  padding: 21px;
  width: 800px;
  height: 500px;
}

.modal__close {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  width: 50px;
}
</style>

<style scoped>
.dark-mode div:deep(.modal-content) {
  border-color: #2d3748;
  background-color: #1a202c;
}

#centerlineTextDiv {
  height: 1px;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  text-align: center;
  position: relative;
}

#centerlineTextDiv>span {
  position: relative;
  top: -0.8em;
  background: #fcb913;
  display: inline-block;
}
</style>

<template>
  <VueFinalModal
    v-model="openModal"
    classes="modal-container"
    content-class="modal-content"
    :clickToClose="false"
    class="popup"
  >
    <button class="modal__close btn btn-warning btn-sm" @click="handleClose()">
      <i class="fa fa-times" aria-hidden="true"></i>
    </button>

    <span class="modal__title text-center">
      Add PRIZE MONEY FOR
      <br />
      {{ event?.name || "Event Name" }}
    </span>

    <p class="text-center mt-2"> Event Date/Time:  {{ event?.datetime || "" }}</p>

    <Form @submit="submitHandler" ref="addPrizeForm"
     :validation-schema="schema"
      v-slot="{ errors }"
    >
      <div class="modal__content">
        <hr />
        <div class="row">
          <section class="col-lg-12 col-md-6">
            <div class="form-group">
              <label for="templateId">- Select Prize Money Template</label>
              <Field
                id="templateId"
                name="templateId"
                class="form-control"
                as="select"
                v-model="selectedTemplateId"
                :class="{ 'is-invalid': errors.templateId }"
             
              >
                <option
                  v-for="(item, i) in templates"
                  :value="item._id"
                  :key="i"
                >{{ item.templateName }}</option>
              </Field>
              <div class="invalid-feedback">{{ errors.templateId }}</div>
            </div>
          </section>
     
        </div>

        <div v-if="!selectedTemplateId || selectedTemplateId">
          <p class="text-danger">{{ deleteActionMsg }}</p>
        </div>
      </div>

      <div :class="`d-flex ${event?.prizeMoneyTemplateId!='' && event?.prizeMoneyTemplateId ? 'justify-content-between' : 'justify-content-end'}`">
         <button class="btn btn-lg bg-danger text-white" 
         v-if="event?.prizeMoneyTemplateId!='' && event?.prizeMoneyTemplateId"
         type="button" @click="handleRemoval"> Remove From Event  <i class="fa fa-trash"> </i>  </button>
        <button class="btn btn-lg bg-dark text-white" style="width: 200px;" type="submit">Add To Event</button>
      </div>
    </Form>
  </VueFinalModal>
</template>

<script>
import { VueFinalModal } from "vue-final-modal";
import { Form, Field } from "vee-validate";
import { mapActions } from "vuex";
import * as Yup from "yup";
import Swal from "sweetalert2";


export default {
  name: "AddPrizeMoneyModal",
  props: ["showAddPrizeModal", "event"],
  emits:['listenerChild'],

  watch: {
    showAddPrizeModal: function (value) {
      this.openModal = value;
    },
    event: function (newEvent) {
       this.selectedTemplateId = newEvent?.prizeMoneyTemplateId || "";
    },
    
  
},
  computed: {},

  data() {
    return {
      openModal: this.showAddPrizeModal,
      selectedTemplateId: this.event?.prizeMoneyTemplateId || "",
      deleteActionMsg: "",
      templates: []
    };
  },

  components: {
    VueFinalModal: VueFinalModal,
    Field,
    Form
  },

  setup() {
    const schema = Yup.object().shape({
      templateId: Yup.string().required("Please choose a template"),
    });

    return {
      schema
    };
  },

  async mounted() { 

    const response= await this.getEventsPrizeMoneyTemplates()
    if(!response?.error && response.code==200){
        this.templates= response?.data || []
    }
   },

  methods: {
    ...mapActions("event", ["getEventsPrizeMoneyTemplates", "removePrizeTemplateFromEvent", "addPrizeTemplateToEvent"]),

    handleClose(extraParams={}) {
      this.openModal = false;
 
      this.$emit("listenerChild", {
        type: "add-prize-money",
        popup: false,
        ...extraParams
      });
    },

    async submitHandler() {

      if (!this.selectedTemplateId || this.selectedTemplateId === "") {
        return
      }

      const response = await this.addPrizeTemplateToEvent({
        eventId: this.event?.serverEventId,
        templateId: this.selectedTemplateId

      })

      if(!response.error && response?.code==200){
             this.selectedTemplateId= response?.data?.prizeMoneyTemplateId
             this.handleClose({apiCall: true})
          } 
    },

    async handleRemoval() {
      if (!this.selectedTemplateId || this.selectedTemplateId == "") {
        this.deleteActionMsg = "No Template is attached to the event."
         return
      }

      Swal.fire({
        title: "Are you sure?",
        text: `Do you want to remove this template from this event`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes",
        cancelButtonText: "No",
      }).then(async (result) => {
        if (result.isConfirmed) {
          const response = await this.removePrizeTemplateFromEvent({
            eventId: this.event?.serverEventId,
            templateId: this.selectedTemplateId 
          });
          if(!response.error && response?.code==200){
             this.handleClose({apiCall: true})
          } 
        }
      }); 
    }
  }
};
</script>
<template>
 
  <page-heading>Add New User</page-heading>

  <AdminNavigation />


  <div class="container">
   <page-loader v-if="loading" />
    <Form
      class="player-form"
      @submit="submitHandler"
      :validation-schema="schema"
      v-slot="{ errors }"
    >
      <div id="accordionDiv">
        <ul>
          <li>
            
            <h2>REQUIRED FIELDS (*)</h2>

            <div class="row">
              <section class="col-lg-6 col-md-6">
                <div class="form-group">
                  <label for="role">Role</label>
                  <Field
                    id="role"
                    name="role"
                    class="form-control"
                    as="select"
                    @change="selectedRole = $event.target.value"
                    :class="{ 'is-invalid': errors.role }"
                    v-model="selectedRole"
                    
                   >
                <option :value="item" v-for="(item, i) in roles" :key="i">
                      {{ item }}
                 </option>
                  </Field>
                  <div class="invalid-feedback">{{ errors.role }}</div>
                </div>
              </section>

             
               <section class="col-lg-6 col-md-6">
                <div class="form-group">
                  <label for="name">FULL NAME</label>
              
                  <Field
                    name="name"
                    id="name"
                    type="text"
                    class="form-control"
                    :class="{ 'is-invalid': errors.name }"
                  />
              <div class="invalid-feedback"> {{ errors.name }}</div>
                </div>
              </section>
    
            

          <section class="col-lg-6 col-md-6">
             <div class="form-group">
                  <label for="email">EMAIL</label>
                  <Field name="email"
                    id="email"
                    type="text"
                    class="form-control"
                    :class="{ 'is-invalid': errors.email }"
                  />
                  <div class="invalid-feedback">{{ errors.email }}</div>
                </div>
              </section>

  
            
            </div>
          </li>

          <li>
           
            <!-- <i></i> -->
            <h2>NOTE</h2>

            <div class="row">
           <section class="col-lg-12 col-md-12">
                <div class="form-group">
                  <h6> 1) Please make sure you entered the correct email address of the user. After form submission user will recieve an auto generated email with password in order to login into the admin dashboard.</h6>
                  <h6> 2)  Please check your Junk/Spam Folder too in case you dont recieve email straight to your inbox</h6>
                </div>
          </section>

            </div>
          </li>
        </ul>

        <div class="row">
          <section class="col-lg-3 col-md-6">
            <label class="hide-mobile">&nbsp;</label>
          </section>

          <section class="col-lg-3 col-md-6">
            <label class="hide-mobile">&nbsp;</label>
          </section>

          <section class="col-lg-3 col-md-6">
            <label class="hide-mobile">&nbsp;</label>
          </section>

          <section class="col-lg-3 col-md-6">
            <label class="hide-mobile">&nbsp;</label>
            <button type="submit" class="btn btn-primary">Submit</button>
          </section>
        </div>
      </div>
    </Form>
  </div>
</template>

<style scoped>
body {
  overflow-x: hidden;
}

section.accordionDiv {
  padding-top: 2em;
  padding-bottom: 3em;
}

#accordionDiv ul {
  text-align: left;
}

#accordionDiv h1 {
  color: #fcb913;
  margin-bottom: 30px;
  margin-top: 0;
}

#accordionDiv h2 {
  color: #fcb913;
  font-family: "hm_light", sans-serif;
  font-size: 20px;
  line-height: 34px;
  text-align: left;
  padding: 15px 15px 0;
  text-transform: none;
  font-weight: 300;
  letter-spacing: 1px;
  display: block;
  margin: 0;
  cursor: pointer;
  transition: 0.2s;
}

#accordionDiv div {
  text-align: left;
  font-family: "hm_light", sans-serif;
  font-size: 14px;
  line-height: 1.45;
  position: relative;

  will-change: max-height;
  opacity: 1;
  transform: translate(0, 0);
  margin-top: 5px;
  margin-bottom: 9px;
  transition: 0.3s opacity, 0.6s max-height;
  hyphens: auto;
  z-index: 2;
  padding-right: 10px;
  padding-left: 10px;
}

#accordionDiv ul {
  list-style: none;
  perspective: 900;
  padding: 0;
  margin: 0;
}

#accordionDiv ul li {
  position: relative;
  overflow: hidden;
  padding: 0;
  margin: 0;
  background: #fff;
  box-shadow: 0 3px 10px -2px rgba(0, 0, 0, 0.1);
  -webkit-tap-highlight-color: transparent;
}

#accordionDiv ul li+li {
  margin-top: 15px;
}
</style>

<script>
import { Field, Form } from "vee-validate";
import { mapActions, mapGetters } from "vuex";
import * as Yup from "yup";


import $ from 'jquery';
import AdminNavigation from "../../../../shared/components/AdminNavigation.vue";

export default {
  name: "AddNewAdminUser",
  components: {
    Form,
    Field,
    AdminNavigation
  },

  computed: {
    ...mapGetters('auth', ['loggedIn']),
  },
  data() {
    return {
      loading: false,
      roles: ['whoosh-super-admin', 'whoosh-admin', 'whoosh-finance-admin'],
      selectedRole: "",
    };
  },
  setup() {
    const schema = Yup.object().shape({
      name: Yup.string().required("Name is required"),
      email: Yup.string().required("Email is required").email(),
      role: Yup.string().required("Role is required"),
    });
    return {
      schema,
    };
  },

  methods: {
    ...mapActions("adminUser", ["addNewAdminUser"]),

    async submitHandler(formData) {
      $('#accordionDiv').addClass('blurry');
      this.loading = true
      const result = await this.addNewAdminUser(formData)
      if (!result.error) {
        this.$router.push({
          name: "ListAllAdminUsers",
        });

      }
      setTimeout(function () {
        $('#accordionDiv').removeClass("blurry");
      }, 600);
      this.loading = false;

    },

  },
};
</script>

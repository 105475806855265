<template>
 
  <FinanceRoleHeaderVue
  v-if="user?.role==='whoosh-finance-admin'"
  :loggedIn="loggedIn"
  :user="user"
  />
    <router-view> </router-view>
   
</template>

<script>
import {  mapGetters } from 'vuex';
import FinanceRoleHeaderVue from '../../views/admin/components/FinanceRoleHeader.vue'

export default {
  name: 'AdminLayout',

  components:{
    FinanceRoleHeaderVue
  },

  computed:{
    ...mapGetters('auth', ['loggedIn', 'user']),
  },

  mounted(){
   }




}
</script>
<style scoped>
.templatesTable > thead {
    background-color: goldenrod !important;
}

</style>
<template>
    <page-heading>
          Prize Money Templates
    </page-heading>

    <AdminNavigation
    @listenerChild="listenerChild"
    :eventId="eventId" 
    :event="event"
    :filterData="filterData"
    :eventListPage="$route.params.eventListPage"/>


    <div class="container mt-4">
    
        <vue3-datatable 
            class="templatesTable" 
            headClass="bg-dark" 
            :sortable="true"
            sortColumn="_id"
            sortDirection="desc"
            :rows="rows" 
            :columns="cols" 
            :loading="loading" 
            :totalRows="total_rows" 
            :isServerMode="false" 
            :pageSize="params.pagesize" 
            @change="changeServer">

            <template #categories="data">
                <p>{{ getUniqueCategories(data.value.categories) }}</p>
            </template>
 
            <template #updatedAt="data">
                <p>{{  convertISODateTime(data.value.updatedAt) }}</p>
            </template>

           
            <template #action="data"> 
               <div class="d-flex flex-row gap-4 ">
                  <button class="btn btn-md btn-dark" @click="handleEditBtn(data.value._id)">Edit</button> 
                   <button class="btn btn-md btn-dark mx-2" @click="handleRemoval(data.value._id)">Delete</button>
            </div>

             

            </template>


        </vue3-datatable>
    </div>





</template>

<script>
import Vue3Datatable from '@bhplugin/vue3-datatable';
import '@bhplugin/vue3-datatable/dist/style.css';
import AdminNavigation from "@/shared/components/AdminNavigation.vue";
import { convertISODateTime} from "../../../../shared/events/event.helpers.ts";

import {mapActions} from 'vuex';

export default {
    name: 'PrizeMoneyTemplatesPage',
    components: {
        Vue3Datatable,
        AdminNavigation
    },
    data() {
        return {
            loading: true,
            convertISODateTime: convertISODateTime,
            total_rows: 0,
            params: {
                current_page: 1,
                pagesize: 10
            },
            rows: null,
            cols: [
                { field: '_id', title: 'ID', isUnique: true },
                { field: 'templateName', title: 'Template Name' },
                { field: 'categories', title: 'Assign Categories'},  
                
                { field: 'updatedAt', title: 'updated AT'},
                { field: 'action', title: 'Action',},
 
            ]
        };
    },

    computed:{
       
    },
    mounted() {
        this.getTemplates();
    },
    methods: {

        ...mapActions("event", ["getEventsPrizeMoneyTemplates", "removeEventPrizeMoneyTemplate"]),


        getUniqueCategories(obj){
             return Object.keys(obj)
        },

        async getTemplates() {
            try {
                this.loading = true;
                const response =  await this.getEventsPrizeMoneyTemplates() 
                 this.rows = response?.data;
                this.total_rows = response?.data?.length;
            } catch (error) {
                console.error("Error fetching users:", error);
            }

            this.loading = false;
        },

       async handleRemoval(templateId){
            const res= await this.removeEventPrizeMoneyTemplate({_id: templateId})
            if(res?.code==200 && !res?.error){
                window.location.reload();
            } 
        },

        handleEditBtn(templateId){
            return this.$router.push(
                { name: "AddNewPrizeMoneyTemplate", query: { templateId } }
            )
        },

        changeServer(data) {
            this.params.current_page = data.current_page;
            this.params.pagesize = data.pagesize;
            this.getTemplates();
        },

        listenerChild(receivedData){
            const {type, popup}= receivedData
            console.log(type,popup);
        }
    }
};
</script>

const config = {
  BASE_URL: process.env.VUE_APP_BASE_URL,
  API_URL: process.env.VUE_APP_API_URL,
  BASE_JAVA_API_URL: process.env.VUE_BASE_JAVA_API_URL,
  RESULT_PUBLISH_EVENT_ORIGIN:
    process.env.NODE_ENV === "development"
      ? "https://service20.my-whoosh.com/"
      : process.env.NODE_ENV === "staging"
        ? "https://stage-events.my-whoosh.com/"
        : "https://mywhoosh.com/",
  IS_MAR_ONWARDS_SERIES_EVENT: 1678808146,
  IS_MAY_ONWARDS_SRC_EVENT: 1682899200, //  old -- 1684136948
};

export default config;

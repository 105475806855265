export const EventFormatType = {
    TIME_BASED: "TIME_BASED",
    POINTS_BASED: "POINTS_BASED",
    TIME_POINTS_BASED: "TIME_POINTS_BASED",
    TRIATHLON: "TRIATHLON"
}

export const javaActionCodesEnum = {
    segEnd: 8013,
    dayEnd: 8011,
    dayStart: 8010
}

export const SportsTypeModeTypeEnum ={
    run : "1",
    ride : "0",
    swim : "2",
    overall : "overall"
}
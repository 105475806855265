
<style scoped>
#files-table {
    border: 1px solid #ccc;
    border-collapse: collapse;
    /* margin: 50px; */
    /* padding: 50; */
    width: 100%;
    table-layout: fixed;
}

#files-table #table-title {
    font-size: 1.5em;
    margin: .5em 0 .75em;
    text-align: center;
    padding: 20px;


}

#files-table tr {
    background-color: #f8f8f8;
    border: 1px solid #ddd;
    padding: .35em;
}

#files-table th,
#files-table td {
    padding: .625em;
    text-align: center;
}

#files-table th {
    font-size: .85em;
    letter-spacing: .1em;
    text-transform: uppercase;
}

@media screen and (max-width: 600px) {
    #files-table {
        border: 0;
    }

    #files-table #table-title {
        font-size: 1.3em;
        text-align: center;
        padding: 20px
    }

    #files-table thead {
        border: none;
        clip: rect(0 0 0 0);
        height: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
        width: 1px;
    }

    #files-table tr {
        border-bottom: 3px solid #ddd;
        display: block;
        margin-bottom: .625em;
    }

    #files-table td {
        border-bottom: 1px solid #ddd;
        display: block;
        font-size: .8em;
        text-align: right;
    }

    #files-table td::before {

        content: attr(data-label);
        float: left;
        font-weight: bold;
        text-transform: uppercase;
    }

    #files-table td:last-child {
        border-bottom: 0;
    }
}
</style>


<style scoped>
:deep(.modal-container) {
    display: grid;
    justify-content: center;
    align-items: center;
    width: 100%;
}

:deep(.modal-content) {
    position: relative;
    display: flex;
    flex-direction: column;
    /* max-height: 50%; */
    margin: 0 1rem;
    padding: 1rem;
    border: 1px solid #e2e8f0;
    border-radius: 0.25rem;
    background: #fff;
}

.modal__title {
    margin: 0 2rem 0 0;

    font-weight: 700;
    text-align: center;
    text-decoration: underline;

    color: #fcb913;
    font-size: 30px;

    text-transform: uppercase;
    font-family: 'Barlow Condensed', sans-serif !important;

}

.modal__content {
    /* flex-grow: 1; */
    overflow-y: auto;
    padding: 21px;
    width: 800px;
    height: 500px;
}

.modal__close {
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
    width: 50px;
}
</style>

<style scoped>
.dark-mode div:deep(.modal-content) {
    border-color: #2d3748;
    background-color: #1a202c;
}

.bg-leaderboard {
    background-color: white;
    border: 1px solid #e1e1e1;
    gap: 30px;
}

.selectStagesDropdown {
    width: 170px;
    font-family: 'Poppins', sans-serif !important;
}
</style>

<template>
   
    <!-- Page Header Component -->
    <ResultHeader 
    :event="event" 
    :isStages="true" 
    :isSeries="true" 
    :stages="stages" 
    />

    <!-- Result Page navigation -->
    <AdminNavigation 
    :eventId="eventId" 
    :event="event" 
    :isTriathlon="true"
    :filterResult="filterResult" 
    :playersCount="playersCount"
    :isSeriesEvent="isSeriesEvent"
    :isStages="isStages"
    :currentDayId="currentDayId" 
    />


    <!-- Filters -->
    <div class="container"   >

        <!-- Categories filter -->
        <div class="btn-wrap mb-2">
          <div class="white-top-bg">
            <div class="row justify-content-md-center">
              <div class="col-sm-12 mb-10 tx-c">
                <div class="btn-group btn-css" role="group" aria-label="Basic example">
                  <button type="button" v-for="(data, k) in getEventCategories" :key="k" class="btn btn-outline-secondary"
                          @click="applyFilters({ categoryId: data.CategoryId })">
                    <strong>{{ data.Label }}</strong>
                  </button>
    
                  <button
                      type="button" :class="{
                          'd-none': this.activeFilters &&
                              Object.keys(this.activeFilters).length === 0
                      }"
                      class="btn btn-outline-secondary" @click="applyFilters({}, true)">
                    RESET
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>



        <div class="text-danger">
            <p>Note:</p>
            <p>1- To Update Player please click on each table row for particular player</p>
            <p>2- Update player allowed only in Stages Segments Only</p>
            <p>3- Update NOT ALLOWED in Overall & Transition Time Leaderboards</p>
          </div>



        <div class="container-fluid">
            <div class="row d-flex justify-content-between pb-2 mt-2">
              
              <!-- Stages -->
              <div class="d-flex">
                <select @change="updateStage($event.target.value)" class="bg-leaderboard selectStagesDropdown">
                  <option
                  v-for="(stage, k) in getStages"
                  :value="`${stage.value}+${stage.updateResultValue}`"
                  :key="k"
                  >
                    <strong>{{ stage.label }}</strong>
                  </option>
                </select>
            </div>

            <!-- Stage Segments/Leaderboards (SportsType) -->
              <div class="d-flex flex-row bg-leaderboard gap-2">
                    <a
                      v-for="(item, index) in  allLeaderboards " :key="index"
                      class="p-2"
                      style="cursor: pointer;"
                      :data-id="index"
                      @click="updateSportsType(item.id)"
                    >
                      <span :class="selectedSportsType === item.id ? 'bg-warning' : ' '" class="p-1 text-dark">{{ item.name }} </span>
                    </a>    
              </div>

            </div>
          </div>


          <div class="row">
              <!-- Table Data -->
              <DataTableTriathlon 
              v-if="event"
              @tableRowClick="tableRowClick"
              :eventData="event"
              :selectedSportsType="selectedSportsType"
              :selectedStageId="selectedStageId"
              :selectedStageNo="selectedStageNo"
              :isMobileDevice="isMobileDevice"
               
              />
          </div>


          <!-- Player Edit Modal -->
          <DialogDrawerBox 
          v-model:visible="dialogVisible" 
          title="Update Player Results" 
          placement="left"
          :backdrop="true" 
          >
            <div class="d-flex flex-column justify-content-center align-items-center">

                <div class="d-flex flex-row align-items-center">
                    <img :src="editPlayerData.jerseyId" class="" width="42"  alt="user-profile" />
                    <div class="user-category" v-if="editPlayerData.categoryId != 0">
                        {{ editPlayerData.categoryId }}
                    </div>
                </div>

                <div class="d-flex flex-row align-items-center">
                  <span v-html="mapCountryFlag(editPlayerData.userCountryFlag, '40px', '40px')"></span>
 
                <div class="font-semibold mx-2">
                      {{ editPlayerData.userFullName }}
                  <div class="text-dark p-0 m-0">{{ editPlayerData.teamName }}</div>
                </div>
                </div> 

                <Form
                @submit="submitHandler"
                class="d-flex flex-column gap-2 justify-content-center align-items-center"
               
                > 

                <div class="d-flex flex-wrap mt-4"> 
                    <div class=" w-auto d-flex flex-column gap-2 mx-1 " v-for="(col,index) in editableColumns" :key="index">
                        <label class="p-0">{{col.title}}</label>
                        <Field
                            v-maska="col.field==='overallTime' ? '##:##:##.###' : '' "
                            :name="col.field"
                            :type="col.field==='overallTime' ? 'text': 'number'"
                            min="0"
                            v-model="editPlayerData[col.field]"
                            class="form-control" 
                            :id="col.field"> 
                        </Field>


                        <Field
                        v-maska="col.field==='overallTime' ? '##:##:##.###' : '' "
                        :name="col.field"
                        :type="col.field==='overallTime' ? 'text': 'number'"
                        min="0"
                        v-model="editPlayerData[col.field]"
                        class="form-control" 
                        :id="col.field"> 
                    </Field>

                      
               

                     </div> 

                    
                  
                 </div>

                 <!-- <Field
                     id="selectionStatus"
                     name="selectionStatus"
                     class="form-control"
                     as="select" 
                    
                   >
                     <option v-for="(item, i) in selectionStatuses" :value="item.name" :key="i">{{ item.label }}</option>
                   </Field> -->

                 <div class="mt-4 w-auto row"> 
                    <Button class="btn btn-lg"   style="width: 200px;" type="submit">Submit</Button>
                </div>

                </Form>
            </div>


            

          </DialogDrawerBox>
       


    
    </div>






</template>


<script>
import { mapActions } from 'vuex';
import ResultHeader from '../../components/ResultHeader.vue'
import AdminNavigation from '../../../../shared/components/AdminNavigation.vue';
import DataTableTriathlon from "@/views/components/DataTable-Triathlon.vue";
import { DialogDrawerBox } from 'v-dialogs'
import { Field, Form } from "vee-validate";

import {
    getAllStages,
    getUniqueLeaderBoards,
    mapCountryFlag,
} from "@/shared/events/event.helpers.ts";
import { selectionStatuses} from '@/shared/events/columns.dto.ts';

import {SportsTypeModeTypeEnum} from "@/shared/events/enums.ts";
 

export default {
    name: 'TraithlonResults',
    components: {
        ResultHeader,
        AdminNavigation,
        DialogDrawerBox,
        DataTableTriathlon,
        Field,
        Form
    },
    computed: {

        getEventCategories() {
            return this.event?.categories || []
        },
        getStages() {
            return getAllStages(this.stages, this.event)
        },
        getLeaderboards() {
            return getUniqueLeaderBoards(this.event, this.selectedSportsType, this.selectedDayId)
        },

    },


    

    data() {
        return {
            eventId: this.$route.params.eventId,
            event: null,
            overallLeaderboard: {
                id: 'overall',
                name: 'Overall'
            },
            transitionTimeLeaderboard: {
                id: 'transitionTime',
                name: 'Transition Time'
            },
            allLeaderboards: [],
            stages: [{ label: "Overall Stages", value: "0" }],
            selectedStageId: 0, //default value 
            selectedStageNo: 0,
            categories: [],
            selectedCategory: 0,
            selectedSportsType: 'overall',
            noResultsDataMessage: "Loading Data...",
            isMobileDevice: false,
            mapCountryFlag: mapCountryFlag,
            loading: false,
            selectedDayId: "",
            dialogVisible: false,
            editPlayerData: null,
            editableColumns: [],
            selectionStatuses: selectionStatuses

        }
    },
    async mounted() {
        this.event = await this.getEvent(this.eventId)

        this.allLeaderboards = [this.overallLeaderboard, ...this.getLeaderboards, this.transitionTimeLeaderboard]

    },

    methods: {

        ...mapActions('event', ['getEvent']),


        submitHandler(formData) { 
            const updatedData= {
                ...formData, 
                ...{
                    stageId: this.selectedStageId, 
                    sportsType: SportsTypeModeTypeEnum[this.selectedSportsType],
                    eventId: this.eventId 
                }
            }
            console.log(updatedData);
         },

        tableRowClick(receivedData) { 
            const {rowData, columns}= receivedData 
            if (Object.keys(rowData)?.length > 0) {
                this.editPlayerData = rowData
                this.dialogVisible = true
            }

            this.editableColumns = columns

          },

        async updateSportsType(value) {
            this.selectedSportsType = value
            //   this.selectedColumns = await this.getOverallORStageSegmentsColumns(this.eventData, this.selectedSportsType, this.selectedStageId)
        },

        async updateStage(value) {
            const splitValue= value?.split("+") //the reason we split, that we are generating stages for from same function while two different api required different params thats why we did like this here
            if(splitValue?.length){
                this.selectedStageNo= Number(splitValue[0])
                this.selectedStageId = splitValue[1]
            }
         }

    }


}
</script>
 
<template>
  <sidebar-menu
    :menu="menuOptions"
    :collapsed="true"
    @update:collapsed="onToggleCollapse"
    @item-click="onMenuItemClick"
  >
    <template v-slot:header>
      <!-- <div class="col-md-12 left-button mt-lg-2 mb-lg-3"> -->
      <p class="text-white p-3" v-if="!menuCollapsed">
        <b>{{ user?.name?.toUpperCase() }}</b> <br />
        You're logged in as
        {{ user.role == roles[0] ? "Super Admin" : "Admin" }}
      </p>
      <!-- </div> -->
    </template>
  </sidebar-menu>

  <div class="row">
    <div class="col-md-12 left-button mt-lg-2 mb-lg-3">
      <div class="tab tab-left">
        <ul class="list-inline">
          <li
            class="list-inline-item"
            v-if="eventId && !['pointsMainPage', 'PrizeMoneyPage'].includes(this.$route.name)"
          >
            <router-link
            v-if="isTriathlon"
            :to="{ name: 'AddTriathlonPlayer', query: { eventId: eventId } }">
            Add Players
            </router-link>
            <router-link
            v-else
            :to="{ name: 'AdminAddNewPlayer', query: { eventId: eventId } }"
            >
            Add Player
            </router-link>
          </li>


          <li
          class="list-inline-item"
          v-if="eventId && !['pointsMainPage', 'PrizeMoneyPage'].includes(this.$route.name) && isSrcEvent"
        >
          <a  href="javascript:void(0)"  @click="controlParentModal('add-prize-money')">
          Attach/Delete Prize Money
        </a>
           
        </li>


          <li
            class="list-inline-item"
            v-if="event && !['pointsMainPage', 'PrizeMoneyPage', 'AddNewPrizeMoneyTemplate'].includes(this.$route.name)"
          >
            <a
              v-if="event?.result_status === 'official'"
              href="javascript:void(0)"
              @click="publishEventResultHandler('un-official')"
              >Un-Publish</a
            >
            <a
              v-else
              href="javascript:void(0)"
              @click="publishEventResultHandler('official')"
              >Publish</a  >
          </li>

          <li
            class="list-inline-item"
            v-if="eventId && !['pointsMainPage', 'PrizeMoneyPage'].includes(this.$route.name) && !isTriathlon"
          >
            <a
              href="javascript:void(0)"
              @click="rewardJerseyCorrectionsHandler()"
              >Correct Points & Rewards</a
            >
          </li>

          <!-- Add Points Btn -->
          <li
            class="list-inline-item"
            v-if="this.$route.name == 'pointsMainPage'"
          >
            <a
              href="javascript:void(0)"
              @click="controlParentModal('add-points')"
              >Add Points</a
            >
          </li>
          <li
            class="list-inline-item"
            v-if="this.$route.name == 'pointsMainPage'"
          >
            <a
              href="javascript:void(0)"
              @click="controlParentModal('remove-points')"
              >Delete Points</a
            >
          </li>

          
 

        </ul>
      </div>
    </div>
  </div>
</template>

<style scoped>
@media (min-width: 1200px) {

  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl {
    max-width: 1350px !important;
  }
}
</style>

<script>
import { mapActions, mapGetters } from "vuex";
import sweetAlert from "@/services/alert.ts";
import "sweetalert2/src/sweetalert2.scss";
import Swal from "sweetalert2";
import { SidebarMenu } from "vue-sidebar-menu";
import "vue-sidebar-menu/dist/vue-sidebar-menu.css";

export default {
  name: "AdminNavigation",
  emits: ['listenerChild'],
  components: {
    SidebarMenu,
  },
  props: [
    "event",
    "eventId",
    "isTriathlon",
    "filterResult",
    "playersCount",
    "listenerChild",
    "isSeriesEvent",
    "isSrcEvent",
    "isStages",
    "currentDayId",
  ],
  computed: {
    ...mapGetters("auth", ["user"]),

    getUserId() {
      return this.user?.name?.toUpperCase();
    },
  },
  data() {
    return {
      roles: ["whoosh-super-admin", "whoosh-admin"],
      menuCollapsed: true,
      menuOptions: [
        {
          header: "Result Admin Panel",
          hiddenOnCollapse: true,
        },
        {
          href: { name: "AdminEventResult" },
          title: "Events",
          icon: "fa fa-calendar",
        },
        {
          href: { name: "PushNotificationsPage" },
          title: "Push Notifications",
          icon: "fa fa-mobile",
        },
        {
          href: { name: "ListAllAdminUsers" },
          title: "Users",
          icon: "fa fa-users",
          child: [
            {
              href: { name: "AddNewAdminUser" },
              title: "New User",
              icon: "fa fa-user",
            },
          ],
        },
        

        {
          href: { name: "PrizeMoneyTemplates" },
          title: "Prize Money Templates",
          icon: "fa  fa-tasks",
          child:[
           
              {
              href: { name: "AddNewPrizeMoneyTemplate" },
              title: "Add New Template",
              icon: "fas fa-plus",
            },
       
          ]
       
           
        },

        {
          href: { name: "FinanceEventsList" },
          title: "Prize Money (SRC)",
          icon: "fa fa-check-square",
           
        },


        {
          href: { name: "RolesAndPermissions" },
          title: "Roles",
          icon: "fas fa-user-tag",
          child: [
            {
              href: { name: "AddNewRole" },
              title: "New Role",
              icon: "fas fa-user-tag",
            },
          ],
        },


        {
          title: "Logout",
          icon: "fas fa-sign-out-alt",
        },
      ],
    };
  },
  methods: {
    ...mapActions("event", ["publishEventResult", "rewardJerseyCorrections"]),
    ...mapActions("player", ["reloadPlayers"]),
    ...mapActions("auth", ["logout"]),

    onToggleCollapse(collapsed) {
      this.menuCollapsed = collapsed;
    },

    onMenuItemClick(event, item) {
      if (item.title === "Logout") {
        this.logoutHandler();
      }
      if(item.title == "Events"){
         this.goToEventsPage()
      }
    },

    goToEventsPage(){ 
      if(this.$route.name==="AdminEventResult" &&  this.$route.query?.eventListPage ){ 
        window.location.href=this.$route.fullPath?.split("?")[0]
      }else{ 
         this.$router.push({
          name: 'AdminEventResult', 
          query:{
            eventList: 1
          }
        })
      }

       
    },
    publishEventResultHandler(status) {

      Swal.fire({
        title: "Are you sure?",
        text: `You would like to mark this complete event as ${status}`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes",
        cancelButtonText: "No",
      }).then(async (result) => {
        if (result.isConfirmed) {
          const data = await this.publishEventResult({
            eventId: this.eventId,
            dayId: null,
            result_status: status,
          });

          if (!data?.error) {
            this.$router.go(this.$router.currentRoute);
          }
        }
      });

    },

    rewardJerseyCorrectionsHandler() {
      sweetAlert.confirm(
        "Are you sure?",
        `You want to correct all the reward jerseys?`,
        "Yes",
        "No",
        null,
        async () => {
          await this.rewardJerseyCorrections({
            EventId: this.eventId,
            DayId:
              this.isStages && this.isSeriesEvent ? this.currentDayId : null,
            Key: "6703465e-148e-11ed-861d-0242ac120002",
          });
        }
      );
    },

    async reloadPlayersHandler() {
      Swal.fire({
        title: "Enter password to perform this action",
        input: "password",
        showCancelButton: true,
        confirmButtonText: "Submit",
        inputPlaceholder: "Enter your password",
        inputAttributes: {
          autocapitalize: "off",
          autocorrect: "off",
        },
        inputValidator: async (value) => {
          if (!value) {
            return "Please enter password";
          }
          const res = await this.reloadPlayers({
            eventId: this.eventId,
            password: value,
            isSeriesEvent: this.isSeriesEvent,
            isStages: this.isStages,
          });
          if (res.error) {
            return res.message;
          } else if (!res.error) {
            await this.filterResult("individual", "individual");
          }
        },
        preDeny: (response) => {
          if (response.error) {
            return false; // Prevent confirmed
          } else {
            return true;
          }
        },
      }).then((response) => {
        if (!response.isDenied && response.isConfirmed) {
          // this.$router.push();
          window.location.reload();
        }
      });
    },
    controlParentModal(value) {
      this.$emit("listenerChild", { type: value, popup: true });
    },
    async logoutHandler() {
      await this.logout();
      await this.$router.push({ name: "AdminLogin" });
    },
  },
};
</script>

<template>
  <page-heading v-if="isStages && isSeriesEvent">
    {{ event?.name }} | Stage {{ parseInt(currentStage) + 1 }} of {{ stages.length }}
  </page-heading>
  <page-heading v-else>{{ event?.name }}</page-heading>

  <p class="text-center datetime" v-if="isStages && isSeriesEvent">
    {{ getStageDate(this.currentDay) }}
  </p>
  <p class="text-center datetime" v-else>
    {{ event?.datetime ? getDate(event?.datetime) : "" }}
  </p>



</template>

<script>
 
import {getDate,getStageDate} from '../../../shared/events/event.helpers.ts';

export default {
    name: 'ResultHeader',
    props:['event', 'isStages', 'isSeriesEvent', 'stages'],
    components:{

    },
    data(){
        return {
            getDate: getDate,
            getStageDate: getStageDate
        }
    }
     

}
</script>
<template>
  <page-heading>Add Player Data</page-heading>

  <AdminNavigationVue />

  <div class="container">
    <div class="row">
      <div class="col-md-12 left-button mt-lg-2 mb-lg-3">
        <div class="tab tab-left">
          <ul class="list-inline">
            <li class="list-inline-item">
                <router-link
                :to="{
                  name: 'AdminResultEditPanelv2',
                  params: { eventId: eventId },
                }"
                >{{ playerFieldsData?.EventName }}
              </router-link>
            </li>
          </ul>
        </div>
      </div>
    </div>

   <page-loader v-if="loading" />

   <Form class="player-form" @submit="submitHandler">
    <div id="accordionDiv" v-if="addingPlayers.length">
      <ul>

        <li>
<!--   @change="selectedType = $event.target.value" -->
          <div class="row">

            <section class="col-lg-6 col-md-6">
              <div class="form-group">
                <label for="DayId">Day No</label>
                <Field
                  id="DayId"
                  name="DayId"
                  as="select"
                  class="form-control"
                  :class="{ 'is-invalid': errors.DayId }"
                  v-model="SelectedDayId"
                >
                <option :value="day?.DayId" v-for="(day, i) in daysList" :key="i">
                  {{ day?.DayId + " - " + day.DayNo }}
                </option>
              </Field>
                <div class="invalid-feedback">{{ errors.DayId }}</div>
              </div>
            </section>

            <section class="col-lg-6 col-md-6">
              <div class="form-group">
                <label for="StageId">Stage</label>
                <Field
                  id="StageId"
                  name="StageId"
                  class="form-control"
                  as="select"
                  :class="{ 'is-invalid': errors.StageId }"
                  v-model="selectedStageId"
                >
                  <option :value="j.StageId" v-for="j in stageList(SelectedDayId)" :key="j">
                    {{ j.DayName }}
                  </option>
                </Field>
                <div class="invalid-feedback">{{ errors.DayName }}</div>
              </div>
            </section>
        </div>
        </li>

        <li v-for="(player, index) in addingPlayers" :key="index">
          <div class="row">
            
            <!-- <section class="col-lg-3 col-md-6">
              <div class="form-group">
                <label :for="`PlayerId[${index}]`">Player Name</label>
                <Field
                  :id="`PlayerId[${index}]`"
                  :name="`PlayerId[${index}]`"
                  class="form-control"
                  as="select"
                  :class="{ 'is-invalid': errors[`ListOfPlayers[${index}].PlayerId`] }"
                  v-model="player.PlayerId"
                >
                  <option  :value="user.UserId" v-for="(user, j) in usersList" :key="j">
                    {{ user.UserFullName }}
                  </option>
                </Field>
                <div class="invalid-feedback">{{ errors[`ListOfPlayers[${index}].PlayerId`] }}</div>
              </div>
            </section> -->

            <section class="col-lg-3 col-md-6">
              <div class="form-group">
                <label :for="`PlayerId[${index}]`">Player ID</label>
                <Field
                  :id="`PlayerId[${index}]`"
                  :name="`PlayerId[${index}]`"
                  type="text"
                  class="form-control"
                  :class="{ 'is-invalid': errors[`ListOfPlayers[${index}].PlayerId`] }"
                  v-model="player.PlayerId"
                />
              </div>
            </section>

            <section class="col-lg-4 col-md-6" v-if="selectedStageId !== firstStage">
                <div class="form-group">
                    <label :for="`StartedAtDateTime[${index}]`" class="d-block">Started At</label>
                    <div class="d-flex align-items-center">
                    <Field
                        :name="`ListOfPlayers[${index}].StartedAtDateTime`"
                        type="datetime-local"
                        class="form-control"
                        :class="{ 'is-invalid': errors[`ListOfPlayers[${index}].StartedAtDateTime`] }"
                        v-model="player.StartedAtDateTime"
                        :step="1"
                    />
                    <input
                        type="number"
                        class="form-control"
                        :class="{ 'is-invalid': errors[`ListOfPlayers[${index}].StartedAtMilliseconds`] }"
                        v-model="player.StartedAtMilliseconds"
                        min="0"
                        max="999"
                        placeholder="000"
                        @input="validateMilliseconds(index, 'StartedAtMilliseconds')"
                    />
                    </div>
                   
                </div>
            </section>


            <section class="col-lg-4 col-md-6">
                <div class="form-group">
                    <label :for="`StartedAt[${index}]`">Finished At</label>
                    <div class="d-flex align-items-center">
                        <Field
                            :name="`FinishedAtDateTime[${index}]`"
                            type="datetime-local"
                            class="form-control"
                            :class="{ 'is-invalid': errors[`ListOfPlayers[${index}].FinishedAtDateTime`] }"
                            v-model="player.FinishedAtDateTime"
                            :step="1" 
                        />
                        <input
                            type="number"
                            class="form-control"
                            :class="{ 'is-invalid': errors[`ListOfPlayers[${index}].FinishedAtMilliseconds`] }"
                            v-model="player.FinishedAtMilliseconds"
                            min="0"
                            max="999"
                            placeholder="000"
                            @input="validateMilliseconds(index, 'FinishedAtMilliseconds')"
                        />
                    </div>
                    <div class="invalid-feedback">{{ errors[`ListOfPlayers[${index}].FinishedAtDateTime`] }}</div>
                    <div class="invalid-feedback">{{ errors[`ListOfPlayers[${index}].FinishedAtMilliseconds`] }}</div>
                </div>
            </section>
            
            <section class="col-lg-3 col-md-6">
              <div class="form-group">
                <label :for="`ElapsedTime[${index}]`">Elapsed Time (HH:MM:SS.MS)</label>
                <Field
                  v-maska="'##:##:##.###'"
                  :id="`ElapsedTime[${index}]`"
                  :name="`ElapsedTime[${index}]`"
                  type="text"
                  class="form-control"
                  :class="{ 'is-invalid': errors[`ListOfPlayers[${index}].ElapsedTime`] }"
                  v-model="player.ElapsedTime"
                />
                <div class="invalid-feedback">{{ errors[`ListOfPlayers[${index}].ElapsedTime`] }}</div>
              </div>
            </section>

            <section class="col-lg-12 col-md-6 d-flex flex-row-reverse" v-if="(index + 1) === playerCount">
              <div>
                <span class="btn btn-black" @click="AddAnotherHandler('increment')">Add Another</span>
              </div>
              <div v-if="index > 0">
                <span class="btn btn-primary" @click="AddAnotherHandler('decrement')">Cancel</span>
              </div>
            </section>
          </div>
        </li>
      </ul>

      <div class="row">
        <section class="col-lg-3 col-md-6">
          <label class="hide-mobile">&nbsp;</label>
        </section>

        <section class="col-lg-3 col-md-6">
          <label class="hide-mobile">&nbsp;</label>
        </section>

        <section class="col-lg-3 col-md-6">
          <label class="hide-mobile">&nbsp;</label>
        </section>

        <section class="col-lg-3 col-md-6">
          <label class="hide-mobile">&nbsp;</label>
          <button type="submit" class="btn btn-primary">Submit</button>
        </section>
      </div>
    </div>
  </Form>
  </div>
</template>

<style scoped>
.jerseyImage {
  width: 50px;
  height: 50px;
}


section.accordionDiv {
  padding-top: 2em;
  padding-bottom: 3em;
}

#accordionDiv ul {
  text-align: left;
}

.transition,
p,
ul li i:before,
ul li i:after {
  transition: all 0.3s;
}

#accordionDiv .no-select,
#accordionDiv h2 {
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  user-select: none;
}

#accordionDiv h1 {
  color: #fcb913;
  margin-bottom: 30px;
  margin-top: 0;
}

#accordionDiv h2 {
  color: #fcb913;
  font-family: "hm_light", sans-serif;
  font-size: 20px;
  line-height: 34px;
  text-align: left;
  padding: 15px 15px 0;
  text-transform: none;
  font-weight: 300;
  letter-spacing: 1px;
  display: block;
  margin: 0;
  cursor: pointer;
  transition: 0.2s;
}

#accordionDiv div {
  text-align: left;
  font-family: "hm_light", sans-serif;
  font-size: 14px;
  line-height: 1.45;
  position: relative;
  will-change: max-height;

  opacity: 1;
  transform: translate(0, 0);
  margin-top: 5px;
  margin-bottom: 9px;

  transition: 0.3s opacity, 0.6s max-height;
  hyphens: auto;
  z-index: 2;
  padding-right: 10px;
  padding-left: 10px;
}

#accordionDiv ul {
  list-style: none;
  perspective: 900;
  padding: 0;
  margin: 0;
}

#accordionDiv ul li {
  position: relative;
  overflow: hidden;
  padding: 0;
  margin: 0;
  background: #fff;
  box-shadow: 0 3px 10px -2px rgba(0, 0, 0, 0.1);
  -webkit-tap-highlight-color: transparent;
}

#accordionDiv ul li+li {
  margin-top: 15px;
}

#accordionDiv ul li:last-of-type {
  padding-bottom: 0;
}

#accordionDiv ul li i {
  position: absolute;
  transform: translate(-6px, 0);
  margin-top: 28px;
  right: 15px;
}

#accordionDiv ul li i:before,
ul li i:after {
  content: "";
  position: absolute;
  background-color: #fcb913;
  width: 3px;
  height: 9px;
}

#accordionDiv ul li i:before {
  transform: translate(-2px, 0) rotate(45deg);
}

#accordionDiv ul li i:after {
  transform: translate(2px, 0) rotate(-45deg);
}

#accordionDiv ul li input[type="checkbox"] {
  position: absolute;
  cursor: pointer;
  width: 100%;
  height: 100%;
  z-index: 1;
  opacity: 0;
  touch-action: manipulation;
}

#accordionDiv ul li input[type="checkbox"]:checked~h2 {
  color: #000;
}

#accordionDiv ul li input[type="checkbox"]:checked~div {

  max-height: 0;
  transition: 0.3s;
  opacity: 0;

}

#accordionDiv ul li input[type="checkbox"]:checked~i:before {
  transform: translate(2px, 0) rotate(45deg);
}

#accordionDiv ul li input[type="checkbox"]:checked~i:after {
  transform: translate(-2px, 0) rotate(-45deg);
}

ul {
  padding: 0;
  list-style: none;
}

sup {
  font-size: 70%;
}
</style>

<script>
import { Field, Form } from "vee-validate";
import { mapActions, mapGetters } from "vuex";
import * as Yup from "yup";
import AdminNavigationVue from "../../../../shared/components/AdminNavigation.vue";
import {
  timeFormateTypes
} from "../../../../shared/events/columns.dto.ts";

const sanitizedPayload = require("string-values-to-numbers")


export default {
  name: "AddTriathlonPlayer",
  components: {
    Form,
    Field,
    AdminNavigationVue,
  },
  watch: {
  },
  computed: {
    ...mapGetters("player", ["playerFieldsData"]),

    daysList() {
      return this.playerFieldsData?.ListOfDays || [];
    },
    usersList() {
      return this.playerFieldsData?.ListOfUsers || [];
    },
  },
  data() {
    return {
      loading: false,
      selectTimeType: '',
      selectedStageId: '',
      SelectedDayId: '',
      eventId: this.$route.query.eventId,
      timeFormatList: timeFormateTypes,
      addingPlayers: [
        {
          PlayerId: '',
          StartedAtDateTime:  '',
          StartedAtMilliseconds: '',
          FinishedAtDateTime:  '',
          FinishedAtMilliseconds: '',
          ElapsedTime: '00:00:00.000',
        },
      ],
      playerCount: 1,
      errors: [],
    };
  },

  async mounted() {
    this.playerFieldsData = await this.getAddPlayerFieldsData({ eventId: this.eventId, type: 'triathlon'});
    const defaultDay = this.daysList.find((day) => day.DayNo === 1);
    if (defaultDay) {
      this.SelectedDayId = defaultDay.DayId;
      this.selectedStageId = defaultDay.ListOfStages[0].StageId;
      this.firstStage = defaultDay.ListOfStages.find((stg) => stg.StageNo === 1)?.StageId || '';
    }
  },

  methods: {
    ...mapActions("player", ["getAddPlayerFieldsData", "fetchUsersList", "addPlayer", "addTriathlonPlayer"]),
    stageList(day) {
      return (
        this.playerFieldsData?.ListOfDays?.find((e) => e.DayId === day)?.ListOfStages
      );
    },
    getCurrentDateTime() {
      const now = new Date();
      const pad = (num) => String(num).padStart(2, '0');
      return `${now.getFullYear()}-${pad(now.getMonth() + 1)}-${pad(
        now.getDate()
      )} ${pad(now.getHours())}:${pad(now.getMinutes())}:${pad(
        now.getSeconds()
      )}`;
    },
    toEpochTime(dateTime, milliseconds) {
        if (!dateTime) return null; // Handle empty or invalid datetime
        const dateInMs = new Date(dateTime).getTime(); // Get epoch time in milliseconds
        return dateInMs + (parseInt(milliseconds) || 0); // Add milliseconds (default to 0 if undefined)
    },
    validateMilliseconds(index, fieldName) {
        if(fieldName === 'StartedAtMilliseconds'){
            if(this.selectedStageId === this.firstStage){
                return;
            }
        }
        const value = this.addingPlayers[index][fieldName];

        // Ensure the value is within 0 to 999
        if (value < 0) {
        this.addingPlayers[index][fieldName] = 0;
        } else if (value > 999) {
        this.addingPlayers[index][fieldName] = 999;
        }
    },

    AddAnotherHandler(type) {
      if (type == 'decrement') {
        this.addingPlayers.pop()
        this.playerCount -= 1
      } else {
        this.addingPlayers.push(
          {
            PlayerId: '',
            StartedAtDateTime:  '',
            StartedAtMilliseconds: '',
            FinishedAtDateTime:  '',
            FinishedAtMilliseconds: '',
            ElapsedTime: '00:00:00.000'
          }
        )
        this.playerCount += 1
      }
    },

    async submitHandler() {
      this.loading = true
      const playerSchema = Yup.object().shape({
            PlayerId: Yup.string().required('Player ID is required'),
            
            ElapsedTime: Yup.string()
                .nullable()
                .test('valid-format', 'Elapsed Time must be in HH:MM:SS.MS format', function (value) {
                    // Regex to validate HH:MM:SS.MS format
                    return !value || value === '00:00:00.000' || /^\d{2}:\d{2}:\d{2}\.\d{3}$/.test(value);
                }),
            
            StartedAtDateTime: Yup.string()
                .nullable()
                .when('ElapsedTime', {
                    is: (elapsedTime) => !elapsedTime || elapsedTime.trim() === '' || elapsedTime === '00:00:00.000', // Validate only if ElapsedTime is not meaningful
                    then: Yup.string()
                        .when('$selectedStageId', {
                            is: (selectedStageId) => selectedStageId !== this.firstStage,
                            then: Yup.string().required('Started At is required'),
                            otherwise: Yup.string().nullable(),
                        })
                        .test(
                            'is-before-finished',
                            'Started At must be before Finished At',
                            function (value) {
                                const { FinishedAtDateTime } = this.parent;
                                return !value || !FinishedAtDateTime || new Date(value) < new Date(FinishedAtDateTime);
                            }
                        ),
                }),
            
            // StartedAtMilliseconds: Yup.number()
            //     .transform((value, originalValue) => (originalValue === '' ? 0 : value)) // Convert empty string to 0
            //     .nullable()
            //     .when('ElapsedTime', {
            //         is: (elapsedTime) => !elapsedTime || elapsedTime.trim() === '' || elapsedTime === '00:00:00.000', // Validate only if ElapsedTime is not meaningful
            //         then: Yup.number().when('$selectedStageId', {
            //             is: (selectedStageId) => selectedStageId !== this.firstStage,
            //             then: Yup.number().required('Started At Milliseconds is required'),
            //             otherwise: Yup.number().default(0), // Default to 0
            //         }),
            //     }),

            FinishedAtDateTime: Yup.string()
                .nullable()
                .when('ElapsedTime', {
                    is: (elapsedTime) => !elapsedTime || elapsedTime.trim() === '' || elapsedTime === '00:00:00.000', // Validate only if ElapsedTime is not meaningful
                    then: Yup.string().required('FinishedAtDateTime is required'),
                }),
        });



      const schema = Yup.object().shape({
        EventId: Yup.string().required('Event ID is required'),
        DayId: Yup.string().required('Day ID is required'),
        StageId: Yup.string().required('StageId ID is required'),
        // ListOfPlayers: Yup.array().of(playerSchema).required('List of Players is required'),
        ListOfPlayers: Yup.array()
            .of(playerSchema)
            .required('List of Players is required')
            .test(
                'unique-player-ids',
                'Players must be unique',
                (players) => {
                    const playerIds = players.map((player) => player.PlayerId);
                    const uniquePlayerIds = new Set(playerIds);
                    return uniquePlayerIds.size === playerIds.length; // Check for duplicates
                }
            ),
      });

      let finalPayload = {
        EventId: this.eventId,
        DayId: this.SelectedDayId,
        StageId: this.selectedStageId,
        ListOfPlayers:  this.addingPlayers
      }
      

      try {
        
        finalPayload = await schema.validate(finalPayload, {
            abortEarly: false,
            context: { selectedStageId: this.selectedStageId },
        });

        const startedTime  = this.stageList(this.SelectedDayId).find(stg => stg.StageId === this.selectedStageId)?.StartTime * 1000;
       
        finalPayload.ListOfPlayers = this.addingPlayers.map(pl => {
          //eslint-disable-next-line
            const { 
                PlayerId, 
                StartedAtDateTime, 
                StartedAtMilliseconds,
                FinishedAtDateTime,
                FinishedAtMilliseconds,
                ElapsedTime 
            } = pl;
            const elapsedTime = ElapsedTime !== '00:00:00.000' ? this.convertDurationtoSeconds(ElapsedTime) : false;
            const userInfo = this.usersList.find(u => u.UserId === PlayerId) ?? false;
            const startedAt = this.toEpochTime(StartedAtDateTime, StartedAtMilliseconds);
            const FinishedAt = this.toEpochTime(FinishedAtDateTime, FinishedAtMilliseconds);
            
            if(elapsedTime){
                return {
                    PlayerId,
                    CategoryId: userInfo ? userInfo.categoryId : 1,
                    StartedAt: "",
                    FinishedAt:"",
                    ElapsedTime: elapsedTime,
                    TotalRepetition: 1
                }
            } else {
                return {
                    PlayerId,
                    CategoryId: userInfo ? userInfo.categoryId : 1,
                    StartedAt: startedAt ?? startedTime,
                    FinishedAt,
                    ElapsedTime: "",
                    TotalRepetition: 1
                }
            }
        });
        //send api call
        const response = await this.addTriathlonPlayer(sanitizedPayload(finalPayload));
        response.error ? global.app.$toast.error(response?.message ? response.message : "Error Occured") :  this.$router.push({ name: "AdminResultEditPanelv2", params: { eventId: this.eventId } });
        // Handle valid form submission logic here
      } catch (validationErrors) {

        global.app.$toast.error(validationErrors)
        this.errors = validationErrors.inner.reduce((acc, error) => {
            
          const { path, message } = error;
          if (!acc[path]) acc[path] = '';
          acc[path] = message;
          return acc;
        }, {});
      }
      
      this.loading = false
    },

    convertDurationtoSeconds(duration) {
      const [hours, minutes, seconds] = duration.split(':')
      return (Number(hours) * 60 * 60 + Number(minutes) * 60 + Number(seconds)) * 1000;
    },

    convertToTimestamp(dateString) {
        const date = new Date(dateString.replace(' ', 'T'));
        return date.getTime();
    }, 

  },
};
</script>

<style>
#trithlonTable .bh-table-responsive {
  border-radius: 1px !important;

}

 

#trithlonTable {
  font-family: 'Poppins', sans-serif !important;
  font-style: normal;
  font-size: 16px;
  border: 1px solid rgb(242, 242, 242);

}

#trithlonTable thead tr:nth-child(1) {
  background: #000 !important;
  color: white;
  height: 40px;
  align-content: center;
  font-weight: 900;
  line-height: 30px;
}



#trithlonTable tbody tr:nth-child(1) td:first-child {
  background: #fcb913 !important;
}

#trithlonTable tbody tr:nth-child(2) td:first-child {
  background: #62c3ec !important;
}

#trithlonTable tbody tr:nth-child(3) td:first-child {
  background: #ea5f28 !important;
}

#trithlonTable .bh-text-sm {
  line-height: 1px !important;
}




.rank-tbl-mob .table-outer {

  padding: 0px;
  overflow-y: scroll !important;
}

.rank-tbl-mob table tbody tr:nth-child(1)>td:nth-child(1) {
  background: #fcb913 !important;
}

.rank-tbl-mob table tbody tr:nth-child(2)>td:nth-child(1) {
  background: #62c3ec !important;
}

.rank-tbl-mob table tbody tr:nth-child(3)>td:nth-child(1) {
  background: #ea5f28 !important;
}





.rank-tbl-mob table tr td span {
  font-family: "Inter" !important;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  text-align: middle;
  width: 35px;
  color: #ffffff;
  display: flex;
  align-items: center !important;
  justify-content: center !important;

}

.rank-tbl-mob table tr.active td {
  font-weight: 700;
}

.rank-tbl-mob table tr td {
  font-family: "Inter" !important;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  align-items: center;
  color: #000000;
  background-color: transparent;
  border: none;
  vertical-align: middle !important;

}

.rank-tbl-mob .teamsText {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  color: #59636b;
  text-transform: uppercase;
  margin: 4px !important;
}


.mobileRowText {
  font-size: 12px;
  font-weight: 700;
  padding: 0px !important;
  margin: 0px !important;
}

.rankCol {
  background: #59636b !important;
}

.container {
  max-width: 1321px !important;
}


.highlighted-row {
  background-color: #F1AA00; /* Light blue background */
}

</style>


<template>

    <page-loader v-if="loading" style="margin-top: 50px"/>

  
  
    <div class="container" v-if="!isMobileView">  
                      <vue3-datatable
                      v-if="rows?.length > 0"
                      :rows="rows"
                      :columns="cols"
                      sortColumn="rank"
                      :pagination="false"
                      :skin="getIsAdminUser ? '' : 'bh-table-hover'"
                      class="bg-white"
                      @row-click="((rowData) => handleRowClick(rowData))"
                      :row-class="getRowClass"
                      id="trithlonTable"> 

                    <template #userFullName="data">
                      <div class="d-flex flex-row align-items-center">
                          <img :src="data.value.jerseyId" class="" width="42"  alt="user-profile" />
                          <div class="font-semibold mx-2">
                            {{ data.value.userFullName }}
                            <div class="text-dark">{{ data.value.teamName }}</div>
                          </div>
                      </div>
                  </template>

                    <template #userCountryFlag="data">
                      <span v-html="mapCountryFlag(data.value.userCountryFlag, '40px', '40px')"></span>
                    </template>

                
                    
                  
                      </vue3-datatable>

                      <vue3-datatable v-else
                        :columns="cols"
                        sortColumn="rank"
                        :pagination="false"
                        skin="bh-table-hover "
                        class="bg-white"
                        id="trithlonTable"    
                      >
                        </vue3-datatable>
          
     
    </div>

    <div v-else style="margin: 0px !important; padding: 0px !important;">
      <div class="rank-tbl-mob">
        <div class="table-outer">
          <table class="table overall-ind-table mt-3">
            <tbody>
              <template v-for="(row, index) in rows" :key="index">

                <tr :class="valTypeCheck(row.rank)" scope="row">

                  <td scope="col" width="10px" class="p-0 m-0 rankCol">
                    <span :class="index == 0 ? 'active' : ''">{{ row.rank }}</span>
                  </td>

                  <td scope="col" class="p-0">

                    <div class="bg-white d-flex justify-content-between" >

                      <td class="p-2">

                        <div class="d-flex justify-content-between">

                          <div class="jersey-img d-flex">
                            <div v-html="mapJerseyImage(row.jerseyId)"></div>
                            
                          </div>

                          <div class="mx-3">

                            <div class="d-flex justify-content-center">
                              <strong v-html="mapCountryFlag(row.userCountryFlag, '20px', '20px')"></strong>
                              <p
                                class="mb-0 playerNameTruncateMbl"
                                v-html="row.userFullName" >
                              </p>
                          </div>

                            <p class="teamsText">
                              {{ row.teamName || "-" }}
                            </p>

                          </div>

                        

                        

                        </div>

                      </td>

                      

                      

                    <td scope="col" class="text p-2 text-center">
                        <div class=" ">
                          <p class="teamsText"> Overall</p>
                          <p  class="mobileRowText">{{ row.overallTime }}</p>
                        </div> 
                    </td>

                      
                    </div>

                    <div   class="d-flex justify-content-between align-items-center" style="background: rgba(226, 230, 235, 1);">

                      
                      <div class="d-flex flex-column p-1 text-center" v-for="(item, index) in cols" :key="index">
                        <p class="teamsText" >{{ item.title }}</p>
                        <p class="mobileRowText" >{{ row[item.field] }}</p>
                      </div>
                      
 

                    </div>


                  </td>

                 
                  
                </tr>

 
      
              </template>
            
            
        
            </tbody>
          </table>
        </div>
      </div>
    </div>
  
</template>


<script>
import Vue3Datatable from '@bhplugin/vue3-datatable';
import '@bhplugin/vue3-datatable/dist/style.css';


import {
  formatTime,
  isValueEpochTime,
  mapCountryFlag,
  mapJerseyImage,
  getSportsType,
  getSportTypeValue,
  getUniqueLeaderBoards

} from "@/shared/events/event.helpers.ts";
import { mapActions, mapGetters } from "vuex";
 
 


export default {
  name: 'DataTableTriathlon',
  emits: ['tableRowClick'],
  props: {
    
    selectedStageId: {},
    selectedStageNo: {},
    eventData: {},
    selectedSportsType: {
      type: String,
      default: 'overall', // Default to an empty string if sportsType is not passed
    },

    isMobileDevice: {
      type: Boolean,
      default: false,
    }
  },

  components: {
    Vue3Datatable,

  },


  data() {
    return {
      rows: [], // Rows will be updated from resultRows prop
      cols: [], // Columns will be calculated by combining default and passed columns
      desktopCols: this.cols,
      defaultCols: [
        {
          title: 'Rank', field: 'rank',
          type: 'number', maxWidth: '4px',
          cellClass: 'text-center font-weight-bolder text-white bg-dark p-0',
        }, // Default column for rank
        { title: '', field: 'userCountryFlag', width: '5px' },
        { title: 'Rider', field: 'userFullName' }
      ],
      mapCountryFlag: mapCountryFlag,
      mapJerseyImage: mapJerseyImage,
      isMobileView: this.isMobileDevice,
      editableCols: [],
      selectedRow: null


    };
  },

  computed: {
    // Combine default columns with passed columns and add an 'Overall' column

    ...mapGetters("auth", ["user"]),

    getEventId() {
      return this.eventData?.serverEventId
    },

    getIsAdminUser() {
      return this.user?.is_logout == "no" && this.$router.currentRoute?._value?.fullPath?.split("/")?.includes("result-admin")
    }
  },

  watch: {
    selectedStageNo: {
      immediate: true, // Run the watcher on the first render
      async handler() {
        this.cols = await this.getCols()
        this.rows = await this.loadResult()
        this.selectedRow = null
      },
    },

    selectedSportsType: {
      immediate: true, // Run the watcher on the first render
      async handler() {
        this.cols = await this.getCols()
        this.rows = await this.loadResult()
        this.selectedRow = null
      },
    },

    isMobileDevice: {
      immediate: true, // Run the watcher on the first render
      async handler(newValue) {
        this.isMobileView = newValue
        this.cols = await this.getCols(this.cols);
      },
    }
  },

  async mounted() {
    // Set initial columns when the component is mounted
    this.cols = await this.getCols()
    this.rows = await this.loadResult()
    this.selectedRow = null
     
  },

 


  methods: {

    ...mapActions("event", ["getMultiSportEventResult"]),


    handleRowClick(row) { 
      if (this.getIsAdminUser && ['ride','run','swim'].includes(this.selectedSportsType) && this.selectedStageNo !=0) {
        this.selectedRow=row.userId
        this.$emit("tableRowClick", {rowData: row, columns: this.editableCols});
       }

    },

      getRowClass(row) {
        return  this.selectedRow &&  this.selectedRow === row?.userId ? "highlighted-row" : "";

     },


    // Compute rows based on sportsType and resultRows
    getNewRows(data) {
      if (!data?.length) return []
      const res = data?.map(item => {
        const updatedItem = { ...item };
        for (const key in updatedItem) {
          if (isValueEpochTime(updatedItem[key])) {
            updatedItem[key] = formatTime(updatedItem[key]);
          }
        }
        return updatedItem;
      });
      return res

    },

    async getCols() {
      const columns = await this.getOverallORStageSegmentsColumns(this.eventData, this.selectedSportsType, this.selectedStageNo)

 
      this.editableCols=columns 


      if (this.isMobileView) {
        return [
          ...columns?.filter(item => !['rank', 'overallTime', 'userCountryFlag', 'userFullName'].includes(item.field)),
          // { title: 'Overall', field: 'overallTime', cellClass: 'font-weight-bolder' },
        ];
      }

      // return columns
      return [
        ...this.defaultCols,
        ...columns,
        { title: 'Overall', field: 'overallTime', cellClass: 'font-weight-bolder' },
      ];
    },


    valTypeCheck(val) {
      if (this.isEleminationRaceProp) {
        const aAsNumber = Number(val);
        return isNaN(aAsNumber) ? 'rank-row-string' : 'rank-row-number'
      }
      return ''
    },


    async loadResult() {
      this.loading = true
      const payload = {
        eventId: this.getEventId,
        stage: this.selectedStageNo,
        sportsType: `${getSportTypeValue(this.selectedSportsType)}`,
        category: Number(this.selectedCategory || 0)
      }
      //call api
      const responseData = await this.getMultiSportEventResult(payload) || []
      this.loading = false
      return this.getNewRows(responseData)

    },


    async getOverallORStageSegmentsColumns(eventData, sportType, stageId) {


      const stageCount = eventData?.SportsModeType?.length;
      const tableCols = eventData?.ListOfDays[0]?.Stages?.reduce((acc, stage, index, array) => {
        const key = Math.floor(index / stageCount) + 1; // Calculate the group index
        acc[key] = acc[key] || []; // Initialize array if not present

        acc[key].push({ title: stage.DayName, field: stageId == 0 ? getSportsType(stage.SportType).toLowerCase() + "" + (key) : getSportsType(stage.SportType).toLowerCase() }); // Push stage
        if (index < array.length - 1) {
          acc[key].push({ title: `T${index + 1}`, field: `t${index + 1}`, isTransitionSegments: true }); // Push T1, T2, T3, etc., after each stage
        }
        return acc;
      }, {});

      //transitionTime sportsMode
      if (sportType === 'transitionTime') {
        let filteredStages = []
        if (stageId === 0) { // sportType 0 indicates 'overall stage'
          // Flatten and filter the data to include only transition elements
          filteredStages = Object.values(tableCols)  // Get all the arrays of stages
            .flat()  // Flatten them into a single array
            .filter(stage => stage.isTransitionSegments);  // Filter for only the elements with `isTransitionSegments`
          return filteredStages
        }
        tableCols[stageId]?.pop(); //remove last index which is t col
        return tableCols[stageId]?.filter(item => item.isTransitionSegments)
      }


      //overall sportsMode
      if (sportType === 'overall') {
        if (stageId === 0) { // sportType 0 indicates 'overall stage'
          const overallSegments = getUniqueLeaderBoards(eventData, sportType)?.map(({ id, name }) => ({ title: name, field: id }))
          return [...overallSegments, { title: 'T', field: 'transitionsTime' }]
        }
        tableCols[stageId]?.pop(); //remove last index which is t col
        return tableCols[stageId]
      }

      //Swim Ride Run Modes
      // sportType = Number(sportType) //receiving string
      if (['ride', 'run', 'swim'].includes(sportType)) {
        if (stageId === 0) { // sportType 0 indicates 'overall stage'
          // Flatten all stages from the structured object into one array
          const allStages = Object.values(tableCols).flat();


          // Filter by the provided SportType
          return allStages.filter(stage => stage.field.toLowerCase().includes(sportType))
        } else {
          if (sportType == 'run') {
            return [
              {
                title: 'Avg.HR',
                field: 'avgHeartRate'
              },
              {
                title: 'Pace/Min',
                field: 'avgPower'
              },]
          }

          if (sportType == 'ride') {
            //manage cols from here
            return [{
              title: 'W/Kg',
              field: 'wattPerKG'
            },
            {
              title: 'Avg.Watts',
              field: 'avgPower'
            },
            {
              title: 'Avg.HR',
              field: 'avgHeartRate'
            }
            ]
          }

          if (sportType == 'swim') {
            return [] //no cols
          }
        }
      }
      return tableCols
    }

  },
};
</script>

<style scoped></style>

<template>

    <!-- Result Page Navigation -->
  <AdminNavigation v-if="!isFinanceAdmin" />


    <page-loader v-if="loading"></page-loader>

    <div class="container  mt-4">

        <div class="d-flex justify-content-between ">
            <h2>SRC Events List</h2> 
            <nav class="navbar navbar-light bg-light mb-2" >
                <form class="d-flex" @submit.prevent="handleSearchSubmit">
                  <input class="form-control mr-sm-2" type="search" placeholder="Search"  required aria-label="Search" v-model="searchQuery" :disabled="loading">
                  <button class="btn btn-outline my-2 my-sm-0" type="submit" :disabled="loading">Search</button>
                </form>

                <button v-if="isSearchApiCall" class="mx-1 btn btn-success" @click="resetSearch" :disabled="loading"> reset <i class="fa fa-times"></i></button>
            </nav>

        </div>

         <table class="table table-bordered">
            <thead class="thead-dark">
              <tr>
                  <th scope="col">Date</th>
                  <th scope="col">Event Name</th>
                  <th scope="col">Result Status</th>
                <th scope="col">Event ID</th>
                <th scope="col">Action</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in eventsList" :key="index">
                <td>{{ item.datetime }}</td>
                <td>{{ item.name }}</td>
                <td>{{ item.result_status }}</td>
                <th scope="row">{{ item.EventId }}</th>
                <td>
                    <button class="btn btn-dark" @click="goToFundTransferPage(item)">{{isFinanceAdmin ? 'Transfer Funds' : 'View Winners'}} </button>
                </td>
                 
              </tr>


             

              <tr v-if="eventsList?.length == 0" class="text-center p-4 d-flex">
                     No Data Found
              </tr>
              
              
            </tbody>
        </table>


        <div class="text-center" v-if="isMoreData">
            <button class="btn btn-dark" @click="handleLoadMore">Load More Events!</button>
        </div>  
          
    </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import AdminNavigation from "@/shared/components/AdminNavigation.vue";


export default {
    name: 'FinanceEventsList',

    data() {
        return {
            loading: false,
            eventsList: [],
            isMoreData: false,
            currentPage: 1,
            searchQuery: "",
            limit: 10
        }
    },

    components: {
        AdminNavigation
    },

    computed: {

        ...mapGetters('auth', ['user']),

        isSearchApiCall() {
            return this.searchQuery.trim().length > 0;
        },

        isFinanceAdmin(){ 
            return this.user.role==='whoosh-finance-admin' ? true : false
        },
    },

    async mounted() {
        await this.fetchData(this.currentPage)

    },

    methods: {
        ...mapActions("event", ["getSrcPublishedEvents"]),
        async fetchData(page, search = '', isloadMoreCall = false) {
            this.loading = true
            const response = await this.getSrcPublishedEvents({ page: page, limit: this.limit, search })
            if (!response?.error && response?.data?.events?.length) {
                this.isMoreData = this.currentPage < response?.data?.totalPages ? true : false
                if (isloadMoreCall) {
                    this.eventsList = [...this.eventsList, ...response?.data?.events]
                } else {
                    this.eventsList = response?.data?.events
                }

            } else {
                this.eventsList = []
                this.isMoreData = false
            }
            this.loading = false
        },

        async handleLoadMore() {
            this.currentPage = this.currentPage + 1
            await this.fetchData(this.currentPage, this.searchQuery, true)
        },

        async handleSearchSubmit() {

            if (this.searchQuery == "") {
                return
            }
            await this.fetchData(this.currentPage, this.searchQuery)
        },

        async resetSearch() {
            this.searchQuery = ""
            await this.fetchData(this.currentPage, this.searchQuery)
        },

        goToFundTransferPage(event) {
            this.$router.push({
                name: 'FinanceEventResult',
                params: {
                    eventId: event.EventId
                },
                query: {
                    name: event.name,
                    status: event.result_status,
                    datetime: event.datetime
                }
            })
        }
    }
}
</script>
<template>
  <footer class="footer-wrap clearfix">
      <div class="max-width-wrap clearfix">
        <div class="tablet-wrap clearfix">
            <div class="column-one">
                <a :href="wpURL" class="logo">
                    <img src="/assets/images/my-whoosh-logo-white-v3.png" alt="MyWhoosh Logo">
                </a>
                <div class="address-wrap">
                  <img src="/assets/images/pin-icon-v1.svg" alt="" class="pin-icon">
                  <div class="address">
                    <p>Abu Dhabi, UAE</p>
                  </div>
                </div>
                <div class="email-wrap">
                  <img src="/assets/images/mail-icon-v1.svg" alt="" class="mail-icon">
                  <a href="mailto:support@mywhoosh.com" class="email">support@mywhoosh.com</a>
                </div>
                <div class="copyright-wrap-desktop">
                  <p>&copy; {{currentYear}} MyWhoosh. All rights reserved.</p>
                </div>
            </div>
            <div class="column-two">
                <h2>Navigation</h2>
                <a class="page-hyper-link" :href="`https://mywhoosh.com/how-it-works/${makeQueryString}`">Get Started</a>
                <a class="page-hyper-link" :href="`https://mywhoosh.com/about/${makeQueryString}`">About Us</a>
                <a class="page-hyper-link" :href="`https://mywhoosh.com/getting-started-mywhoosh-cycling-app/${makeQueryString}`">Download App</a>
                <a class="page-hyper-link" :href="`https://mywhoosh.com/terms-and-conditions/${makeQueryString}`">Terms &amp; Conditions</a>
                <a class="page-hyper-link" :href="`https://mywhoosh.com/privacy-policy/${makeQueryString}`">Privacy Policy</a>
            </div>
            <div class="column-three">
                <h2>Community</h2>
                <a class="page-hyper-link" :href="`https://mywhoosh.com/events/${makeQueryString}`">Events</a>
                <a class="page-hyper-link" :href="`https://mywhoosh.com/blog/${makeQueryString}`">Blog</a>
                <a class="page-hyper-link" :href="`https://mywhoosh.com/routes/${makeQueryString}`">Routes</a>
                <a class="page-hyper-link" :href="`https://results.mywhoosh.com/${makeQueryString}`">Results</a>
                <a class="page-hyper-link" :href="`https://mywhoosh.com/media/${makeQueryString}`">Media</a>
                <a class="page-hyper-link" :href="`https://mywhoosh.com/contact-us/${makeQueryString}`">Contact Us</a>
            </div>
        </div>
        <div class="column-four clearfix">
            <h2>Find Us On Social Media</h2>
            <a class="social-link" :href="setting.facebook">
                <img src="/assets/images/facebook-logo-v1.svg" alt="Facebook">
            </a>
            <a class="social-link" href="setting.twitter">
                <img src="/assets/images/twitter-logo-v1.svg" alt="Twitter">
            </a>
            <a class="social-link" :href="setting.linkedin">
                <img src="/assets/images/linkedin-logo-v1.svg" alt="LinkedIn">
            </a>
            <a class="social-link" :href="setting.instagram">
                <img src="/assets/images/instagram-logo-v1.svg" alt="Instagram">
            </a>
            <a class="social-link" :href="setting.youtube">
                <img src="/assets/images/youtube-logo-v1.svg" alt="YouTube">
            </a>
            <a class="social-link" :href="setting.strava">
                <img src="/assets/images/strava-logo-v1.svg" alt="Strava">
            </a>
        </div>
        <div class="copyright-wrap-mobile">
            <p>&copy; {{currentYear}} MyWhoosh. All rights reserved.</p>
        </div>
    </div>
  </footer>
</template>

<style scoped>
footer.footer-wrap {

  background-color: #0a0a0a;
  padding: 24px 32px 34px;
  overflow: hidden;


}

@media (min-width: 768px) {
  footer.footer-wrap {
    padding: 24px 0 34px;
  }
}

@media (min-width: 1200px) {
  footer.footer-wrap {
    padding: 65px 50px 34px;
  }
}

@media (min-width: 1400px) {
  footer.footer-wrap {
    padding: 65px 0 34px;
  }
}

footer.footer-wrap .max-width-wrap {
  width: 100%;
  margin: 0 auto;
  max-width: 400px;
}

@media (min-width: 768px) {
  footer.footer-wrap .max-width-wrap {
    max-width: 1180px;
  }
}

@media (min-width: 768px) {
  footer.footer-wrap .tablet-wrap {
    width: 100%;
    max-width: 700px;
    margin: 0 auto;
  }
}

@media (min-width: 900px) {
  footer.footer-wrap .tablet-wrap {
    max-width: 850px;
  }
}

@media (min-width: 1200px) {
  footer.footer-wrap .tablet-wrap {
    max-width: none;
    float: left;
    width: 75%;
  }
}

footer.footer-wrap .column-one {
  text-align: center;
  width: 100%;
  float: left;
}

@media (min-width: 768px) {
  footer.footer-wrap .column-one {
    width: 33%;
  }
}

footer.footer-wrap .column-one a.logo {
  display: block;
  width: 100%;
  max-width: 153px;
  margin: 0 auto 45px;
}

@media (min-width: 1200px) {
  footer.footer-wrap .column-one a.logo {
    max-width: 189px;
    margin: 0 0 40px 20px;
  }
}

footer.footer-wrap .column-one a.logo img {
  max-width: 100%;
  height: auto;
}

footer.footer-wrap .column-one .address-wrap,
footer.footer-wrap .column-one .email-wrap {
  text-align: left;
}

footer.footer-wrap .column-one .address-wrap img.pin-icon,
footer.footer-wrap .column-one .email-wrap img.pin-icon {
  width: 19px;
  margin: 0 19px 0 0;
  display: inline-block;
  vertical-align: top;
}

footer.footer-wrap .column-one .address-wrap img.mail-icon,
footer.footer-wrap .column-one .email-wrap img.mail-icon {
  width: 20px;
  margin: 0 15px 0 0;
  display: inline-block;
  vertical-align: middle;
}

footer.footer-wrap .column-one .address-wrap .address,
footer.footer-wrap .column-one .email-wrap .address {
  display: inline-block;
}

footer.footer-wrap .column-one .address-wrap .address p,
footer.footer-wrap .column-one .email-wrap .address p {
  font-family: "Inter", sans-serif;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  font-size: 12px;
  line-height: 23px;
  letter-spacing: 0.01em;
  color: #e6e6e6;
  margin: 0;
}

@media (min-width: 1200px) {

  footer.footer-wrap .column-one .address-wrap .address p,
  footer.footer-wrap .column-one .email-wrap .address p {
    font-size: 14px;
    line-height: 24px;
  }
}

footer.footer-wrap .column-one .address-wrap a.email,
footer.footer-wrap .column-one .email-wrap a.email {
  display: inline-block;
  text-decoration: none;
  font-family: "Inter", sans-serif;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  font-size: 12px;
  line-height: 1;
  letter-spacing: 0.01em;
  color: #e6e6e6;
  margin: 0;
}

@media (min-width: 1200px) {

  footer.footer-wrap .column-one .address-wrap a.email,
  footer.footer-wrap .column-one .email-wrap a.email {
    font-size: 14px;
  }
}

footer.footer-wrap .column-one .address-wrap a.email:hover,
footer.footer-wrap .column-one .email-wrap a.email:hover {
  text-decoration: underline;
}

footer.footer-wrap .column-one .address-wrap {
  margin: 0 0 45px 0;
}

footer.footer-wrap .column-one .email-wrap {
  margin: 0 0 70px 0;
}

footer.footer-wrap .column-one .copyright-wrap-desktop {
  display: none;
  text-align: left;
}

@media (min-width: 1200px) {
  footer.footer-wrap .column-one .copyright-wrap-desktop {
    display: block;
  }
}

footer.footer-wrap .column-one .copyright-wrap-desktop p {
  font-family: "Inter", sans-serif;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  font-size: 14px;
  line-height: 1;
  letter-spacing: 0.01em;
  color: #ededed;
  margin: 0;
}

footer.footer-wrap .column-two,
footer.footer-wrap .column-three {
  width: 50%;
  float: left;
  text-align: left;
}

@media (min-width: 768px) {

  footer.footer-wrap .column-two,
  footer.footer-wrap .column-three {
    width: 33%;
    padding: 17px 0 0 85px;
  }
}

@media (min-width: 1200px) {

  footer.footer-wrap .column-two,
  footer.footer-wrap .column-three {
    padding: 15px 0 0 85px;
  }
}

footer.footer-wrap .column-two h2,
footer.footer-wrap .column-three h2 {
  font-family: "Inter", sans-serif;
  font-weight: 700;
  -webkit-font-smoothing: antialiased;
  font-size: 16px;
  line-height: 30px;
  letter-spacing: 0.04em;
  color: #ffffff;
  margin: 0 0 28px;
  text-transform: uppercase;
}

footer.footer-wrap .column-two a.page-hyper-link,
footer.footer-wrap .column-three a.page-hyper-link {
  font-family: "Inter", sans-serif;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  font-size: 12px;
  line-height: 23px;
  letter-spacing: 0.01em;
  color: #ededed;
  margin: 0 0 35px;
  text-decoration: none;
  display: block;
  text-transform: uppercase;
}

@media (min-width: 768px) {

  footer.footer-wrap .column-two a.page-hyper-link,
  footer.footer-wrap .column-three a.page-hyper-link {
    margin: 0 0 25px;
  }
}

@media (min-width: 1200px) {

  footer.footer-wrap .column-two a.page-hyper-link,
  footer.footer-wrap .column-three a.page-hyper-link {
    margin: 0 0 35px;
  }
}

footer.footer-wrap .column-two a.page-hyper-link:hover,
footer.footer-wrap .column-three a.page-hyper-link:hover {
  text-decoration: underline;
}

@media (max-width: 767px) {
  footer.footer-wrap .column-three {
    float: right;
    width: 115px;
  }
}

footer.footer-wrap .column-four {
  text-align: center;
  width: 100%;
  float: left;
  margin: 13px 0 62px;
}

@media (min-width: 768px) {
  footer.footer-wrap .column-four {
    float: none;
    width: 300px;
    margin: 13px auto 42px;
  }
}

@media (min-width: 1200px) {
  footer.footer-wrap .column-four {
    width: 25%;
    float: left;
    margin: 15px 0 0;
  }
}

footer.footer-wrap .column-four h2 {
  font-family: "Inter", sans-serif;
  font-weight: 700;
  -webkit-font-smoothing: antialiased;
  font-size: 16px;
  line-height: 30px;
  letter-spacing: 0.04em;
  color: #ffffff;
  margin: 0 0 28px;
  text-transform: uppercase;
  width: 100%;
  float: left;
}

@media (min-width: 1200px) {
  footer.footer-wrap .column-four h2 {
    text-align: left;
    margin: 0 0 28px 15px;
  }
}

footer.footer-wrap .column-four a.social-link {
  position: relative;
  text-decoration: none;
  display: block;
  width: 16.6666666667%;
  height: 30px;
  float: left;
  margin: 0;
}

footer.footer-wrap .column-four a.social-link:hover {
  text-decoration: none;
}

footer.footer-wrap .column-four a.social-link img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
}

footer.footer-wrap .copyright-wrap-mobile {
  text-align: center;
  width: 100%;
  float: left;
}

@media (min-width: 1200px) {
  footer.footer-wrap .copyright-wrap-mobile {
    display: none;
  }
}

footer.footer-wrap .copyright-wrap-mobile p {
  font-family: "Inter", sans-serif;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  font-size: 12px;
  line-height: 23px;
  letter-spacing: 0.01em;
  color: #ededed;
  margin: 0;
}

footer.footer-wrap * {
  box-sizing: border-box;
}
</style>

<script>
import { mapGetters } from "vuex";


export default {
  name: "FooterComponent",

  

  computed: {
    ...mapGetters("player", ["loggedInPlayerData"]),

    getUserName() {
      return this.loggedInPlayerData?.firstName + " " + this.loggedInPlayerData?.lastName

    },

    makeQueryString() {
      return `?login=${this.loggedInPlayerData ? true : false}${this.loggedInPlayerData ? `&profile=${this.getUserName}` : ""}`
    }
  },

  data() {
    return {
      submitted: false,
      form: {
        email: null,
      },
      year: new Date().getFullYear(),
      wpURL: process.env.VUE_APP_WEB_URL,
      setting: {},
      currentYear: new Date().getFullYear()
    };
  },

  mounted() {
    // check if player is logged in
    this.$store.dispatch("auth/loggedIn");
  },

  methods: {
    submitHandler() {

    }
  },
};
</script>
<template>
  <page-heading>ADD New Template</page-heading>

  <AdminNavigation
    @listenerChild="listenerChild"
    :eventId="eventId"
    :event="event"
    :filterData="filterData"
    :eventListPage="$route.params.eventListPage"
  />

  <div class="container bg-white pt-2 shadow">
    <div class="d-flex mt-4 flex-column">
      <div class="d-flex flex-column form-group flex-grow-1">
        <label for="templateName">- Template Name</label>
        <input
          id="templateName"
          name="templateName"
          class="form-control"
          v-model="templateName"
          :class="emptyHeadingErrors['templateName'] ? 'is-invalid' : ''"
          @keyup="emptyHeadingErrors['templateName'] && delete emptyHeadingErrors['templateName']"
        />

        <small :style="{ color: '#dc3545' }">{{ emptyHeadingErrors['templateName'] }}</small>
      </div>

      <div class="d-flex flex-column form-group flex-grow-1">
        <label for="currency">- Select Currency - [FOR RESULT WEBSITE SHOW ONLY] </label>
        <select
          id="currency"
          name="currency"
          class="form-control"
          as="select"
          v-model="selectedCurrency" 
          :class="emptyHeadingErrors['currency'] ? 'is-invalid' : ''"
          @change="handleCurrencyChange($event.target.value)"
        >
          <option
            v-for="(item, i) in ['USD', 'AED']"
            :value="item"
            :key="i"
          >{{ item }}</option>
        </select>
        <small :style="{ color: '#dc3545' }">{{ emptyHeadingErrors['currency'] }}</small>
      </div>

      <div class="d-flex flex-column form-group flex-grow-1">
        <label for="conversionRate">- Conversion Rate <sub>Do not change default rates unless required</sub> - [FOR RESULT WEBSITE SHOW ONLY]</label>
        <input
          id="conversionRate"
          name="conversionRate"
          class="form-control"
          type="number"
          :disabled="selectedCurrency == 'AED'"
          v-model="conversionRate"
          :max="selectedCurrency == 'AED' ? 1 : 5"
          :min="1"
          :class="emptyHeadingErrors['conversionRate'] ? 'is-invalid' : ''"
          @change="emptyHeadingErrors['conversionRate'] && delete emptyHeadingErrors['conversionRate']"
        />

        <small :style="{ color: '#dc3545' }">{{ emptyHeadingErrors['conversionRate'] }}</small>
      </div>

    </div>

    <hr />

    <section class="pt-4">
      <!-- Tabs Navigation -->
      <nav class="bg-light">
        <div class="nav nav-tabs" id="nav-tab" role="tablist">
          <a
            v-for="(category, index) in categories"
            :key="index"
            class="nav-item nav-link"
            :class="{ active: selectedCategoryId === category.id }"
            @click.prevent="setSelectedCategory(category.id)"
            :id="'nav-' + category.id + '-tab'"
            data-toggle="tab"
            :href="'#nav-' + category.id"
            role="tab"
            :aria-controls="'nav-' + category.id"
            :aria-selected="selectedCategoryId === category.id"
          >{{ category.name }}</a>
        </div>
      </nav>

      <!-- Tabs Content -->
      <div class="tab-content mt-4" id="nav-tabContent">
        <div
          v-for="category in categories"
          :key="'content-' + category.id"
          class="tab-pane fade"
          :class="{ 'show active': selectedCategoryId === category.id }"
          :id="'nav-' + category.id"
          role="tabpanel"
          :aria-labelledby="'nav-' + category.id + '-tab'"
        >
          <!-- Form -->
          <form @submit.prevent="saveFormData">
            <div v-for="(item, index) in leaderboards" :key="index">
              <div class="d-flex flex-row gap-2">
                <div class="d-flex align-items-center col-2">
                  <label class="p-0 m-0">{{ item.name }}</label>
                </div>

                <div class="flex-grow-1">
                  <!-- Heading Input -->
                  

                  <!-- Prize Tags Input -->
                  <div class="d-flex flex-column">
                    <small class="p-0 mt-2">Enter Prizes <span class="badge badge-danger">(ONLY AED)</span> (Press Enter for each rank prize)</small>
                    <Vue3TagsInput
                      :tags="formData[selectedCategoryId][item.id].prizes"
                      placeholder="Enter Prize"
                      :validate="customValidate"
                      :allow-duplicates="true"
                      :class="emptyPrizesErrors[selectedCategoryId]?.[item.id] ? 'is-invalid' : ''"
                      @on-tags-changed="handleChangeTag($event, selectedCategoryId, item.id)"
                    />
                    <small
                      v-if="emptyPrizesErrors[selectedCategoryId]?.[item.id]"
                      style="color: #dc3545;"
                    >{{ emptyPrizesErrors[selectedCategoryId]?.[item.id] }}</small>
                  </div>
                </div>
              </div>

              <div v-if="index !== leaderboards?.length - 1">
                <hr />
              </div>
            </div>

            <!-- Submit Button -->
            <div class="d-flex justify-content-end mb-4 mt-2 pb-2">
              <button type="submit" class="btn btn-dark mt-3 btn-lg" style="width: 350px;">Submit</button>
            </div>
          </form>
        </div>
      </div>
    </section>
  </div>
</template>

 
<script>
import { mapActions } from "vuex";
import Vue3TagsInput from "vue3-tags-input";
import AdminNavigation from "@/shared/components/AdminNavigation.vue";

export default {
  name: "AddNewPrizeMoneyTemplate",
  components: {
    Vue3TagsInput,
    AdminNavigation
  },

  data() {
    return {
      selectedCurrency: "",
      templateName: "",
      conversionRate: 1,
      queryTemplateId: this.$route.query?.templateId || null,
      templateId: null,
      emptyHeadingErrors: {},
      emptyPrizesErrors: {},
      tags: [],
      payloadTags: {},

      formData: {},

      sameAcrossAllCategories: null,
      leaderboards: [
        {
          id: "individual",
          name: "Individual"
        },
        {
          id: "teams",
          name: "Teams"
        },
        {
          id: "9",
          name: "Sprint Overall"
        },
        {
          id: "4",
          name: "King/Queen Of Moutains Overall"
        },
        {
          id: "segments",
          name: "Per Segment"
        }
      ],

      categories: [
        { id: 1, name: "Category 1" },
        { id: 2, name: "Category 2" },
        { id: 3, name: "Category 3" },
        { id: 4, name: "Category 4" },
        { id: 5, name: "Category 5" },
        { id: 6, name: "Category 6" }
      ],
      selectedCategoryId: 1
    };
  },

  created() {
    this.initializeFormData();
  },

  computed: {

    formattedPrizes() {
      return (categoryId, leaderboardId) => {
        if (
          !this.formData[categoryId] ||
          !this.formData[categoryId][leaderboardId]?.prizes
        ) {
          return [];
        }

        return this.formData[categoryId][leaderboardId].prizes.map(
          (prize, index) => {
            return `Rank-${index + 1} : AED ${prize}`;
          }
        );
      };
    }
  },

  async mounted() {
    if (this.queryTemplateId) {
      const response = await this.getEventPrizeMoneyTemplate({
        templateId: this.queryTemplateId
      });

      if (response?.code == 200 && !response.error && response.data) {
        this.populateFormWithExistingData(response.data);
      }
    }
  },

  methods: {
    ...mapActions("event", [
      "getEventPrizeMoneyTemplate",
      "addEventPrizeMoneyTemplate"
    ]),

    handleCurrencyChange(value) {

      if (value === 'USD') {
        this.conversionRate = 3.67
      } else {
        this.conversionRate = 1
      }
      this.emptyHeadingErrors['currency'] = ""
      this.emptyHeadingErrors['conversionRate'] = ""

    },

    setSelectedCategory(categoryId) {
      this.selectedCategoryId = categoryId;
    },

    initializeFormData() {
      this.categories.forEach(category => {
        // Ensure formData, emptyHeadingErrors, and emptyPrizesErrors exist for each category
        if (!this.formData[category.id]) this.formData[category.id] = {};

        if (!this.emptyPrizesErrors[category.id])
          this.emptyPrizesErrors[category.id] = {};

        this.formData[category.id] = this.leaderboards.reduce(
          (lbAcc, leaderboard) => {
            lbAcc[leaderboard.id] = { prizes: [], prizesValues: [] };

            // Initialize validation errors as empty strings
            this.emptyPrizesErrors[category.id][leaderboard.id] = "";

            return lbAcc;
          },
          {}
        );
      });
    },


    populateFormWithExistingData(data) {
      this.templateName = data.templateName || "";
      this.selectedCurrency = data.currency || "";
      this.conversionRate = data.conversionRate;
      this.templateId = data._id;

      this.categories.forEach(category => {
        if (!this.formData[category.id]) this.formData[category.id] = {};
        if (!this.emptyPrizesErrors[category.id]) this.emptyPrizesErrors[category.id] = {};

        const categoryData = data.categories?.[category.id] || {};

        // ✅ Populate leaderboards for this category
        this.formData[category.id] = this.leaderboards.reduce((lbAcc, leaderboard) => {
          const leaderboardData = categoryData[leaderboard.id] || { prizes: [], };

          const numericPrizes = Array.isArray(leaderboardData.prizes) ? leaderboardData.prizes : [];


          lbAcc[leaderboard.id] = {
            prizes: Array.isArray(leaderboardData.prizes)
              ? leaderboardData.prizes.map((value, index) => `Rank ${index + 1}: AED ${value}`) // ✅ Format existing prizes
              : [],
            prizesValues: numericPrizes

          };

          return lbAcc;
        }, {});
      });
    }
    ,


    handleChangeTag(newTags, categoryId, leaderboardId) {
      if (!this.formData[categoryId]) {
        this.formData[categoryId] = {};
      }

      if (!this.formData[categoryId][leaderboardId]) {
        this.formData[categoryId][leaderboardId] = { prizes: [], prizesValues: [] };
      }



      // ✅ Extract valid numeric values while avoiding "Rank X: AED" formatted strings
      const extractedValues = newTags
        .map(tag => {
          if (typeof tag === "string") {
            // ✅ Corrected regex: Avoids extracting `1` from "Rank 1: AED 34"
            const numericValue = tag.replace(/Rank \d+: AED /, "").match(/^\d+(\.\d+)?$/);
            return numericValue ? Number(numericValue[0]) : NaN;
          }
          return Number(tag); // Directly parse if already a number
        })
        .filter(value => !isNaN(value) && value > 0); // ✅ Keep only valid numbers



      // ✅ Sync with Vue state properly
      this.formData[categoryId][leaderboardId].prizesValues = [...extractedValues];

      // ✅ Format `prizes` properly while keeping rank order
      this.formData[categoryId][leaderboardId].prizes = extractedValues.map((value, index) =>
        `Rank ${index + 1}: AED ${value}`
      );



      // ✅ Clear validation error if valid data is entered
      if (this.emptyPrizesErrors?.[categoryId]?.[leaderboardId]) {
        this.emptyPrizesErrors[categoryId][leaderboardId] = "";
      }
    }

    ,

    customValidate(value) {
      const regex = new RegExp(/^\d+(\.\d{1,2})?$/); // Allows both integers and decimals
      return regex.test(value);
    },

    async saveFormData() {
      let hasErrors = false;
      this.emptyHeadingErrors = {};
      this.emptyPrizesErrors = {};

      if (!this.selectedCurrency) {
        this.emptyHeadingErrors["currency"] = "Currency is requried";
        hasErrors = true;
      }
      if (!this.templateName || this.templateName == "") {
        this.emptyHeadingErrors["templateName"] = "Template Name is requried";
        hasErrors = true;
      }

      this.leaderboards.forEach(leaderboard => {
        // Ensure formData is properly initialized before accessing
        if (!this.formData[this.selectedCategoryId]) {
          console.error(
            `Error: formData for ${this.selectedCategoryId} is not initialized.`
          );
          return;
        }
        if (!this.formData[this.selectedCategoryId][leaderboard.id]) {
          console.error(
            `Error: formData[${this.selectedCategoryId}][${leaderboard.id}] is not initialized.`
          );
          return;
        }

        if (leaderboard.id !== 'teams') {
          const prizes = this.formData[this.selectedCategoryId][leaderboard.id].prizesValues;
          if (!prizes.length) {
            if (!this.emptyPrizesErrors[this.selectedCategoryId]) {
              this.emptyPrizesErrors[this.selectedCategoryId] = {};
            }
            this.emptyPrizesErrors[this.selectedCategoryId][leaderboard.id] =
              "At least one prize is required";
            hasErrors = true;
          }
        }

      });

      if (hasErrors) {
        return;
      }

      const finalPayload = {
        templateId: this.templateId,
        templateName: this.templateName,
        currency: this.selectedCurrency,
        conversionRate: this.conversionRate,
        categoryId: this.selectedCategoryId,
        leaderboards: Object.keys(this.formData[this.selectedCategoryId] || {}).reduce((acc, leaderboardId) => {
          acc[leaderboardId] = {
            prizes: this.formData[this.selectedCategoryId][leaderboardId].prizesValues || []
          };
          return acc;
        }, {})
      };

      const response = await this.addEventPrizeMoneyTemplate(finalPayload);
      if (response?.code == 200 && !response.error && response?.data?._id) {
        this.templateId = response?.data?._id;
      }

    }
  }
};
</script>
<template>

  
  <page-heading>
    {{ event?.name || "Event Name" }}
  </page-heading>

  <AdminNavigation
      @listenerChild="listenerChild"
      :eventId="eventId" :event="event"
      :filterData="filterData"
      :eventListPage="$route.params.eventListPage"/>

  <div class="container overflow-hidden">
    <div class="row align-items-center">
      <div class="col-md-12">
        <div class="white-top-bg pb-0">
          <div class="row">
            <div class="col-md-12">
              <div class="tab tab-left weight-tab">

                
                
                <div class="stage-wrap border">
                  <button class="arrow-btn" @click="updateStage('prev')">
                    <img src='/assets/arrow-left.png'/>
                  </button>
                  <select @change="updateDay" v-model="this.selectedDayId">
                    <option :value="day.DayId" v-for="(day, k) in days" :key="k">Day-{{ day.DayNo }}:
                      {{ day.DayName }}
                    </option>
                  </select>
                  <button class="arrow-btn" @click="updateStage('next')">
                    <img src='/assets/arrow-right.png'/>
                  </button>
                </div>


                <!-- <div class="stage-wrap border">
                  <select @change="updateDay" v-model="this.selectedDayId">
                    <option :value="day.DayId" v-for="(day, k) in days" :key="k">Day-{{ day.DayNo }}:
                      {{ day.DayName }}
                    </option>
                  </select>
                </div> -->

                <div class="stage-wrap border ml-4"  v-if="getIsEventTriathlon">
                  <button class="arrow-btn" @click="updateStage('prev')">
                    <img src='/assets/arrow-left.png'/>
                  </button>
                  <select @change="updateStage" v-model="this.selectedStageId">
                    <option :value="stage.StageId" v-for="(stage, k) in selectedDayStages" :key="k">Stage-{{ k + 1 }}:
                      {{ stage.Name }}
                    </option>
                  </select>
                  <button class="arrow-btn" @click="updateStage('next')">
                    <img src='/assets/arrow-right.png'/>
                  </button>
                </div>


                <!-- <div class="stage-wrap border ml-4" v-if="isEventTraithlon">
                  <select @change="updateStage" v-model="this.selectedStageId">
                    <option :value="stage.StageId" v-for="(stage, k) in selectedDayStages" :key="k">Stage-{{ k + 1 }}:
                      {{ stage.Name }}
                    </option>
                  </select>
                   
                </div> -->

              </div>
            </div>
          </div>


          <div class="row">
            <div class="col-md-8 mx-auto" v-if="selectedStageSegments?.length">
              <splide :options="options" :slides="selectedStageSegments">
                <splide-slide v-for="item in selectedStageSegments" :key="item?.label"
                              :class="activeItem?.id === item?.id && activeItem?.lapNo === item?.lapNo ? 'isActive' : 'splide-item-click'"
                              @click="filterData(item?.type, item?.id, item?.lapNo)">
                  <i :class="item?.icon"></i> {{ item?.label }}
                </splide-slide>
              </splide>

            </div>

          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="container">
    <div class="table-wrap">
      <div class="table-responsive individual-result result-edit-admin">
        <table class="table">
          <thead>
          <tr>
            <th scope="col" v-for="column in getColumns" :key="column.name">
              <span v-html="column.name"></span>
            </th>
          </tr>
          </thead>
          <page-loader v-if="loading"/>
          <tbody>
          <tr v-if="!form?.length">
            <td colspan="50" style="text-align: center">
              <strong>Sorry, there is no points set for this stage</strong>
            </td>
          </tr>
          <template v-if="form?.length">
            <tr v-for="(item, k) in form" :key="k"
                :class="{ 'removedPlayer': item.id === 'true', 'odd': k % 2 === 0, 'even': k % 2 !== 0 }">
              <td>
                {{ item.rank }}
              </td>
              <td>{{ item.points }}</td>
              <td>{{ item.lapNo }}</td>
              <td>{{ item.eventId }}</td>
              <td>{{ item.dayId }}</td>
             
              <td>{{ item.gateId || '-' }}</td>
            </tr>
          </template>
          </tbody>
        </table>
      </div>
    </div>
  </div>


  <!-- Add New Points Modal -->
  <AddPointsModal
      @listenerChild="listenerChild"
      @selectedDayDropdownListener="selectedDayDropdownListener"
      :event="event"
      :addPointsModal="addPointsModal"
      :days="days"
      :stages="selectedDayStages"
      :selectedStageSegments="selectedStageSegments"
      :isUCIEvent="getIsUCIEvent"
      :isPointBasedEvent="getIsisPointBasedEvent"
      :isTraithlonEvent="getIsEventTriathlon"
  />
  <!-- End of Add new Points Popup -->


  <!-- Remove Points Modal -->
  <RemovePointsModal
      @listenerChild="listenerChild"
      @selectedDayDropdownListener="selectedDayDropdownListener"
      :event="event"
      :removePointsModal="removePointsModal"
      :days="days"
      :stages="selectedDayStages"
      :selectedStageSegments="selectedStageSegments"
      :isUCIEvent="getIsUCIEvent"
      :isPointBasedEvent="getIsisPointBasedEvent"
      :isTraithlonEvent="getIsEventTriathlon"
  />
  <!-- End of Remove Points Modal -->

</template>

<style scoped>
.individual-result table tbody {
  text-transform: none
}
</style>

<script>
import $ from 'jquery';
import * as Yup from "yup";
import 'vue3-carousel/dist/carousel.css';
import 'sweetalert2/src/sweetalert2.scss'
import { mapActions, mapGetters } from "vuex";
import { Splide, SplideSlide } from '@splidejs/vue-splide';
import "@/theme/style/splide-default.min.css"; import AdminNavigation from "@/shared/components/AdminNavigation.vue";
import { selectionStatuses, } from '@/shared/events/columns.dto.ts';
import AddPointsModal from '../../components/modals/AddPointsModal.vue'
import RemovePointsModal from '../../components/modals/RemovePointsModal.vue';
import { isUCIEvent, isTraithlonEvent, isPointBasedEvent } from "@/shared/events/event.helpers.ts";
import { EventFormatType } from '@/shared/events/enums.ts';

export default {
  name: 'pointsMainPage',

  components: {
    AdminNavigation,
    Splide,
    SplideSlide,
    AddPointsModal,
    RemovePointsModal
  },
  computed: {
    ...mapGetters('event', ['event']),

    getDaysList() {
      return this.event?.ListOfDays || []
    },
    getSegments() {
      return this.selectedDayStages.find(item => isTraithlonEvent ? (item.StageId === this.selectedStageId) : (item.DayId === this.selectedDayId))?.ListOfGates || []
    },
    getColumns() {
      return this.columns
    },
    getIsUCIEvent() {
      return isUCIEvent(this.event)
    },
    getIsEventTriathlon() {
      return isTraithlonEvent(this.event)
    },
    getIsisPointBasedEvent() {
      return isPointBasedEvent(this.event)
    }


  },
  data() {
    return {

      addPointsModal: false,
      removePointsModal: false,
      columns: [
        {
          id: 1,
          name: 'Rank',
          type: 'text'
        },

        {
          id: 6,
          name: 'Points',
          type: 'text',

        },
        {
          id: 5,
          name: 'Lap No',
          type: 'text',

        },

        {
          id: 2,
          name: 'EventId',
          type: 'text',

        },
        {
          id: 3,
          name: 'Day Id',
          type: 'text',

        },
        {
          id: 5,
          name: 'Segment Id',
          type: 'text',

        }
      ],
      selectionStatuses: selectionStatuses,
      loading: false,
      refresh: 0,
      eventId: this.$route.params.eventId,


      form: [],
      activeItem: { id: "1", index: 0, type: "gate", lapNo: 1 },
      items: [],
      filterItems: [],
      options: {
        rewind: false,
        gap: 0,
        perPage: 3,
        start: 0,
        pagination: false,
        breakpoints: {
          1400: {
            perPage: 2,
          },
          1000: {
            perPage: 1,
          }
        }
      },
      overAllGates: [],


      currentDayId: "",
      days: [],
      selectedDayStages: [],
      selectedStageSegments: [],
      selectedDayId: null,
      selectedStageId: null,
      isStages: false,
      isSeriesEvent: false,
    };
  },

  setup() {
    const schema = Yup.object().shape({
      samePointsAcross: Yup.string().required("please choose option"),
      pointsArr: Yup.string().required("please enter points"),
    });
    return {
      schema,
    };
  },

  async mounted() {
    await this.getEvent(this.eventId);
    await this.setDaysAndGates()
    await this.generateSegments()
    await this.filterData("individual", "default", 1); //select the first segment by default
  },

  methods: {
    ...mapActions('event', ['getEventPoints', 'getEvent', 'publishEventResult']),

    async setDaysAndGates() {
      const ListOfDays = this.getDaysList || [];
      this.selectedDayId = ListOfDays[0]?.DayId
      this.days = ListOfDays
        .flatMap(day => (day?.Stages || []).map(stage => ({
          DayId: day.DayId || stage.DayId,
          DayNo: day.DayNo,
          DayName: stage.DayName,
          NoOfLaps: stage.NoOfLaps
        })))
        .filter((v, i, a) => a.findIndex(t => t.DayId === v.DayId) === i);
      this.selectedStageId = ListOfDays[0].Stages[0]?.StageId
      this.selectedDayStages = ListOfDays.find(item => item.DayId === this.selectedDayId)?.Stages || []
      this.selectedStageSegments = this.selectedDayStages?.find(item => item.StageId === this.selectedStageId)?.ListOfGates
      this.isSeriesEvent = this.days?.length > 1;
    },

    async generateSegments() {
      const duplicateGates = []
      if (this.event.EventFormatType == EventFormatType.POINTS_BASED && !this.getIsUCIEvent) {
        duplicateGates.push({
          id: "individual",
          order: 1,
          label: 'Individual',
          type: 'individual',
          icon: "",
          lapNo: 1
        })
      }
      this.getSegments?.map((gate) => {
        const gates = { ...gate, NoOfIterations: gate.NoOfIterations || 1 } //if any gate doesnt have totalRepitition it will set it as 1
        for (let i = 1; i <= gates.NoOfIterations; i++) {
          duplicateGates.push({
            id: gate.GateId,
            order: gate.GateOrder,
            label: gate.GateName + (i != 1 ? " - Lap " + i : ""),
            type: 'gate',
            icon: "",
            lapNo: i
          })
        }
      })

      if (this.getIsUCIEvent) {
        const currentDay = this.days.find(item => item.DayId == this.selectedDayId)
        for (let i = 1; i <= currentDay?.NoOfLaps; i++) {
          duplicateGates.push({
            id: 'individual',
            order: 0,
            label: currentDay?.NoOfLaps > 1 ? ("Lap - " + i) : "Individual",
            type: 'individual',
            icon: "",
            lapNo: i
          })
        }
      }
      this.selectedStageSegments = (duplicateGates.length ? duplicateGates : []).sort((a, b) => a.order - b.order);
    },

    async setActiveFilter() {
      this.options.start = this.activeItem?.order || 0;
    },

    listenerChild(childResponseObj) {
      childResponseObj.type == 'add-points' ? this.addPointsModal = childResponseObj.popup : this.removePointsModal = childResponseObj.popup
    },


    async filterData(filterType, key = null, lapNumber) {
      $('.individual-result tbody td').addClass('blurry');

      this.loading = true;
      this.activeItem = this.selectedStageSegments.find(i => i?.type === filterType && i?.id === key && i?.lapNo == lapNumber) || this.selectedStageSegments[0];
      let result = [];
      const queryPayload = {
        eventId: this.eventId,
        dayId: this.selectedDayId,
        gateId: key === 'default' ? this.activeItem?.id : key,
        type: this.activeItem?.type || 'individual',
        lapNo: lapNumber || 1,
        isUCIEvent: this.getIsUCIEvent
      };
      const getResponse = await this.getEventPoints(queryPayload);
      result = getResponse ? getResponse.data : []
      this.form = result;
      await this.setActiveFilter();
      setTimeout(function () {
        $('.individual-result tbody td').removeClass("blurry");
      }, 600);
      this.loading = false;
    },

    async updateDay() {
      this.isStages = true;
      // this.currentStage = this.currentStage + 1;
      this.selectedDayStages = this.getDaysList.find(item => item.DayId === this.selectedDayId)?.Stages || []
      this.selectedStageId = this.selectedDayStages[0].StageId
      await this.generateSegments()
      await this.filterData("individual", "default", 1);
      this.refresh++;
      this.reloadTable++;
    },

    async updateStage() {
      this.isStages = true;
      await this.generateSegments()
      await this.filterData("individual", "default", 1);
      this.refresh++;
      this.reloadTable++;
    },


  }
};
</script>

<template>
    
    <div>  
          <nav class="d-flex navbar bg-warning   justify-content-between align-items-center  align">



            <div class="d-flex flex-column text-center justify-content-center aling-items-center">
                <span>  Welcome  <strong>{{getUserName}}!</strong></span>
                <span>  Role: {{ getRole }} </span> 
            </div>

                    <div class="d-flex flex-column text-center justify-content-center aling-items-center">
                        <p class="p-0 m-0">  Mywhoosh Results Admin   </p>
                        <p class="p-0 m-0"> {{convertISODateTime(new Date)}}</p>
                    </div>
                     
                    <button class="btn btn-dark btn-md" @click="logoutHandler">Logout</button>
                  
                  </nav>  
    </div>
</template>

<script>
import { mapActions } from 'vuex';
import { convertISODateTime } from '../../../shared/events/event.helpers.ts';

 export default {
    name: 'FinanceRoleHeader',
    props:["loggedIn", "user"],

    data(){
        return{
            convertISODateTime: convertISODateTime
        }
    },

    computed:{
        getUserName() {
        return this.user?.name
        },
        getRole(){
            return this.user?.role
        }

    },
    methods:{

        ...mapActions("auth", ["logout"]),

        async logoutHandler(){
          await this.logout();
          await this.$router.push({ name: "AdminLogin" });
        }
    },

    mounted(){
     }
}
</script>
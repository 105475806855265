<style scoped>
.selectDropdown {
  width: 200px;
  font-family: 'Poppins', sans-serif !important;
}
</style>


<template>

    <page-heading>
        {{ event?.name || "Event Name" }}

      </page-heading>


      <div class="text-center">
       <h3>{{event?.datetime}}</h3>
      </div>

    
      <AdminNavigation
          @listenerChild="listenerChild"
          :eventId="eventId" 
          :event="event"
          :filterData="filterData"
          :eventListPage="$route.params.eventListPage"/>


          <!-- filters -->
          <div class="container overflow-hidden">
            <div class="row align-items-center">
              <div class="col-md-12">
                <div class="white-top-bg pb-0">
                  <div class="row">
                    <div class="col-md-12">
                      <div class="tab tab-left weight-tab">
        
                        
                        
                        <!-- <div class=""> 
                          <select @change="updateDay" v-model="this.selectedDayId" class="selectDropdown p-2 m-2 border">
                            <option :value="day.DayId" v-for="(day, k) in days" :key="k">Day-{{ day.DayNo }}:
                              {{ day.DayName }}
                            </option>
                          </select>
                        
                        </div> -->
        
        
                    
                        <div>
                          
                          <select 
                          @change="updateCategory($event.target.value)"
                          class="selectDropdown p-2 m-2 border"
                          v-model="selectedCategory"
                          >

                            <option :value="cat.CategoryId" v-for="(cat, k) in getCategories" :key="k">
                              {{ cat.Label }}
                            </option>
                          </select>
                          
                        </div>
        
         
        
                      </div>
                    </div>
                  </div>
        
        
                  <div class="row">
                    <div class="col-md-8 mx-auto" v-if="selectedStageSegments?.length">
                      <splide :options="options" :slides="selectedStageSegments">
                        <splide-slide v-for="item in selectedStageSegments" :key="item?.label"
                                      :class="activeItem?.id === item?.id && activeItem?.lapNo === item?.lapNo ? 'isActive' : 'splide-item-click'"
                                      @click="filterData(item?.id, item?.lapNo)">
                          <i :class="item?.icon"></i> {{ item?.label }}
                        </splide-slide>
                      </splide>
        
                    </div>
        
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- Table -->

          <div class="container">
            <div class="table-wrap">
              <div class="table-responsive individual-result result-edit-admin">
                <table class="table">
                  <thead>
                  <tr>
                    <th scope="col" v-for="column in getColumns" :key="column.name">
                      <span v-html="column.name"></span>
                    </th>
                  </tr>
                  </thead>
                  <page-loader v-if="loading"/>
                  <tbody>
                  <tr v-if="!rowData?.length">
                    <td colspan="50" style="text-align: center">
                      <strong>Sorry, there is no prize money set for this leaderboard</strong>
                    </td>
                  </tr>
                  <template v-if="rowData?.length">
                    <tr v-for="(item, k) in rowData" :key="k"
                        :class="{ 'removedPlayer': item.id === 'true', 'odd': k % 2 === 0, 'even': k % 2 !== 0 }">
                      <td>
                        {{ this.selectedCategory }}
                      </td>
                      <td>
                        {{ k + 1 }}
                      </td>
                      <td>
                          {{ getCurrency }} {{ item }}
                      </td>
                      <td>
                        {{ getWebHeading }}
                      </td> 
                    </tr>
                  </template>
                  </tbody>
                </table>
              </div>
            </div>
        </div>



        <!-- Add Prize Money Modal -->
        <AddPrizeMoneyModal 
        v-if="showAddPrizeModal"
        :eventName="event?.name" 
        :isShowModal="showAddPrizeModal"  
        @listenerChild="listenerChild"
        :categories="getCategories"
        :selectedDayId="selectedDayId"
        :selectedCategory="Number(selectedCategory)"
        :eventId="eventId"
        :leaderboards="gateList?.overallGates || []"
         />

         <RemovePrizeMoneyModal
          v-if="showRemovePrizeModal"
          :isShowModal="showRemovePrizeModal" 
          @listenerChild="listenerChild"
           :selectedCategory="{ label: `Category ${selectedCategory}`, value: Number(selectedCategory) }"
          :categories="getCategories"
          :eventId="eventId"
          :leaderboards="gateList?.overallGates || []"
         />


       



    
</template>


<script>

import { mapActions, mapGetters } from "vuex";
import AdminNavigation from "@/shared/components/AdminNavigation.vue";
import $ from 'jquery';
import AddPrizeMoneyModal from '../../components/modals/AddPrizeMoneyModal.vue';
import RemovePrizeMoneyModal from "../../components/modals/RemovePrizeMoneyModal.vue";



export default {
  name: 'PrizeMoneyPage',
  components: {
    AdminNavigation,
    AddPrizeMoneyModal,
    RemovePrizeMoneyModal


  },

  data() {
    return {
      days: [],
      selectedStageSegments: [],
      selectedDayStages: [],
      selectedStageId: null,
      selectedDayId: null,
      eventId: this.$route.params.eventId,
      columns: [
        {
          id: 'category',
          name: 'Category',

        },

        {
          id: 'rank',
          name: 'Rank',

        },
        {
          id: 'prize',
          name: 'Prize Money',


        },
        {
          id: 'heading',
          name: 'Heading - (WEB)',
        },
      ],
      activeItem: { id: "1", index: 0, type: "gate", lapNo: 1 },
      selectedCategory: "",
      showAddPrizeModal: false,
      showRemovePrizeModal: false,

      options: {
        rewind: false,
        gap: 0,
        perPage: 3,
        start: 0,
        pagination: false,
        breakpoints: {
          1400: {
            perPage: 2,
          },
          1000: {
            perPage: 1,
          }
        }
      },
      loading: false,
      rowData: []
    }

  },

  computed: {
    ...mapGetters('event', ['event', 'gateList']),

    getDaysList() {
      return this.event?.ListOfDays || []
    },

    getColumns() {
      return this.columns
    },
    getCategories() {
      return this.event?.categories?.map(({ CategoryId, Label }) => ({ CategoryId, Label }));
    },

    getLeaderboardPrizes() {
      return this.event?.winners?.[this.selectedCategory]?.[this.activeItem.id]?.prizes ?? [];
    },
    getCurrency() {
      return this.event?.winners?.[this.selectedCategory]?.[this.activeItem.id]?.currency ?? '';
    },
    getWebHeading() {
      return this.event?.winners?.[this.selectedCategory]?.[this.activeItem.id]?.heading || "-";
    }

  },

  async mounted() {
    
    await this.getEvent(this.eventId);
    await this.setDaysAndGates()
    await this.generateSegments()
    this.selectedCategory = this.getCategories?.[0]?.CategoryId
    await this.filterData("individual", 1); //select the first segment by default
    await this.getGateList({ eventId: this.eventId });
     
  },



  methods: {
    ...mapActions('event', ['getEvent', 'getGateList']),

    async setDaysAndGates() {
      const ListOfDays = this.getDaysList || [];

      this.selectedDayId = ListOfDays[0]?.DayId


      this.days = ListOfDays
        .flatMap(day => (day?.Stages || []).map(stage => ({
          DayId: day.DayId || stage.DayId,
          DayNo: day.DayNo,
          DayName: stage.DayName,
          NoOfLaps: stage.NoOfLaps
        })))
        .filter((v, i, a) => a.findIndex(t => t.DayId === v.DayId) === i);


      this.selectedDayStages = ListOfDays.find(item => item.DayId === this.selectedDayId)?.Stages || []
      this.selectedStageId = this.selectedDayStages[0]?.StageId
      this.selectedStageSegments = this.selectedDayStages?.find(item => item.StageId == this.selectedStageId)?.ListOfGates
      this.isSeriesEvent = this.days?.length > 1;

    },

    async generateSegments() {
      const duplicateGates = []
      const overallGates = this.gateList?.overallGates || []

      duplicateGates.push({
        id: "individual",
        order: 1,
        label: 'Individual',
        type: 'individual',
        icon: "",
        lapNo: 1
      })

      duplicateGates.push({
        id: "teams",
        order: 1,
        label: 'teams',
        type: 'teams',
        icon: "",
        lapNo: 1
      })

      overallGates?.map((gate) => {
        duplicateGates.push({
          id: gate.GateId,
          order: 1,
          label: gate.GateName,
          type: 'gate',
          icon: "",
          lapNo: 1
        })
      })

      duplicateGates.push({
        id: "segments",
        order: 7,
        label: 'Per Segment',
        type: 'segments',
        icon: "",
        lapNo: 1
      })

      this.selectedStageSegments = duplicateGates.length ? duplicateGates : []
    },

    async filterData(key = null, lapNumber) {
      this.loading= true
      $('.individual-result tbody').addClass('blurry');

      this.activeItem = this.selectedStageSegments.find(i => i?.id === key && i?.lapNo == lapNumber) || this.selectedStageSegments[0];

      this.rowData = await this.getLeaderboardPrizes

      setTimeout(function () {
        $('.individual-result tbody').removeClass("blurry"); 
      }, 600);
      
      setTimeout(()=>{
        this.loading= false
      }, 600)

    },



    async updateDay() {
      await this.generateSegments()
      await this.filterData("individual", 1);
    },

    async updateCategory(value) {
      this.selectedCategory = value
      await this.filterData("individual", 1);


    },

    async listenerChild(receivedData) {
      if (!receivedData) return;
      if (receivedData?.type == 'add-prize-money') {
        this.showAddPrizeModal = receivedData?.popup
      }
      if (receivedData?.type == 'remove-prize-money') {
        this.showRemovePrizeModal = receivedData?.popup
      }
      if (receivedData?.category) {
        this.selectedCategory = receivedData?.category
      }

       if (receivedData?.apiCall) {
        await this.getEvent(this.eventId);
        await this.filterData("individual", 1);
      }

    },

    
     
    




  }

}
</script>
 

<style scoped>
:deep(.modal-container) {
    display: grid;
    justify-content: center;
    align-items: center;
    width: 100%;
}

:deep(.modal-content) {
    position: relative;
    display: flex;
    flex-direction: column;

    margin: 0 1rem;
    padding: 1rem;
    border: 1px solid #e2e8f0;
    border-radius: 0.25rem;
    background: #fff;
}

.modal__title {
    margin: 0 2rem 0 0;

    font-weight: 700;
    text-align: center;
    text-decoration: underline;

    color: #fcb913;
    font-size: 30px;

    text-transform: uppercase;
    font-family: "Barlow Condensed", sans-serif !important;
}

.modal__content {

    overflow-y: auto;
    padding: 21px;
    width: 800px;
    height: 500px;
}

.modal__close {
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
    width: 50px;
}
</style>

<style scoped>
.dark-mode div:deep(.modal-content) {
    border-color: #2d3748;
    background-color: #1a202c;
}

#centerlineTextDiv {
    height: 1px;
    border-top: 1px solid rgba(0, 0, 0, .1);
    text-align: center;
    position: relative;
}

#centerlineTextDiv>span {
    position: relative;
    top: -.8em;
    background: #fcb913;
    display: inline-block;
}
</style>

<template>
    
    <VueFinalModal
    v-model="openModal"
    classes="modal-container"
    content-class="modal-content"
    :clickToClose="false"
    class="popup"
  >
    <button class="modal__close btn btn-warning btn-sm" @click="handleClose()">
      <i class="fa fa-times" aria-hidden="true"></i>
    </button>

    <div class="d-flex align-items-center justify-content-center">

        <span v-html="mapCountryFlag(modalData?.userCountryFlag)"></span>

        <div class="d-flex flex-column mx-2">
            <small>{{ modalData?.userFullName }}</small>
            <small>{{ modalData?.userId }}</small>
        </div>

       
    </div>

    <hr>

   


 
    <form @submit.prevent="handleSubmit">
    <div class="modal__content">

        <page-loader v-if="loading"></page-loader>

         <!-- Overall Teams -->
     
    
        <div id="centerlineTextDiv" class="mt-3 mb-3" v-if="!IsProcessedAll"><span> Player Prize Money</span></div>
          <div class="d-flex flex-column" >
        
          <div class="d-flex flex-row gap-2 "> 
            <div class="d-flex flex-column justify-content-center align-items-start col-4 text-wrap" v-if="!IsProcessedAll"> 
              <label for="label" class="p-0 m-0 text-uppercase">Total</label>
              <sub>(Winning Amount)</sub>
             </div>
  

            <div class="flex-grow-1" v-if="!IsProcessedAll"> 
                <div class="d-flex flex-row gap-2 mt-4"> 
                    <h5> {{ "AED" }} {{ getRemainingAmount }}</h5> 
                    <sub for="totalAmount" class="p-0 mt-2"></sub>                 
                </div> 
                <div  class="d-flex flex-column ">
                  <small for="transferAmount" class="p-0 mt-2">Enter Amount without currency. <span class="badge badge-danger">Note: Sending amount currency is AED</span> </small>
                    <input
                        id="transferAmount"
                        name="transferAmount"
                        class="form-control" 
                        type="number"
                        min="1"
                        @change="handleTextChange"
                        :max="getRemainingAmount"
                        v-model="transferAmount"
                        :class="{ 'is-invalid': emptyHeadingErrors['transferAmount'] }"
                        :disabled="modalData.isProcessed" 
                    />   
                    <div class="invalid-feedback">{{ emptyHeadingErrors['transferAmount'] }}</div>
                </div>

                


            </div>
          

          </div>

          

          <div id="centerlineTextDiv" class="mt-3 mb-3" v-if="modalData?.tansactionHistory?.length"><span> Transaction History</span></div>


            <div  v-if="modalData?.tansactionHistory?.length">
          <div  class="border p-2 mt-4" v-for="(transaction, index) in sortedTransactionHistory" :key="index"> 

            <div class="d-flex justify-content-between align-content-center" >
             <div class="d-flex flex-column">  Transfer Date/Time<span class="badge badge-success">{{ convertISODateTime(transaction.transferredDate) }}</span></div>
             <div class="d-flex flex-column">  Transfer Amount<span class=""><strong>AED {{ transaction.transferAmount }}</strong></span></div>
             <div class="d-flex flex-column">  Transfer By<span class="badge badge-success">{{ transaction.transferredBy }}</span></div>
            </div>
          </div>
        </div>


      </div>
     
    </div>



    <div v-if="!IsProcessedAll" class="d-flex justify-content-between gap-4 align-items-center border p-2 ">

        <label class="w-auto" for="password">Enter your Password</label>
        <div>
            <input class="form-control w-auto" placeholder="Enter your password" 
            
            type="password"  
            v-model="password"
            id="password" 
            name="password" 
            />
            <small v-if="emptyHeadingErrors['password']" style="color: #dc3545;">
                {{ emptyHeadingErrors['password'] }}
              </small>
    
        </div>
       
        <button class="btn btn-dark" style="width: 200px;" type="submit"  :disabled="loading">Transfer</button>
    </div>

    <div v-else class="border text-center p-2 bg-success text-white" >
        <p class="p-1 m-1"> Total: AED {{ modalData?.totalAmount }}</p>
        <p class="p-0 m-0">All Amount is Transfered! <i class="fa fa-check"></i></p>
    </div>
</form>
    

     
  </VueFinalModal>

</template>

 

<script>
import { VueFinalModal } from "vue-final-modal";
import { convertISODateTime, mapCountryFlag } from "../../../../shared/events/event.helpers.ts";
import { mapGetters, mapActions } from "vuex";



export default {
    name: 'PrizeMoneyTransferModal',
    props: ['showModal', 'modalData'],
    emits: ['listenerChild'],

    components: {
        VueFinalModal,

    },

    watch: {
        showModal(value) {
            this.openModal = value;
        },
         
    },



    data() {
        return {
            mapCountryFlag: mapCountryFlag,
            convertISODateTime: convertISODateTime,
            openModal: this.showModal,
            emptyHeadingErrors: {},
            formData: {},
            password: "",
            loading: false,
            transferAmount: null,
            transactionHistory: this.modalData?.tansactionHistory

        }
    },



    computed: {
        ...mapGetters("auth", ["user"]),

        getUserId() {
            return this.user.id;
        },


        getRemainingAmount() {
            const proccessedAmount = this.modalData?.tansactionHistory?.reduce((total, prize) => total + (prize.transferAmount || 0), 0) || 0;
            return this.modalData?.totalAmount - proccessedAmount
        },

        IsProcessedAll() {
            return this.modalData?.isProcessed
        },

        sortedTransactionHistory() {
            return this.modalData?.tansactionHistory
                ?.slice() // Clone to avoid mutating original data
                ?.sort((a, b) => new Date(b.transferredDate) - new Date(a.transferredDate)) || [];
        }



    },

    async mounted() {
    },

    methods: {

        ...mapActions("event", ["transferFunds"]),


        updateFormData(id, value) {
            this.formData[id] = value
        },

        customValidate(value) {
            const regex = new RegExp(/^([0-9]*)+([0-9]+)+$/);
            return regex.test(value)
        },

        handleTextChange() {
            this.emptyAmountError = ""
        },

        handleClose() {
            this.openModal = false;
            this.$emit("listenerChild", { type: 'transfer-money-modal', popup: false, });
        },

        async handleSubmit() {
            let hasError = false;

            if (!this.password || this.password == "") {
                this.emptyHeadingErrors['password'] = "Please enter your password."
                hasError = true
            }

            if (this.transferAmount == 0 || !this.transferAmount) {
                this.emptyHeadingErrors['transferAmount'] = "Please enter Transfer Amount."
                hasError = true
            }

            if (hasError) {
                return
            }

            this.loading = true;



            // Structure the form data
            const payload = {

                loggedInUserId: this.getUserId,
                password: this.password,
                transferAmount: this.transferAmount,
                ...this.modalData
                // eventId: this.modalData.eventId,
                // dayId: this.modalData.dayId,
                // stageId: this.modalData.stageId,
                // userId: this.modalData.userId,
                // loggedInUserId: this.getUserId, // Replace with actual logged-in user ID
                // password: this.password,
                // prizeData: this.modalData.prizeData.map((item) => ({
                //     transferAmount: this.formData[item.id] || item.sourceAmount, // Use entered amount or default
                //     transferCurrency: "AED",
                //     ...item
                // })),
            };

            const response = await this.transferFunds(payload)
            this.loading = false;

            if (!response?.error) {
                window.location.reload()
            }

        }





    }


}
</script>
// @ts-ignore
import AdminLayout from "@/theme/layouts/AdminLayout.vue";
import {
  Events,
  Login,
  EventResult2,
  AddNewPlayer,
  AddTriathlonPlayer,
  ListAllUsers,
  AddNewAdminUser,
  RolesPermissions,
  AddNewRole,
  PushNotificationsPage,
  pointsMainPage,
  jerseysListPage,
  TraithlonResults,
  PrizeMoneyPage,
  FinanceEventListPage,
  PrizeMoneyTemplatesPage,
  AddNewPrizeMoneyTemplatePage,
  ForbiddenPage,
  FinanceEventResultPage
  // @ts-ignore
} from "@/views/admin/views/index.ts";
// @ts-ignore
import Service from "@/services/http.ts";


const auth = async (to, from, next) => {
  const token = localStorage.getItem("resultAdminToken");

  if (!token) {
    if (to.name !== "AdminLogin") {
      return next({ name: "AdminLogin" });
    }
    return next();
  }

  try {
    const response = await Service(true).post("auth/admin/check-auth", { token });
    const userRole = response.data.role;


    const financeOnlyRoutes = ["FinanceEventsList", "FinanceEventResult"];
    const forbiddenForAdmin = ["FinanceEventsList", "FinanceEventResult"];

 
    // Prevent finance-admin from accessing non-finance pages
    if (userRole === "whoosh-finance-admin" && !financeOnlyRoutes.includes(to.name)) {
      if (to.name !== "ForbiddenPage") {
        return next({ name: "ForbiddenPage" });
      }
      return next();
    }

    // Prevent admin from accessing finance routes
    if (userRole === "whoosh-admin" && forbiddenForAdmin.includes(to.name)) {
      if (to.name !== "ForbiddenPage") {
        return next({ name: "ForbiddenPage" });
      }
      return next();
    }

    return next();
  } catch (error) {
    console.error("Auth Check Failed:", error);
    if (to.name !== "AdminLogin") {
      return next({ name: "AdminLogin" });
    }
    return next();
  }
};




const basePath = "/result-admin/";
const protectedRoutes = [
  {
    path: basePath,
    component: AdminLayout,
    meta: { requiresAuth: false },
    beforeEnter: auth,
    children: [

      {
        path: 'forbidden',
        name: 'ForbiddenPage',
        component: ForbiddenPage
      },

      {
        path: 'finance/events',
        name: 'FinanceEventsList',
        component: FinanceEventListPage
      },

      {
        path: 'finance/event/:eventId',
        name: 'FinanceEventResult',
        component: FinanceEventResultPage
      },



      {
        path: 'prize-money-templates',
        name: 'PrizeMoneyTemplates',
        component: PrizeMoneyTemplatesPage
      },

      {
        path: 'prize-money-template',
        name: 'AddNewPrizeMoneyTemplate',
        component: AddNewPrizeMoneyTemplatePage
      },

      {
        path: "events",
        name: "AdminEventResult",
        component: Events,
      },
      {
        path: "event-result/:eventId",
        name: "AdminResultEditPanelv2",
        component: EventResult2,
      },

      {
        path: "event-result/triathlon/:eventId",
        name: "TraithlonResults",
        component: TraithlonResults,
      },


      {
        path: "new-player",
        name: "AdminAddNewPlayer",
        component: AddNewPlayer,
      },
      {
        path: "add-triathlon-player",
        name: "AddTriathlonPlayer",
        component: AddTriathlonPlayer,
      },
      {
        path: "admin-users",
        name: "ListAllAdminUsers",
        component: ListAllUsers,
      },

      {
        path: "add-new-admin-user",
        name: "AddNewAdminUser",
        component: AddNewAdminUser,
      },

      {
        path: "roles-permissions",
        name: "RolesAndPermissions",
        component: RolesPermissions,
      },

      {
        path: "add-new-role",
        name: "AddNewRole",
        component: AddNewRole,
      },
      {
        path: 'push-notifications',
        name: 'PushNotificationsPage',
        component: PushNotificationsPage
      },

      {
        path: 'event-points/:eventId',
        name: 'pointsMainPage',
        component: pointsMainPage
      },

      {
        path: 'event/prize-money/:eventId',
        name: 'PrizeMoneyPage',
        component: PrizeMoneyPage

      },
      {
        path: "event-jerseys/:eventId",
        name: "jerseysListPage",
        component: jerseysListPage,
        props: true
      },
    ],
  },
];



const routes = [
  {
    path: basePath,
    redirect: { name: "AdminLogin" },
    meta: { requiresAuth: false },
    children: [
      {
        path: "login",
        name: "AdminLogin",
        component: Login,
      },
    ],
  },
  ...protectedRoutes,
];

export default routes;

export const columns = [
    { "name": "selectionStatus", "label": "Status", "type": "select" }, //"Status"
    { "name": "rank", "label": "RANK", "type": "non-editable" },
    { "name": "jerseyId", "label": "JERSEY", "type": "select" },
    { "name": "jerseyName", "label": "JERSEY ICON", "type": "non-editable" },

    { "name": "prizeMoney", "label": "Prize Money", "type": "non-editable" },


    { "name": "categoryId", "label": "Category", "type": "text" },
    { "name": "userType", "label": "Youth/Master", "type": "text" },
    { "name": "lapNo", "label": "LapNo", "type": "text" },
    { "name": "teamId", "label": "Team", "type": "select" },  //for Team

    // { "name": "teamName", "label": "TEAM NAME", "type": "text" },
    // { "name": "teamId", "label": "TEAM ID", "type": "text" },

    { "name": "userCountryFlag", "label": "FLAG", "type": "select" },
    { "name": "userFullName", "label": "RIDER", "type": "text" },
    { "name": "finishedTime", "label": "OVERALL TIME", "type": "text" },
    { "name": "points", "label": "Points", "type": "non-editable", "admin": true },
    { "name": "userWeight", "label": "Weight", "type": "text", "admin": true },
    { "name": "wattPerKG", "label": "AVG (W/KG)", "type": "text" },
    { "name": "avgPower", "label": "AVG (W)", "type": "text" },
    { "name": "avgHeartRate", "label": "AVG (HR)", "type": "text" },
    // watts columns
    { "name": "watts20min", "label": "20M WATTS", "type": "text" },
    { "name": "watts5min", "label": "5M WATTS", "type": "text" },
    { "name": "watts1min", "label": "1M WATTS", "type": "text" },
    { "name": "watts5sec", "label": "5S WATTS", "type": "text" },
    // watts/kg columns
    { "name": "wattsPerKG20min", "label": "20M W/kg", "type": "text" },
    { "name": "wattsPerKG5min", "label": "5M W/kg", "type": "text" },
    { "name": "wattsPerKG1min", "label": "1M W/kg", "type": "text" },
    { "name": "wattsPerKG5sec", "label": "5S W/kg", "type": "text" },
];

export const teamColumns = [
    // :todo need to be merged into columns var
    { "name": "rank", "label": "RIDER RANK", "type": "non-editable", "showBorder": true },
    { "name": "userFullName", "label": "RIDER NAME", "type": "non-editable", "showBorder": true, "width": 250 },
    { "name": "points", "label": "RIDER POINTS", "type": "non-editable", "showBorder": true },
    { "name": "pointsPosition", "label": "RIDER POINTS POSITION", "type": "non-editable", "showBorder": true },
    { "name": "finishedTime", "label": "OVERALL TIME", "type": "time", "showBorder": true },
    { "name": "wattPerKG", "label": "AVG W/KG", "type": "non-editable", "showBorder": true },
    { "name": "avgPower", "label": "AVG Power", "type": "non-editable", "showBorder": true },
    // watts columns
    { "name": "watts20min", "label": "20M Power", "type": "non-editable", "showBorder": true },
    { "name": "watts5min", "label": "5M Power", "type": "non-editable", "showBorder": true },
    { "name": "watts1min", "label": "1M Power", "type": "non-editable", "showBorder": true },
    { "name": "watts5sec", "label": "5S Power", "type": "non-editable", "showBorder": true },
    // watts/kg columns
    { "name": "wattsPerKG20min", "label": "20M W/KG", "type": "non-editable", "showBorder": true },
    { "name": "wattsPerKG5min", "label": "5M W/KG", "type": "non-editable", "showBorder": true },
    { "name": "wattsPerKG1min", "label": "1M W/kg", "type": "non-editable", "showBorder": true },
    { "name": "wattsPerKG5sec", "label": "5S W/kg", "type": "non-editable", "showBorder": true },
    { "name": "avgHeartRate", "label": "AVG (HR)", "type": "non-editable", "showBorder": true }, //"HR"
    { "name": "selectionStatus", "label": "Status", "type": "non-editable", "showBorder": true }, //"Status"
    { "name": "actions", "label": "RACE DATA FILE", "type": "actions", "showBorder": true }, //"DQ"
];

export const selectionStatuses = [
    { "name": "HYPHEN", "label": "-" },
    { "name": "DNS", "label": "DNS - Did not start" },
    { "name": "DNF", "label": "DNF - Did not finish" },
    { "name": "ANL", "label": "ANL - Annulled or annulment" },
    { "name": "DSQ", "label": "DSQ - Disqualified" },
    { "name": "SFT", "label": "SFT - Same finish time" },
    { "name": "TIE", "label": "TIE - Tie" },
];

export const eventTypes = [
    { "name": "Day Start", "value": "dayStart" },
    { "name": "Day End", "value": "dayEnd" },
    // {"name": "Gate", "value": "gate"},
    { "name": "Segment End", "value": "segEnd" },
];

export const timeFormateTypes = [
    { "name": "Elapsed Time", "value": "elapsed_time" },
    { "name": "Date Time", "value": "utc_time" },
];


export const columnsShowTeamsFrontend = [
    "rank",
    "jerseyId",
    "userFullName",
    //"userCountryFlag",
    "finishedTime",
    // "dissQualify",
    "teamName",
    // "selectionStatus",
    //"points",
    //"wattsPerKG5min",
    //"watts20min",
    //"watts1min",
    "wattPerKG",
    "avgPower",
    'avgHeartRate'
];



export const eventJerseysColumns = [
    { "name": "UserId", "label": "User", "type": "non-editable" },
    { "name": "JerseyId", "label": "Jersey ID", "type": "select" },
    { "name": "jerseyName", "label": "Jersey Image", "type": "non-editable" },
    { "name": "JerseyType", "label": "Jersey Type", "type": "text" },
    { "name": "CategoryId", "label": "Category", "type": "select" },
]


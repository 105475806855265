<template>
     
    <page-heading class="mt-4">
        {{ $route.query.name || "-" }} 
        <br>
        <small class="text-capitalize">Status: {{ $route.query.status }}</small>

        <br>
        <small class="text-capitalize">DateTime: {{ $route.query.datetime }}</small>
        
    </page-heading >


      <!-- Result Page Navigation -->
    <AdminNavigation  v-if="!isFinanceAdmin" />


    <page-loader v-if="loading"></page-loader>

    <div class="container  mt-4">

        <div class="d-flex justify-content-between ">
            <h2>Event Winners List - ({{ playersList?.length }})</h2> 
            <nav class="navbar navbar-light bg-light mb-2" >
                
                  <select 
                  class="form-control"
                  placeholder="Search" 
                  required  
                  v-model="categoryId" 
                  :disabled="loading"
                  @change="handleCategoryChange()"
                  
                  >
                  <option v-for="(cat, index) in categories" :value="cat.id"  :key="index">{{ cat.name }}</option>
                  </select>
               

                <button v-if="isSearchApiCall" class="mx-1 btn btn-success" @click="resetSearch" :disabled="loading"> reset <i class="fa fa-times"></i></button>
            </nav>

        </div>

         <table class="table table-bordered">
            <thead class="thead-dark">
              <tr>
                  <th scope="col">#</th>
                  <th scope="col">Player</th>
                  <th scope="col">Total Prize Money</th>
                <th scope="col">Transfer Status</th>
                <th scope="col">Last Transaction</th>
                <th scope="col">Action</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in playersList" :key="index">
                <td>{{ index + 1 }}</td>
                <td>
                  
                     <div class="d-flex align-items-center">
                        <span v-html="mapCountryFlag(item.userCountryFlag)"></span>

                        <div class="d-flex flex-column mx-2">
                            <p class="p-0 m-0 mx-1">
                                {{ item.userFullName }}
                            </p>
                            <small class="p-0 m-0 ">
                                {{ item.userId }}
                            </small>
                        </div>   
                     

                     </div>
                
                </td>
                <td> {{ getCurrency }} {{ item.totalAmount }}</td>

                <td scope="row">

                   <!-- <span :class="getIsProcessedAll(item) ? 'text-success' : 'text-danger'"> {{ getProcessedCount(item) }} / {{ getPlayerTotalLeaderbaord(item) }}
                </span>-->

                    <span :class="IsProcessedAll(item) ? 'text-success' : 'text-danger'" >{{ getProcessedAmountCount(item) }} AED out of {{ item.totalAmount }} AED is Transferred</span> 
            
                </td>

                <td>

                    {{ getLastUpdatedTransaction(item) }}
                    
                </td>

                <td v-if="isFinanceAdmin">
                    <button class="btn btn-dark" @click="btnClick(item)"> {{ IsProcessedAll(item) ? 'View Details' : 'Transfer Now' }}</button>
                </td>
                 
              </tr>


             

              <tr v-if="playersList?.length == 0" class="text-center p-4 d-flex">
                     No Data Found
              </tr>
              
              
            </tbody>
        </table>


        <PrizeMoneyTransferModal
        :showModal="showModal"
        :modalData="modalData"
        @listenerChild="listenerChild"
        
        />


        <div class="text-center">
            <button class="btn btn-dark" style="width: 270px;" @click="goBack">Go Back</button>
        </div>  
          
    </div>

</template>

<script>
import { convertISODateTime, mapCountryFlag } from '../../../../shared/events/event.helpers.ts';
import PrizeMoneyTransferModal from './TransferModal.vue'
import { mapActions, mapGetters } from 'vuex';
import AdminNavigation from "@/shared/components/AdminNavigation.vue";

export default {
    name: 'FinanceEventResult',

    components: {
        PrizeMoneyTransferModal,
        AdminNavigation
    },

    data() {
        return {
            mapCountryFlag: mapCountryFlag,
            showModal: false,
            loading: false,
            modalData: {},
            categoryId: 1,
            categories: [
                { id: 1, name: "Category 1" },
                { id: 2, name: "Category 2" },
                { id: 3, name: "Category 3" },
                { id: 4, name: "Category 4" },
                { id: 5, name: "Category 5" },
                { id: 6, name: "Category 6" }
            ],
            playersList: []
        }
    },


    computed: {
        ...mapGetters('auth', ['user']),


        isFinanceAdmin() {
            return this.user.role === 'whoosh-finance-admin' ? true : false
        },


        getAmount() {
            return (player) => {
                return player?.prizeData?.reduce((total, prize) => total + (prize.amount || 0), 0);
            };
        },
        getCurrency() { 
            return 'AED'
             
        },
        getProcessedAmountCount() {
            return (player) => {
                return player?.tansactionHistory?.reduce((total, prize) => total + (prize.transferAmount || 0), 0);
            }
        },

        getLastUpdatedTransaction() {
            return (player) => {
                if (!player?.tansactionHistory?.length) return "-";  

                const getDate= player.tansactionHistory.reduce((latest, transaction) =>
                    new Date(transaction.transferredDate) > new Date(latest.transferredDate) ? transaction : latest
                ).transferredDate;

                return convertISODateTime(getDate)
            };
        },



        getPlayerTotalLeaderbaord() {
            return (player) => {
                return player?.prizeData?.length;
            }
        },

        IsProcessedAll(){ 
            return (player) => {
                return player?.isProcessed;
            }

  
        }
    },
    async mounted() {
        await this.getPrizeData()
    },

    methods: {

        ...mapActions("event", ["getEventPrizeData"]),

        async getPrizeData() {
            this.loading = true
            const res = await this.getEventPrizeData({
                eventId: this.$route.params.eventId,
                categoryId: this.categoryId
            })
            this.playersList = res?.error ? [] : res?.data
            this.loading = false
        },

        async handleCategoryChange() {
            await this.getPrizeData()
        },


        btnClick(playerData) {
            this.showModal = true
            this.modalData = playerData
        },

        async listenerChild(receivedData) {
            if (!receivedData) return;
            if (!receivedData?.popup) {
                this.showModal = receivedData?.popup
            }
        },


        goBack() {
            this.$router.back()
        }



    }
}
</script>
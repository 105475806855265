<template>
  <VueFinalModal
    v-model="isShowModal"
    classes="modal-container"
    content-class="modal-content"
    :clickToClose="false"
  >
    <button class="modal__close btn btn-warning btn-sm" @click="handleClose()">
      <i class="fa fa-times" aria-hidden="true"></i>
    </button>
    <span class="modal__title">
      Remove Points
      <br />
      {{ event.name }}
    </span>
    <div class="modal__content">
      <hr />
      <Form
        @submit="submitHandler"
        :validation-schema="schema"
        v-slot="{ errors }"
        ref="removePointsForm"
      >
        <div class="row">
          <section class="col-lg-8 col-md-6">
            <div class="form-group">
              <label
                for="type"
                :class="{ 'is-invalid': errors.removeEntireEventPoints }"
              >- Do you want to remove points for all  days and  {{ isTraithlonEvent ? 'Stages' : 'Gates' }}?</label>
            </div>
          </section>

          <section class="col-lg-4 col-md-4">
            <div class="form-group form-check">
              <Field
                name="removeEntireEventPoints"
                type="radio"
                value="yes"
                class="form-check-input"
                id="removeEntireEventPoints"
                v-model="removeEntireEventPoints"
              />
              <label class="form-check-label" for="Yes">Yes</label>
              &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
              <Field
                name="removeEntireEventPoints"
                type="radio"
                value="no"
                class="form-check-input"
                id="removeEntireEventPoints"
                v-model="removeEntireEventPoints"
              />
              <label class="form-check-label" for="No">No</label>
              <div class="invalid-feedback">{{ errors.removeEntireEventPoints }}</div>
            </div>
          </section>
        </div>

        <div v-if="removeEntireEventPoints == 'no'">
          <div class="row">
            <section class="col-lg-12 col-md-6">
              <div class="form-group">
                <label for="selectedDay">- Select Day</label>
                <Field
                  id="selectedDay"
                  name="selectedDay"
                  class="form-control"
                  as="select"
                  :class="{ 'is-invalid': errors.selectedDay }"
                  @change="selectDayDropdownReply($event.target.value)"
                  v-model="selectedDayId"
                >
                  <option v-for="(item, i) in days" :value="item.DayId" :key="i">{{ item.DayName }}</option>
                </Field>
                <div class="invalid-feedback">{{ errors.selectedDay }}</div>
              </div>
            </section>
          </div>

          <div class="row" v-if="selectedDayId != null && isTraithlonEvent">
            <section class="col-lg-8 col-md-6">
              <div class="form-group">
                <label for="type">- Do you want to remove points for all {{ isTraithlonEvent ? 'stages' : 'gates' }} in this day?</label>
              </div>
            </section>

            <section class="col-lg-4 col-md-4">
              <div class="form-group form-check">
                <Field
                  name="removeEntireStagePoints"
                  type="radio"
                  value="yes"
                  class="form-check-input"
                  id="removeEntireStagePoints"
                  v-model="removeEntireStagePoints"
                />
                <label class="form-check-label" for="Yes">Yes</label>
                &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                <Field
                  name="removeEntireStagePoints"
                  type="radio"
                  value="no"
                  class="form-check-input"
                  id="removeEntireStagePoints"
                  v-model="removeEntireStagePoints"
                />
                <label class="form-check-label" for="No">No</label>
                <div class="invalid-feedback">{{ errors.removeEntireStagePoints }}</div>
              </div>
            </section>
          </div>


          <div class="row" v-if="removeEntireDayPoints === 'no' && isTraithlonEvent">
            <section class="col-lg-12 col-md-6">
              <div class="form-group">
                <label for="selectedStage">- Select Stage</label>
                <Field
                  id="selectedStage"
                  name="selectedStage"
                  class="form-control"
                  as="select"
                  :class="{ 'is-invalid': errors.selectedStage }"
                  @change="selectStageUpdate($event.target.value)"
                  v-model="selectedStageId"
                >
                  <option v-for="(item, i) in stages" :value="item.StageId" :key="i"> Stage - {{ i + 1 }} : {{ item.Name }}</option>
                </Field>
                <div class="invalid-feedback">{{ errors.selectedStage }}</div>
              </div>
            </section>
          </div>


          <div class="row" v-if="selectedDayId != null || isTraithlonEvent && selectedStageId">
            <section class="col-lg-8 col-md-6">
              <div class="form-group">
                <label for="type">- Do you want to remove points for all ListOfGates in this {{ isTraithlonEvent ? 'Stage' : 'Day' }}?</label>
              </div>
            </section>

            <section class="col-lg-4 col-md-4">
              <div class="form-group form-check">
                <Field
                  name="removeEntireDayPoints "
                  type="radio"
                  value="yes"
                  class="form-check-input"
                  id="removeEntireDayPoints "
                  v-model="removeEntireDayPoints"
                />
                <label class="form-check-label" for="Yes">Yes</label>
                &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                <Field
                  name="removeEntireDayPoints "
                  type="radio"
                  value="no"
                  class="form-check-input"
                  id="removeEntireDayPoints "
                  v-model="removeEntireDayPoints"
                />
                <label class="form-check-label" for="No">No</label>
                <div class="invalid-feedback">{{ errors.removeEntireDayPoints }}</div>
              </div>
            </section>
          </div>


          <hr />

          <!-- List of Gates Points Setting -->
          <div class="row" v-if="removeEntireDayPoints == 'no' || !selectedDayId && removeEntireStagePoints == 'no'">
            <section class="col-lg-12 col-md-6">
              <div class="form-group">
                <div class="form-group">
                  <label for="selectedStageSegmentOptions">- Select Leaderboard</label>
                  <VueMultiselect
                  v-model="selectedGates"
                  :options="selectedStageSegmentOptions"
                  :multiple="true"
                  :close-on-select="true"
                  placeholder="Pick some"
                  label="label"
                  name="label"
                  track-by="label" />
              </div>
                <div class="invalid-feedback">{{ errors.selectedGate }}</div>
              </div>
            </section>
          </div>
          <!-- End of gate points setting -->
        </div>

        <div
          class="row"
          v-if="removeEntireEventPoints == 'yes' || removeEntireDayPoints == 'yes' || selectedGates.length > 0"
        >
          <section class="col-lg-9 col-md-12">
            <div class="form-group">
              <label for="type">
                - Confirm "Delete" to perform this action?
                <sub>(write the word in the box below)</sub>
              </label>
              <br />
              <Field
                name="deleteWordField"
                type="text"
                class="form-control"
                :class="{ 'is-invalid': regexValidMsg }"
                id="deleteWordField"
                v-model="deleteWordField"
              ></Field>
              <p class="invalid-feedback">{{ errors }}</p>
               
            </div>
          </section>

          <section class="col-lg-12 col-md-12">
            <div class="form-group" style="background-color: #fcb913;">
              <p style="padding: 8px">
                <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
                Make sure you have select the correct options to delete points. Upon submission, this action will not be reverted again.
              </p>
            </div>
          </section>
        </div>

        <hr />

        <div class="row" v-if="removeEntireEventPoints != ''">
          <section class="col-lg-3 col-md-6">
            <label class="hide-mobile">&nbsp;</label>
          </section>

          <section class="col-lg-3 col-md-6">
            <label class="hide-mobile">&nbsp;</label>
          </section>

          <section class="col-lg-3 col-md-6">
            <label class="hide-mobile">&nbsp;</label>
          </section>

          <section class="col-lg-3 col-md-6">
            <label class="hide-mobile">&nbsp;</label>
            <button type="submit" class="btn btn-lg float-center" style="width: 140px; font-size: 15px"> Submit  &nbsp; &nbsp;  <i class="fa fa-trash" aria-hidden="true"></i></button>
          </section>
        </div>
      </Form>
    </div>
  </VueFinalModal>
</template>


<style scoped>
:deep(.modal-container) {
  display: grid;
  justify-content: center;
  align-items: center;
  width: 100%;
}

:deep(.modal-content) {
  position: relative;
  display: flex;
  flex-direction: column;
  margin: 0 1rem;
  padding: 1rem;
  border: 1px solid #e2e8f0;
  border-radius: 0.25rem;
  background: #fff;
}

.modal__title {
  margin: 0 2rem 0 0;

  font-weight: 700;
  text-align: center;
  text-decoration: underline;
  color: #fcb913;
  font-size: 30px;
  text-transform: uppercase;
  font-family: "Barlow Condensed", sans-serif !important;
}

.modal__content {
  overflow-y: auto;
  padding: 21px;
  width: 800px;
  height: 500px;
}

.modal__close {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  width: 50px;
}
</style>

<style scoped>
.dark-mode div:deep(.modal-content) {
  border-color: #2d3748;
  background-color: #1a202c;
}
</style>

<script>
import { Field, Form } from "vee-validate";
import { VueFinalModal } from "vue-final-modal";
import VueMultiselect from 'vue-multiselect';
import { mapActions } from "vuex";
import * as Yup from "yup";
import { EventFormatType } from '@/shared/events/enums.ts';

export default {
  name: "RemovePointsModal",
  props: ["event", "removePointsModal", "days", "isUCIEvent", "isTraithlonEvent", "isPointBasedEvent"],
  components: {
    Form,
    Field,
    VueFinalModal,
    VueMultiselect
  },

  watch: {

    currentStageGates: function (values) {
      this.selectedDayStages = values
    },
    stages: function () {
    },
    removePointsModal: function (value) {
      this.isShowModal = value;
    }
  },
  setup() {
    const schema = Yup.object().shape({
      removeEntireEventPoints: Yup.string().required("please choose option"),
      deleteWordField: Yup.string().required("please enter word")
    });
    return {
      schema
    };
  },
  data() {
    return {

      selectedDayId: null,
      selectedStageId: null,
      selectedStageSegments: [],
      selectedStageSegmentOptions: [],
      selectedGates: [],


      removeEntireEventPoints: null,
      removeEntireDayPoints: null,
      removeEntireStagePoints: null,



      regexValidMsg: "",
      totalRanks: 0,
      deleteWordField: "",


      isShowModal: this.removePointsModal,

    };
  },
  mounted() {

  },
  methods: {
    ...mapActions("event", ["removeEventPoints"]),
    async submitHandler(formData) {
      this.loading = true

      formData = {
        ...formData,
        eventId: this.event.serverEventId,
        selectedDay: this.selectedDayId || this.days?.[0]?.DayId || null,
        removeEntireDayPoints: this.removeEntireDayPoints || 'no',
        selectedGates: this.selectedGates?.length ? this.selectedGates.map(x => { return { gateId: x.value, lapNo: x.lapNo, type: x.type } }) : [],

        isUCIEvent: this.isUCIEvent || false
      };
      const getResponse = await this.removeEventPoints(formData);
      this.isShowModal = false;
      this.$emit("listenerChild", { type: 'remove-points', popup: false })
      this.$refs.removePointsForm.resetForm();

      getResponse.error ? true : setTimeout(() => {
        window.location.reload();
      }, 1000);
    },
    handleClose() {
      this.isShowModal = false;
      this.selectedDayStages = []
      this.$emit("listenerChild", { type: 'remove-points', popup: false });
      this.$refs.removePointsForm.resetForm();
    },


    generateSegments() {
      this.selectedStageSegmentOptions = []

      if (this.event.EventFormatType == EventFormatType.POINTS_BASED && !this.isUCIEvent) {
        this.selectedStageSegmentOptions.push({
          id: "individual",
          order: 1,
          label: 'Individual',
          type: 'individual',
          icon: "",
          lapNo: 1
        })
      }

      if (this.isUCIEvent) {
        const selectedDay = this.days?.find(item => item.DayId == this.selectedDayId)
        for (let i = 1; i <= selectedDay?.NoOfLaps; i++) {
          this.selectedStageSegmentOptions.push({
            label: selectedDay?.NoOfLaps > 1 ? ("Lap - " + i) : "Individual",
            value: 'daylap',
            type: 'daylap',
            lapNo: i
          })
        }
      }
      this.selectedStageSegments = this.stages.find(item => this.isTraithlonEvent ? (item.StageId === this.selectedStageId) : (item.DayId === this.selectedDayId))?.ListOfGates || []
      this.selectedStageSegments?.map((gate) => {
        const gates = { ...gate, NoOfIterations: gate.NoOfIterations || 1 } //if any gate doesnt have totalRepitition it will set it as 1
        for (let i = 1; i <= gates.NoOfIterations; i++) {
          this.selectedStageSegmentOptions.push({
            label: gate.GateName + (i != 1 ? " - Lap " + i : ""),
            value: gate.GateId,
            type: 'gate',
            lapNo: i
          })
        }
      })
    },

    selectDayDropdownReply(selectedDayId) {
      this.stages = this.event.ListOfDays.find(item => item.DayId === selectedDayId)?.Stages || []
      this.generateSegments()
      this.$emit("selectedDayDropdownListener", selectedDayId)
    },

    selectStageUpdate() {
      this.generateSegments()
    },

  }
};
</script>


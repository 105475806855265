<style scoped>
@import url("/node_modules/vue-client-recaptcha/dist/style.css");
</style>
<template>
  <div class="container">
    <div class="row">
      <section class="col-md-5 mx-auto">
        <Form class="db-form" @submit="loginHandler" :validation-schema="validationSchema" v-slot="{ errors }">
          <h2 class="login-head">Login</h2>
          <div class="form-group">
            <label>Email address</label>
            <Field name="email" type="text" class="form-control" :class="{ 'is-invalid': errors.email }"/>
            <div class="invalid-feedback">{{ errors.email }}</div>
          </div>
          <div class="form-group">
            <label>Password</label>
            <Field name="password" type="password" class="form-control" :class="{ 'is-invalid': errors.password }" autocomplete="password"/>
            <div class="invalid-feedback">{{ errors.password }}</div>
          </div>
      
          <div class="form-group">
          <label class="captcha" for="captcha">Verify Captcha</label>
          <div class="sample-captcha col-lg-12">
              <VueClientRecaptcha
                  :value="captchaValue"
                  @isValid="checkValidCaptcha"
                
                />
          </div>
        </div>

          <div class="form-group">
            <label class="captcha" for="captcha">Enter The Code</label>
               <input name="captchInput" type="text" v-model="captchaValue" style="width: 100%; height: calc(1.5em + 0.75rem + 2px); border: 1px solid" />
          </div>
   

        <div class="form-group form-check">
            <Field name="rememberMe" type="checkbox" class="form-check-input" id="rememberMe"
                   :class="{ 'is-invalid': errors.rememberMe }"/>
            <label class="form-check-label" for="rememberMe">Remember Me</label>
            <div class="invalid-feedback">{{ errors.rememberMe }}</div>
          </div>

          <button v-if="captchaValue" type="submit" class="btn">Submit</button>
          <div v-if="0 === true" class="alert alert-danger msg-alert" role="alert">
            Please fill the empty field
          </div>
        </Form>
      </section>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { Form, Field } from 'vee-validate';
import * as Yup from 'yup';
import axios from "axios";
import config from "@/config/app.config.ts";
import VueClientRecaptcha from 'vue-client-recaptcha'



export default {
  name: 'LoginComponent',
  components: {
    Form,
    Field,
    VueClientRecaptcha
  },
  computed: {
    ...mapGetters('auth', ['loggedIn', 'user']),
  },
  async mounted() {
    if (localStorage.getItem("resultAdminToken") && localStorage.getItem("resultAdminUser")) {
      axios.post(config.API_URL + "/auth/admin/check-auth",
        { token: localStorage.getItem("resultAdminToken") })
        .then((r) => {
          if (r.code !== 401) {
            if (r?.data?.data?.role === 'whoosh-finance-admin') {
              this.$router.push({ name: "FinanceEventsList" })
            } else {
              this.$router.push({ name: "AdminEventResult" })
            }

          } else {
            this.$router.push({ name: "AdminLogin" })
          }
        }).catch(() => {
          this.$router.push({ name: "AdminLogin" })
        })
    }
  },

  data() {
    return {
      captchaValue: null,
      captchaValid: false
    }
  },

  setup() {
    const validationSchema = Yup.object().shape({
      email: Yup.string()
        .required('Email is required')
        .email('Email is invalid'),
      password: Yup.string()
        .min(6, 'Password must be at least 6 characters')
        .required('Password is required'),
    });
    return {
      validationSchema,
    };
  },
  methods: {
    checkValidCaptcha(value) {
      /* expected return boolean if your value and captcha code are same return True otherwise return False */
      this.captchaValid = value
    },

    loginHandler(formData) {

      if (this.captchaValid) {
        this.$store.dispatch('auth/login', formData).then(() => { 
          if (this.loggedIn && this.user.role == 'whoosh-finance-admin') {
            this.$router.push({ name: 'FinanceEventsList' });
          } else {
            this.$router.push({ name: 'AdminEventResult' });

          }

          // if (this.loggedIn) {
          //   this.$router.push({ name: 'AdminEventResult' });
          // }
        });
      } else {
        global.app.$toast.error("Invalid Captcha Entered")
      }

    },
  }
};
</script>

<template>
  <VueFinalModal
    v-model="isShowModal"
    classes="modal-container"
    content-class="modal-content"
    :clickToClose="false"
    class="popup"
  >
    <button class="modal__close btn btn-warning btn-sm" @click="handleClose()">
      <i class="fa fa-times" aria-hidden="true"></i>
    </button>
    <span class="modal__title">
      Add Points
      <br />
      {{ event.name }}
    </span>
    <div class="modal__content">
      <hr />
      <Form
      @submit="submitHandler"
      :validation-schema="isUCIEvent ? UciFormschema : schema"
      v-slot="{ errors }"
        ref="addPointsForm"
      >
        <div class="row" v-if="!isUCIEvent">
          <section class="col-lg-8 col-md-6">
            <div class="form-group">
              <label
                for="type"
                :class="{ 'is-invalid': errors.samePointsAcross }"
              >- Do you want to add same points for all Days and {{ isTraithlonEvent ? 'Stages' : 'Gates' }}?</label>
            </div>
          </section>

          <section class="col-lg-4 col-md-4">
            <div class="form-group form-check">
              <Field
                name="samePointsAcross"
                type="radio"
                value="yes"
                class="form-check-input"
                id="samePointsAcross"
                v-model="samePointsAcross"
              />
              <label class="form-check-label" for="Yes">Yes</label>
              &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
              <Field
                name="samePointsAcross"
                type="radio"
                value="no"
                class="form-check-input"
                id="samePointsAcross"
                v-model="samePointsAcross"
              />
              <label class="form-check-label" for="No">No</label>
              <div class="invalid-feedback">{{ errors.samePointsAcross }}</div>
            </div>
          </section>
        </div>

        <div v-if="samePointsAcross == 'no' || isUCIEvent">
          <div class="row">
            <section class="col-lg-12 col-md-6">
              <div class="form-group">
                <label for="selectedDay">- Select Day</label>
                <Field
                  id="selectedDay"
                  name="selectedDay"
                  class="form-control"
                  as="select"
                  :class="{ 'is-invalid': errors.selectedDay }"
                  @change="selectDayDropdownReply($event.target.value)"
                  v-model="selectedDayId"
                >
                  <option v-for="(item, i) in allDays" :value="item.DayId" :key="i">Day - {{ i + 1 }} :  {{ item.DayName }}</option>
                </Field>
                <div class="invalid-feedback">{{ errors.selectedDay }}</div>
              </div>
            </section>
          </div>



          <div class="row" v-if="selectedDayId != null && isTraithlonEvent">
            <section class="col-lg-8 col-md-6">
              <div class="form-group">
                <label for="type">- Do you want to add same points for all {{ isTraithlonEvent ? 'stages' : 'gates' }} in this day?</label>
              </div>
            </section>

            <section class="col-lg-4 col-md-4">
              <div class="form-group form-check">
                <Field
                  name="stagesSamePoints"
                  type="radio"
                  value="yes"
                  class="form-check-input"
                  id="stagesSamePoints"
                  v-model="stagesSamePoints"
                />
                <label class="form-check-label" for="Yes">Yes</label>
                &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                <Field
                  name="stagesSamePoints"
                  type="radio"
                  value="no"
                  class="form-check-input"
                  id="stagesSamePoints"
                  v-model="stagesSamePoints"
                />
                <label class="form-check-label" for="No">No</label>
                <div class="invalid-feedback">{{ errors.stagesSamePoints }}</div>
              </div>
            </section>
          </div>

          <div class="row" v-if="stagesSamePoints === 'no' && isTraithlonEvent">
            <section class="col-lg-12 col-md-6">
              <div class="form-group">
                <label for="selectedStage">- Select Stage</label>
                <Field
                  id="selectedStage"
                  name="selectedStage"
                  class="form-control"
                  as="select"
                  :class="{ 'is-invalid': errors.selectedStage }"
                  @change="selectStageUpdate($event.target.value)"
                  v-model="selectedStageId"
                >
                  <option v-for="(item, i) in stages" :value="item.StageId" :key="i"> Stage - {{ i + 1 }} : {{ item.Name }}</option>
                </Field>
                <div class="invalid-feedback">{{ errors.selectedStage }}</div>
              </div>
            </section>
          </div>


          <div class="row" v-if="selectedDayId != null || isTraithlonEvent && selectedStageId">
            <section class="col-lg-8 col-md-6">
              <div class="form-group">
                <label for="type">- Do you want to add same points for all ListOfGates in this {{ isTraithlonEvent ? 'Stage' : 'Day' }}?</label>
              </div>
            </section>

            <section class="col-lg-4 col-md-4">
              <div class="form-group form-check">
                <Field
                  name="daySamePoints"
                  type="radio"
                  value="yes"
                  class="form-check-input"
                  id="daySamePoints"
                  v-model="daySamePoints"
                />
                <label class="form-check-label" for="Yes">Yes</label>
                &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                <Field
                  name="daySamePoints"
                  type="radio"
                  value="no"
                  class="form-check-input"
                  id="daySamePoints"
                  v-model="daySamePoints"
                />
                <label class="form-check-label" for="No">No</label>
                <div class="invalid-feedback">{{ errors.daySamePoints }}</div>
              </div>
            </section>
          </div>
          <hr />

          

          <div class="row" v-if="daySamePoints == 'no' && selectedDayId != null" >
            <section class="col-lg-12 col-md-6">
              <div class="form-group">
                <label for="selectedStageSegmentOptions">- Select Leaderboard</label>
                <VueMultiselect
                v-model="selectedGates"
                :options="selectedStageSegmentOptions"
                :multiple="true"
                :close-on-select="true"
                placeholder="Pick some"
                label="label"
                name="label"
                track-by="label" />
            </div>
            </section>
          </div>
          <!-- End of gate points setting -->
          </div>


        <div
          class="row"
          v-if="samePointsAcross === 'yes' || daySamePoints == 'yes' || stagesSamePoints == 'yes' || selectedGates.length > 0"
        >
          <section class="col-lg-9 col-md-12">
            <div class="form-group">
              <label for="type">
                - Set Points Separated by Commas?
                <sub>(e.g: 2,40,23,49)</sub>
              </label>
              <br />
              <Field
                name="pointsArr"
                type="text"
                class="form-control"
                :class="{ 'is-invalid': regexValidMsg }"
                id="pointsArr"
                @keyup="checkCharsLimit($event.target.value)"
                v-model="pointsArr"
              >
              </Field>
              <!-- <div class="invalid-feedback">{{ errors.pointsArr }}</div> -->
              <p v-if="regexValidMsg?.length == 0">
                Points set for
                <strong>Top {{ totalRanks }} Rank</strong>
              </p>
              <p v-else :style="{ color: '#dc3545' }">{{ regexValidMsg }}</p>
            </div>
          </section>

          <section class="col-lg-12 col-md-12">
            <div class="form-group">
              <ul>
                <li>Min value of each point is 0. No negative values are accepting.</li>
                <li>Points will be set for ranks according to your order. for example [100,90,80,40,33,10] the 100 represents 1st Rank points and so on.</li>
              </ul>
            </div>
          </section>
          <section class="col-lg-12 col-md-12">
            <div class="form-group" style="background-color: #fcb913;">
              <p style="padding: 8px">
                <i class="fa fa-exclamation-triangle" aria-hidden="true"></i>
                Make sure you have set the correct ordering and options. Upon submission, it will set points according to the options you selected.
              </p>
            </div>
          </section>
        </div>

        <hr />

        <!-- <div class="row" v-if="samePointsAcross != ''"> -->
           <div class="row" v-if="samePointsAcross != ''">
          <section class="col-lg-3 col-md-6">
            <label class="hide-mobile">&nbsp;</label>
          </section>

          <section class="col-lg-3 col-md-6">
            <label class="hide-mobile">&nbsp;</label>
          </section>

          <section class="col-lg-3 col-md-6">
            <label class="hide-mobile">&nbsp;</label>
          </section>

          <section class="col-lg-3 col-md-6">
            <label class="hide-mobile">&nbsp;</label>
            <button type="submit" class="btn btn-lg float-center" style="width: 140px; font-size: 15px">Submit</button>
          </section>
        </div>
      </Form>
    </div>
  </VueFinalModal>
</template>


<style src="vue-multiselect/dist/vue-multiselect.css"></style>

<style scoped>
:deep(.modal-container) {
  display: grid;
  justify-content: center;
  align-items: center;
  width: 100%;
}

:deep(.modal-content) {
  position: relative;
  display: flex;
  flex-direction: column;

  margin: 0 1rem;
  padding: 1rem;
  border: 1px solid #e2e8f0;
  border-radius: 0.25rem;
  background: #fff;
}

.modal__title {
  margin: 0 2rem 0 0;

  font-weight: 700;
  text-align: center;
  text-decoration: underline;

  color: #fcb913;
  font-size: 30px;

  text-transform: uppercase;
  font-family: "Barlow Condensed", sans-serif !important;
}

.modal__content {

  overflow-y: auto;
  padding: 21px;
  width: 800px;
  height: 500px;
}

.modal__close {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  width: 50px;
}
</style>

<style scoped>
.dark-mode div:deep(.modal-content) {
  border-color: #2d3748;
  background-color: #1a202c;
}
</style>

 

<script>
import { Field, Form } from "vee-validate";
import { VueFinalModal } from "vue-final-modal";
import VueMultiselect from 'vue-multiselect';
import { mapActions } from "vuex";
import * as Yup from "yup";
import { EventFormatType } from '@/shared/events/enums.ts';


export default {

  name: "AddPointsModal",
  props: ["event", "addPointsModal", "days", "isUCIEvent", "isTraithlonEvent", "isPointBasedEvent"],
  components: {
    Form,
    Field,
    VueFinalModal,
    VueMultiselect
  },

  computed: {},

  watch: {
    days: function (values) {
      this.allDays = values;
    },
    addPointsModal: function (value) {
      this.isShowModal = value;
    }
  },
  setup() {
    const schema = Yup.object().shape({
      samePointsAcross: Yup.string().required("please choose option"),
      pointsArr: Yup.string().required("please enter points"),
    });

    const UciFormschema = Yup.object().shape({
      pointsArr: Yup.string().required("please enter points")
    });
    return {
      schema, UciFormschema
    };
  },
  data() {
    return {

      allDays: this.days,
      selectedDayId: null,
      selectedStageId: null,
      selectedStageSegments: [],
      selectedStageSegmentOptions: [],
      selectedGates: [],

      regexValidMsg: "",
      totalRanks: 0,
      pointsArr: "",
      samePointsAcross: null,
      daySamePoints: null,
      isShowModal: this.addPointsModal,
      stages: [],


      loading: false,

    };
  },
  mounted() {
  },

  methods: {
    ...mapActions("event", ["setEventPoints"]),
    async submitHandler(formData) {
      this.$refs.addPointsForm.resetForm();
      formData = {
        ...formData,
        eventId: this.event?.serverEventId,
        selectedDay: this.selectedDayId || this.days?.[0]?.DayId || null,
        samePointsAcross: this.samePointsAcross || 'no',
        daySamePoints: this.daySamePoints || 'no',
        stagesSamePoints: this.isEventTraithlon ? (this.stagesSamePoints || 'no') : null,
        selectedGates: this.selectedGates?.length ? this.selectedGates.map(x => { return { gateId: x.value, lapNo: x.lapNo, type: x.type } }) : [],
        eventType: this.event?.EventFormatType,
        isUCIEvent: this.isUCIEvent || false,
        isTraithlonEvent: this.isTraithlonEvent
      };

      const getResponse = await this.setEventPoints(formData);

      this.isShowModal = false;
      this.$refs.addPointsForm.resetForm();
      this.selectedGates = []
      this.$emit("listenerChild", { type: 'add-points', popup: false });
      getResponse.error ? true : setTimeout(() => {
        window.location.reload();
      }, 1000);
    },

    handleClose() {
      this.isShowModal = false;
      this.$emit("listenerChild", { type: 'add-points', popup: false });
      this.selectedGates = []
      this.$refs.addPointsForm.resetForm();

    },

    generateSegments() {
      this.selectedStageSegmentOptions = []

      if (this.event.EventFormatType == EventFormatType.POINTS_BASED && !this.isUCIEvent) {
        this.selectedStageSegmentOptions.push({
          id: "individual",
          order: 1,
          label: 'Individual',
          type: 'individual',
          icon: "",
          lapNo: 1
        })
      }



      if (this.isUCIEvent) {
        const selectedDay = this.allDays?.find(item => item.DayId == this.selectedDayId)
        for (let i = 1; i <= selectedDay?.NoOfLaps; i++) {
          this.selectedStageSegmentOptions.push({
            label: selectedDay?.NoOfLaps > 1 ? ("Lap - " + i) : "Individual",
            value: 'individual',
            type: 'individual',
            lapNo: i
          })
        }
      }

      this.selectedStageSegments = this.stages.find(item => this.isTraithlonEvent ? (item.StageId === this.selectedStageId) : (item.DayId === this.selectedDayId))?.ListOfGates || []
      this.selectedStageSegments?.map((gate) => {
        const gates = { ...gate, NoOfIterations: gate.NoOfIterations || 1 } //if any gate doesnt have totalRepitition it will set it as 1
        for (let i = 1; i <= gates.NoOfIterations; i++) {
          this.selectedStageSegmentOptions.push({
            label: gate.GateName + (i != 1 ? " - Lap " + i : ""),
            value: gate.GateId,
            type: 'gate',
            lapNo: i
          })
        }
      })
    },

    selectDayDropdownReply(selectedDayId) {
      this.stages = this.event.ListOfDays.find(item => item.DayId === selectedDayId)?.Stages || []
      this.generateSegments()
    },

    selectStageUpdate() {
      this.generateSegments()
    },

    checkCharsLimit(pointsValue) {
      if (pointsValue.includes(",")) {
        if (!/^([0-9]*)+(,[0-9]+)+$/.test(pointsValue)) {
          this.regexValidMsg = "Invalid Format";
        } else {
          this.totalRanks = pointsValue.split(",").length;
          this.regexValidMsg = "";
        }
        if (!pointsValue.split(",").every(n => n.length < 6)) {
          this.regexValidMsg = "Invalid Format";
        }
      } else {
        if (!/^[0-9]*$/.test(pointsValue) || pointsValue.length > 5) {
          //the second OR condition restrict and limit the maximum points to 3 digits max 999
          this.regexValidMsg = "Invalid Format";
        } else {
          this.totalRanks = pointsValue.length ? 1 : 0;
          this.regexValidMsg = "";
        }
      }
    }
  }
};
</script>


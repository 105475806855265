
<style scoped>
:deep(.modal-container) {
    display: grid;
    justify-content: center;
    align-items: center;
    width: 100%;
}

:deep(.modal-content) {
    position: relative;
    display: flex;
    flex-direction: column;

    margin: 0 1rem;
    padding: 1rem;
    border: 1px solid #e2e8f0;
    border-radius: 0.25rem;
    background: #fff;
}

.modal__title {
    margin: 0 2rem 0 0;

    font-weight: 700;
    text-align: center;
    text-decoration: underline;

    color: #fcb913;
    font-size: 30px;

    text-transform: uppercase;
    font-family: "Barlow Condensed", sans-serif !important;
}

.modal__content {

    overflow-y: auto;
    padding: 21px;
    width: 800px;
    height: 500px;
}

.modal__close {
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
    width: 50px;
}
</style>

<style scoped>
.dark-mode div:deep(.modal-content) {
    border-color: #2d3748;
    background-color: #1a202c;
}

#centerlineTextDiv {
    height: 1px;
    border-top: 1px solid rgba(0, 0, 0, .1);
    text-align: center;
    position: relative;
}

#centerlineTextDiv>span {
    position: relative;
    top: -.8em;
    background: #fcb913;
    display: inline-block;
}
</style>

<template>
    <VueFinalModal
    v-model="openModal"
    classes="modal-container"
    content-class="modal-content"
    :clickToClose="false"
    class="popup"
  >

  <button class="modal__close btn btn-warning btn-sm" @click="handleClose()">
    <i class="fa fa-times" aria-hidden="true"></i>
  </button>

  <span class="modal__title">
    REMOVE PRIZE MONEY FOR
    <br />
    {{ eventName || "Event Name" }}
  </span>

  <Form 
  @submit="submitHandler"
  ref="removePrizesForm"
  >


  <div class="modal__content">
    <hr />
     
  
        <div class="row" >
            <section class="col-lg-8 col-md-6">
              <div class="form-group">
                <label for="type">- Do you want to remove All added Categories prize money</label>
              </div>
            </section>

            <section class="col-lg-4 col-md-4">
              <div class="form-group form-check">
                <Field
                  name="removeAllPrizes"
                  type="radio"
                  value="yes"
                  class="form-check-input"
                  id="removeAllPrizes"
                  v-model="removeAllPrizes"
                  :class="emptyFields['removeAllPrizes'] ? 'is-invalid' : ''" 

                
                />
                <label class="form-check-label" for="Yes">Yes</label>
                &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                <Field
                  name="removeAllPrizes"
                  type="radio"
                  value="no"
                  class="form-check-input"
                  id="removeAllPrizes"
                  v-model="removeAllPrizes"
                  :class="emptyFields['removeAllPrizes'] ? 'is-invalid' : ''" 
                />
                <label class="form-check-label" >No</label>
                <div class="invalid-feedback">{{ emptyFields.removeAllPrizes }}</div>
              </div>
            </section>
          </div>


          <div class="row" v-if="removeAllPrizes == 'no'">
            <section class="col-lg-12 col-md-6">
              <div class="form-group">
                <label for="selectedCategories">- Select Category</label>
                <VueMultiselect
                v-model="selectedCategoryId"
                :options="categories?.map(item => ({ label: item.Label, value: item.CategoryId }))"
                :multiple="false"
                :close-on-select="true"
                placeholder="Pick a cateogry"
                label="label"
                name="label"
                :class="emptyFields['selectedCategory'] ? 'is-invalid' : ''" 
                track-by="label" />
                <p class="invalid-feedback">{{ selectedCategoryId ? '' : emptyFields['selectedCategory'] }}</p>
            </div>
        </section>
          </div>


          <div class="row" v-if="removeAllPrizes == 'no'">
          <section class="col-lg-12 col-md-6">
            <div class="form-group">
              <label for="selectedStageSegmentOptions">- Select Leaderboard</label>
              <VueMultiselect
              v-model="selectedGates"
              :options="getOverallLeaderboards"
              :multiple="true"
              :close-on-select="true"
              placeholder="Pick some"
              label="label"
              name="label"
              :class="emptyFields['selectedGates'] ? 'is-invalid' : ''" 
              track-by="label" />
              <p class="invalid-feedback">{{ selectedGates?.length > 0 ? "" : emptyFields['selectedGates'] }}</p>
          </div>
          </section>
        </div>

        <div class="row">

            <section class="col-lg-12 col-md-6">
                <div class="form-group">
                    <label for="type">
                      - Confirm "Delete" to perform this action?
                      <sub>(write the word in the box below)</sub>
                    </label>
                    <br />
                    <Field
                      name="deleteWordField"
                      type="text"
                      class="form-control"
                      :class="emptyFields['deleteWordField'] ? 'is-invalid' : ''" 
                      id="deleteWordField"
                      @keyup="handleChange('deleteWordField')"
                      
                    ></Field>
                    <p class="invalid-feedback">{{ emptyFields?.deleteWordField }}</p>
                     
                  </div>
        </section>
        </div>
  </div>
  <div class="text-right">
     <button class="btn btn-lg bg-dark text-white" style="width: 200px;" type="submit">Submit</button>
  </div>
</Form>
</VueFinalModal>

    
</template>

<script>

import { VueFinalModal } from "vue-final-modal";
import { Form, Field } from "vee-validate";
import VueMultiselect from 'vue-multiselect';
import { mapActions } from "vuex";


export default {
    name: 'RemovePrizeMoneyModal',
    props: ["isShowModal", "eventName", "leaderboards", "categories", "eventId", "selectedCategory"],
    emits: ["listenerChild"],

    watch: {
        isShowModal: function (value) {
            this.openModal = value;
        },

    },
    computed: {
        getOverallLeaderboards() {
            return [{ id: 'individual', label: 'Individual' }, { id: 'teams', label: 'Teams' }, { id: 'segments', label: 'Per Segment' }, ...this.leaderboards?.map(({ GateId, GateName }) => ({ id: GateId, label: GateName }))]
        }
    },

    data() {
        return {
            openModal: this.isShowModal,
            selectedGates: [],
            selectedCategoryId: this.selectedCategory,
            removeAllPrizes: null,
            emptyFields: {}
        }
    },

    components: {
        VueFinalModal: VueFinalModal,
        Form,
        Field,
        VueMultiselect


    },


    mounted() { },

    methods: {

        ...mapActions("event", ["removeEventPrizeMoney"]),

        handleClose(extraParams = {}) {
            this.$refs.removePrizesForm.resetForm();
            this.selectedGates = []
            this.emptyFields = {}
            this.$emit("listenerChild", { type: 'remove-prize-money', popup: false, ...extraParams });
        },


        handleChange(field) {
            this.emptyFields[field] = ""

        },
        async submitHandler(formValues) {

            this.emptyFields = {};


            Object.keys(formValues).forEach((key) => {
                if (!formValues[key] || formValues[key].toString().trim() === "") {
                    this.emptyFields[key] = "Field is required";
                }
            });


            if (!this.selectedCategoryId && this.removeAllPrizes === "no") {
                this.emptyFields["selectedCategory"] = "Field is required";
            }


            if (!this.selectedGates || this.selectedGates.length === 0 && this.removeAllPrizes === "no") {
                this.emptyFields["selectedGates"] = "Field is required";
            }


            const finalPayload = {
                ...formValues,
                eventId: this.eventId,
                category: this.selectedCategoryId?.value,
                leaderboards: this.selectedGates?.map(item => item.id)
            };



            if (!formValues.deleteWordField) {
                console.warn("Delete confirmation is required.");
                return;
            }


            const missingValues = Object.values(finalPayload).some(
                (item) => item === undefined || item === null || (Array.isArray(item) && item.length === 0)
            );

            if (formValues?.removeAllPrizes === "no" && missingValues) {
                console.warn("Some required fields are missing.");
                return;
            }

            const response = await this.removeEventPrizeMoney(finalPayload);
            if (response?.error || response?.code == 200) {
                this.handleClose({ apiCall: true, category: this.selectedCategoryId?.value })
            }
        }



    }

}
</script>
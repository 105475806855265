<template>
  
  <page-loader v-if="!event" style="margin-top: 50px" />
  <TriathlonEventResult v-if="isTraithlonEvent && event" :eventData="event" />

  <EventResult v-if="!isUCIEvent && !isTraithlonEvent && event" :eventData="event" :isUCIEvent="isUCIEvent" />  
  <UCIEventResult v-else-if="isUCIEvent && event" :eventData="event" :isUCIEvent="isUCIEvent"/>
   
</template>

<style scoped>
@import url("@/theme/style/carousel.scss");
</style>

<script>
import { mapActions, mapGetters } from "vuex";
import EventResult from "./EventResult.vue";
import UCIEventResult from "./UCIEventResult.vue";
import TriathlonEventResult from './TriathlonEventResult.vue'
import { isUCIEvent, isTraithlonEvent } from "@/shared/events/event.helpers.ts";

export default {
  name: "EventResultIndex",
  components: {
    EventResult,
    UCIEventResult,
    TriathlonEventResult
  },
  data() {
    return {
      event: null
    }
  },
  computed: {
    ...mapGetters("event", ["gateList"]),
    isLive() {
      return this.$route.query.isLive == 1 || this.$route.query.isLive == "true" ? true : false;
    },
    isUCIEvent() {
      return this.event && isUCIEvent(this.event)
    },
    isTraithlonEvent() {
      return this.event && isTraithlonEvent(this.event)
    }
  },

  async mounted() {
    window.scrollTo(0, 0)
    const { eventId } = this.$route.query;
    this.event = await this.getEvent(eventId)
    //get gates data for all days
    await this.getGateList({ eventId: eventId });
    //set data for live graph
    sessionStorage.setItem(
      "graphDays",
      JSON.stringify(this.gateList?.days?.find((x) => x.DayId === this.gateList.activeDayId) || []));
  },
  methods: {
    ...mapActions("event", ["getGateList", "getEvent"]),
  },
};
</script>

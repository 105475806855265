
 


<style scoped>
@media (min-width: 1200px) {

  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl {
    max-width: 1320px;
  }
}


.bh-text-sm {
  line-height: 1px !important;
}

.customBreadcrums h3 {
  font-family: "Inter" !important;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 36px;
  display: flex;
  align-items: center;
  color: #282828;
  margin-bottom: 0px;
  text-transform: uppercase;
}


.customDrodown select {
  font-family: "Inter" !important;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.08em;
  text-transform: uppercase;
  width: 204px;
  height: 54px;
  appearance: none;
  padding: 15px;
  outline: 0;
  background: #f9fbfc;
  width: 100%;

  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: url("data:image/svg+xml;utf8,<svg fill= 'black' height= '24' viewBox= '0 0 24 24' width= '24' xmlns= 'http://www.w3.org/2000/svg' ><path d= 'M7 10l5 5 5-5z' /><path d= 'M0 0h24v24H0z' fill= 'none' /></svg>");
  background-repeat: no-repeat;
  background-position-x: 98%;
  background-position-y: 14px;
}

.subListTabs ul li.active {
  background-color: #fcb913;
}

.list-indivi ul li img {
  vertical-align: middle;
}

.list-indivi ul li span {
  vertical-align: middle;
}

.individual {
  padding: 40px 0 0 0;
}

.individual .div-flex {
  justify-content: space-between;
}


.section-main {
  padding: 52px 20px 70px 20px;
}

.section-main.blue-bg {
  background-color: #000000;
  display: flex;
  align-items: center;
}

.section-main h1 {
  text-align: center;
  font-family: "Thunder" !important;
  font-style: normal;
  font-weight: 800;
  font-size: 60px;
  line-height: 60px;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: #ffffff;

}

.load-btn-wrpr {
  text-align: center;
  margin: 10px auto;
}

.loadmore {
  border: 1px solid #e1e1e1;
  font-family: "Inter" !important;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  display: inline-block;
  align-items: center;
  text-align: center;
  letter-spacing: 0.0125em;
  color: #000000;
  background-color: #fff;
  margin: 0 6px;
  padding: 12px 40px;
  cursor: pointer;
  min-width: 144px;

}

.loadmore:hover {
  background: #fcb913;
  color: #000000 !important;
}


.bg-leaderboard {
  background-color: white;
  border: 1px solid #e1e1e1;
  gap: 30px;
}

@media (max-width: 991px) {
  .btn-group {
    width: 100% !important;
  }

  .customDrodown {
    justify-content: left;
  }

  .ranking-select {
    text-align: center;
    margin: 20px;
  }

  .wrpr-btns-mob {
    background-color: #f6f6f6;
  }

  .wrpr-btns-mob button {
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 37px;
    align-items: center;
    text-align: center;
    letter-spacing: 0.046em;
    color: #000000;
    display: inline-block;
    border: none;
    background-color: #f6f6f6;
    padding: 5px;
  }

  .wrpr-btns-mob select {
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 37px;
    align-items: center;
    text-align: center;
    letter-spacing: 0.046em;
    color: #000000;
    width: 33.3%;
    display: inline-block;
    border: none;
    padding: 10px;
    color: #212529;
    background-color: #f6f6f6;
    border: 0px solid #ced4da;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }

  .wrpr-btns-mob button.active {
    background: #fcb913;
  }

  .buttons-group-custom {
    width: 33.3% !important;
  }

  .section-main h1 {
    font-family: "Barlow Condensed", sans-serif !important;
  }
}


@media (max-width: 575px) {
  .section-main.blue-bg {
    height: auto;
  }

  .team-name-mob {
    display: flex !important;
    justify-content: space-evenly;
    align-items: center;
  }

  .rank-tbl-mob>div {
    padding: 0 !important;
  }

  .rank-tbl .container {
    padding: 0;
  }

  .rank-tbl-mob .container {
    padding: 0;
  }

  .individual {
    display: none;
  }

  .section-main .div-flex .col-left {
    width: 59%;
  }

  .rank-tbl table tr td {
    padding: 0px 0px;
  }

  .section-main h1 {
    margin: 20px 0px;
    font-size: 35px;
    line-height: 36px;
  }

  .customBreadcrums {
    margin-bottom: 0px;
  }

  .individual {
    padding: 20px 0 0 0;
  }

  .sprint-bar {
    padding: 0 0;
  }

  .rank-tbl .table-outer {
    overflow-y: scroll !important;
  }

  .individual .div-flex {
    justify-content: space-between;
    flex-direction: column;
  }

  .list-indivi {
    width: 100%;
    margin: 7px;
  }

  .individual ul li {
    width: 100%;
  }

  .filterBottom ul.subListTabs {
    justify-content: center;
  }


}

@media (max-width: 400px) {
  .ranking-select {
    margin: 25px 0px;
  }

  .customDrodown select {
    font-size: 13px;
  }

  .ranking-select button {
    font-size: 13px;
  }

  .section-main h1 {
    margin: 14px 0px;
    font-size: 30px;
    line-height: 29px;
  }
}


.selectStagesDropdown {
  width: 170px;
  font-family: 'Poppins', sans-serif !important;
}

@media (max-width: 555px) {
  #mobileSportTypesMdDevices{
    display: none !important;  
  }
}

@media (max-width: 552px) {
  #mobileSportTypesSmDevices{
    display: block !important;
  }
}


</style>
 

<template>
  <div
    class=""
    data-aos-easing="ease-in"
    data-aos-duration="600"
    data-aos-delay="0"
  >
    

    <div class="homepage-wrap clearfix">

       <div class="section-main blue-bg pattern-bg">
        <div class="container">
          <h1>
            {{ eventData?.name }} -
            <span style="color: #00b2e3">{{
              mapUserGender(eventData?.gender)
            }}</span>
          </h1>

          <div v-if="isMobileDevice">

            <div class="d-flex flex-row gap-4 text-white justify-content-center flex-wrap">
              <div v-for="(item, index) in getLeaderboards" :key="index" class="d-flex mx-2" style="font-size: 16px;">
                <p>{{ item.name }}: </p>
                <span class="font-weight-bolder mx-1"> <strong>{{ item?.totalDistance }}</strong></span>
              </div>               
            </div>

            <div class="col-right">
              <div class="customDrodown">
                <select 
                v-model="selectedDayId" @change="updateDay($event.target.value)">
                <option value="">
                  Select Day
                </option> 
                <option
                    :value="day.DayId"
                    v-for="(day, k) in getDays"
                    :key="k"
                  >
                    <strong>Day No {{ day.DayNo }}</strong>
                  </option>
                </select>
              </div>
            </div>
          </div>

          <div v-else class="div-flex">
            <div class="col-right">
              <div class="customDrodown w-50 mx-auto">
                <select 
                v-model="selectedDayId" @change="updateDay($event.target.value)">
                <option value="">
                  Select Day
                </option> 
                <option
                    :value="day.DayId"
                    v-for="(day, k) in getDays"
                    :key="k"
                  >
                    <strong>Day No {{ day.DayNo }}</strong>
                  </option>
                </select>
              </div>
            </div>
          </div>
        </div>
      </div>



      <div class="container p-2" v-if="isMobileDevice">

            <div class="d-flex mt-2  ">

              <div class="col-6 p-0 m-0" >
                <select @change="updateStage($event.target.value)" 
                  style="width: inherit"
                  class="bg-leaderboard  p-2">
                 
                  <option
                  v-for="(stage, k) in getStages"
                  :value="stage.value"
                  :key="k"
                  >
                    <strong>{{ stage.label }}</strong>
                  </option>
                </select>
              </div>

              <div class="d-flex flex-row bg-leaderboard col-6 p-1 mx-1"  id="mobileSportTypesMdDevices" >
                <a
                  v-for="(item, index) in  allLeaderboards " :key="index"
                  class="align-content-center mx-0"
                  style="cursor: pointer; font-size: 12px;"
                  :data-id="index"
                  @click="updateSportsType(item.id)"
                >
                  <span :class="selectedSportsType === item.id ? 'bg-warning' : ' '" class=" text-dark">{{ item.id=='transitionTime' ? 'TT' : item.name }} </span>
                </a>    
              </div>

              <div class="d-none col-6 p-0 m-0" id="mobileSportTypesSmDevices">
 
                  <select @change="updateSportsType($event.target.value)" 
                  style="width: -webkit-fill-available"
                  class="bg-leaderboard mx-1 p-2">
                  <option
                  v-for="(item, k) in allLeaderboards"
                  :value="item.id"
                  :key="k"
                  
                  >
                    <strong>{{ item.id=='transitionTime' ? 'TT' :item.name }}</strong>
                  </option>
                </select>
                 
              </div>


            </div>

         

        
      </div>

      <div class="individual mb-8" v-if="!isMobileDevice">
        <div class="container">
          <div class="d-flex justify-content-between pb-4 text-uppercase customBreadcrums">
            <h3>
              Results / {{ eventData?.name }} - {{ mapUserGender(eventData?.gender) }} /
              {{ getRouteName(eventData, selectedDayId) }}
            </h3>

            <div class="d-flex flex-row gap-4">
              <div v-for="(item, index) in getLeaderboards" :key="index" class="d-flex mx-2" style="font-size: 16px;">
                <p>{{ item.name }}: </p>
                <p class="font-weight-bolder mx-1"> {{ item?.totalDistance }}</p>
              </div>               
            </div>


          </div>

          <div class="container-fluid">
            <div class="row d-flex justify-content-between pb-2">
              
              <!-- Stages -->
              <div class="d-flex">
                <select @change="updateStage($event.target.value)" class="bg-leaderboard selectStagesDropdown">
                  <option
                  v-for="(stage, k) in getStages"
                  :value="stage.value"
                  :key="k"
                  >
                    <strong>{{ stage.label }}</strong>
                  </option>
                </select>
            </div>

            <!-- Stage Segments/Leaderboards (SportsType) -->
              <div class="d-flex flex-row bg-leaderboard gap-2">
                    <a
                      v-for="(item, index) in  allLeaderboards " :key="index"
                      class="p-2"
                      style="cursor: pointer;"
                      :data-id="index"
                      @click="updateSportsType(item.id)"
                    >
                      <span :class="selectedSportsType === item.id ? 'bg-warning' : ' '" class="p-1 text-dark">{{ item.name }} </span>
                    </a>    
              </div>

            </div>
          </div>
 
        </div>
      </div>
      <!--end advance sections -->

  
      <!-- DATA TABLE STAT -->
        <DataTableTriathlon 
        :eventData="eventData"
        :selectedSportsType="selectedSportsType"
        :selectedStageNo="selectedStageNo"
        :isMobileDevice="isMobileDevice"
        />
      <!--DATA TABLE END-->

      <!--load btn start-->
      <div class="load-btn-wrpr">
        <router-link :to="{ name: 'EventResultListPage' }" class="loadmore"
          >GO BACK
        </router-link>
      </div>
      <!--load btn end-->
    </div>

    <div class="progress-bar-wrap">
      <div id="progress-bar" style="--scroll: 0%"></div>
    </div>
    <div class="clearfix"></div>
  </div>
</template>

<script>
import "@/theme/style/splide-default.min.css";
import DataTableTriathlon from "@/views/components/DataTable-Triathlon.vue";
 
import {
  getDistanceAndElevation,
  getRouteName,
  mapUserGender,
  getUniqueLeaderBoards,
  getAllStages,
 

} from "@/shared/events/event.helpers.ts";

export default {
  name: "TriathlonEventResult",
  props: ["eventData"],
  components: {
    DataTableTriathlon,
  },
  data() {
    return {

      overallLeaderboard: {
        id: 'overall',
        name: 'Overall'
      },
      transitionTimeLeaderboard: {
        id: 'transitionTime',
        name: 'Transition Time'
      },
      allLeaderboards: [],


      stages: [{ label: "Overall Stages", value: 0 }],
      selectedStageNo: 0, //default value
     

      categories: [],
      selectedCategory: 0,


      selectedSportsType: 'overall',

      mapUserGender: mapUserGender,
      getRouteName: getRouteName,
      getDistanceAndElevation: getDistanceAndElevation,
      isMobileDevice: false,
      isLive: 0, 

      selectedDayId: "",
    };
  },
  computed: {
    getLeaderboards() {
     return getUniqueLeaderBoards(this.eventData,this.selectedSportsType, this.selectedDayId)
    },
    getCategories() {
      return this.eventData?.categories?.map(({ CategoryId, Label }) => ({ CategoryId, Label }));
    },
    getStages() {
      return getAllStages(this.stages, this.eventData)
    },
    getDays(){
      return this.eventData?.ListOfDays?.map(({DayId, DayNo})=> ({DayId, DayNo})) || []
    }
  },

  async mounted() {



    this.isMobileDevice = window.outerWidth <= 1024;
    this.$nextTick(() => {
      window.addEventListener("resize", this.onResize);
    });

    const { isLive } = this.$route.query;
    this.isLive = isLive ? (isLive === "1" || isLive === "true" ? 1 : 0) : 0;

    //All SportsType Leaderboards including overall and transition time
    this.allLeaderboards = [this.overallLeaderboard, ...this.getLeaderboards, this.transitionTimeLeaderboard]

   
    this.selectedDayId= this.eventData?.ListOfDays?.[0]?.DayId


 
    //default category
    // this.selectedCategory = this.getCategories[0].CategoryId


    //default leaderboard selected - eventId, sportsType, stageId 
    // this.selectedColumns = await this.getOverallORStageSegmentsColumns(this.eventData, this.selectedSportsType, this.selectedStageId)


    //load the results
    // await this.loadResult(this.selectedStageId, this.selectedSportsType, this.selectedCategory);

    // if (this.isLive) {
    //   setInterval(async () => {
    //     await this.filterResult(
    //       this.activeItem.type,
    //       this.activeItem.id,
    //       this.activeItem.lapNo,
    //       false
    //     );
    //   }, 5000);
    // }

 

  },
  methods: {
   
    async updateDay(value){
      this.selectedDayId= value
    },

      updateStage(value) {
         
      this.selectedStageNo = Number(value)
    },

    async updateSportsType(value) {
      this.selectedSportsType = value
       

    },

    async updateCategory(value) {
      this.selectedCategory = value
      
    },

    onResize() {
      this.isMobileDevice = window.outerWidth <= 1024 ? true : false;

     },
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.onResize);
  },
};
</script>

// @ts-nocheck
import config from "../../config/app.config.ts";
import countries from "@/shared/store/json/country.flags.json";
import crypto from "crypto-js";
import { EventFormatType } from "./enums.ts";
import moment from "moment";
import { number } from "yup";

export const mapJersey = (id, maxWidth = "50px", minWidth = "50px") => {
  return `<img src="${config.BASE_URL}/assets/jersey/${id}.png" alt="${id}" style="min-width: ${minWidth};max-width: ${maxWidth}"></img>`;
};

export const mapJerseyImage = (url, maxWidth = "40px", minWidth = "40px") => {
  return `<img src="${url}" style="min-width: ${minWidth};max-width: ${maxWidth}"></img>`;
};

// export function IsMarOnwardsSeriesEvents(event) {
//     // return event.datetime_epoc > config.IS_MAR_ONWARDS_SERIES_EVENT;
//     return event.EventFormatType === EventFormatType.TIME_POINTS_BASED;
// }

export function IsSeriesPointAndTimeBasedEvents(event) {
  // return event.datetime_epoc > config.IS_MAY_ONWARDS_SRC_EVENT;
  return event.EventFormatType === EventFormatType.TIME_POINTS_BASED;
}

// export function isMayOnwardsEvents(event) {
//     // return event.datetime_epoc > config.IS_MAY_ONWARDS_SRC_EVENT;
//     return event.EventFormatType === EventFormatType.TIME_POINTS_BASED;
// }

export function IsSinglePointAndTimeBasedEvents(event) {
  // return event.datetime_epoc > config.IS_MAY_ONWARDS_SRC_EVENT;
  return event.EventFormatType === EventFormatType.TIME_POINTS_BASED;
}

export function IsSinglePointBasedEvents(event) {
  // return event.datetime_epoc > config.IS_MAY_ONWARDS_SRC_EVENT;
  return event.EventFormatType === EventFormatType.POINTS_BASED;
}

export function IsTimeBasedEvent(event) {
  return event.EventFormatType === EventFormatType.TIME_BASED;
}


export function getProfileUrl() {
  // const test = 'https://service14-qa.mywhoosh.com'
  const currentHost = window.location.host;
  const splitUrl = currentHost.split(".");
  if (splitUrl.length < 3 && currentHost.includes("mywhoosh")) {
    return "https://event.mywhoosh.com/user/activities#profile";
  }
  if (currentHost.includes("localhost")) {
    return `https://service20-dev03.my-whoosh.com/user/activities#profile`;
  }
  const domains = ["dev", "dev01", "dev02", "dev03", "qa", "int", "stage"];
  const subDomain = splitUrl[0].split("-")[1];
  const selectedEnv = domains[domains.indexOf(subDomain)];
  return `https://service20-${selectedEnv}.my-whoosh.com/user/activities#profile`;
}

export function isChampionShipEvent(eventId) {
  return ["89a94ee0-4349-418b-b73c-a65f301bdff0", "3d862044-358f-4e83-a392-33e2156d5803"].includes(eventId)
}


export function isUCIEvent(event) {
  return event.EventFormatType === EventFormatType.POINTS_BASED &&
    event.EventType === 8
    ? true
    : false;
}

export function isHandiCapEvent(event) {
  return event.EventFormatType === EventFormatType.TIME_BASED &&
    event.EventType === 7
    ? true
    : false;
}


export function isEleminationRace(event) {
  return event.EventFormatType === EventFormatType.TIME_BASED &&
    event.EventType === 9
    ? true
    : false;
}

export function isRunningEvent(event) {
  return event.EventFormatType === EventFormatType.TIME_BASED &&
    event.SportsModeType === 1
    ? true
    : false;
}

export const mapEventImage = (
  url,
  maxWidth = "360px",
  minWidth = "360px",
  percentage = null
) => {
  if (url) {
    if (percentage) {
      return `<img src="${url}" style="width: ${percentage};" />`;
    }
    return `<img src="${url}" style="min-width: ${minWidth};max-width: ${maxWidth}" />`;
  }
  if (percentage) {
    return `<img src="/assets/placeholder-2.png" style="width: ${percentage};" />`;
  }
  return `<img src="/assets/placeholder-2.png" style="min-width: ${minWidth};max-width: ${maxWidth}" />`;
};

export const mapCategory = (id) => {
  return id === 0 ? "OPEN" : `CAT-${id}`;
};

export const jerseyImageUrl = (id) => {
  return `${config.BASE_URL}/assets/jersey/${id}.png`;
};

export const mapCountryFlag = (id, minWidth = "40px", maxWidth = "40px") => {
  let country = countries.find((c) => c.id === parseInt(id));

  if (country && country.flag) {
    return `<img src="${config.BASE_URL}/assets/flags/${country.flag}.png" alt="${country.name}" style="min-width: ${minWidth}; max-width: ${maxWidth}"></img>`;
  } else {
    country = {
      id: 246,
      name: "United Arab Emirates",
      flag: "united_arab_emirates",
    };

    return `<img src="${config.BASE_URL}/assets/flags/${country.flag}.png" alt="${country.name}" style="min-width: ${minWidth}; max-width: ${maxWidth}"></img>`;
  }
};

export const mapUserGender = (gender) => {
  let gn;
  switch (gender) {
    case 0:
      gn = "Men";
      break;
    case 1:
      gn = "Women";
      break;
    case 2:
      gn = "Both";
      break;
    default:
      gn = "";
      break;
  }
  return gn;
};

export const mapUserGenderColor = (gender) => {
  let gn;
  switch (gender) {
    case 0:
      gn = {
        color: "#00B2E3",
        gender: 0,
      };
      break;
    case 1:
      gn = {
        color: "#EA5F28",
        gender: 1,
      };
      break;
    case 2:
      gn = {
        color: "#00B2E3",
        gender: 0,
      };
      break;
    default:
      gn = {
        color: "#00B2E3",
        gender: 0,
      };
      break;
  }
  return gn;
};

export const mapCountryName = (id) => {
  let country = countries.find((c) => c.id === id);

  if (country && country.name) {
    return country.name;
  }
  return "unknown";
};
const pad = (num, val) => String(num).padStart(val, '0');
export const formatTime = (time) => {
  let miliSec = String(time % 1000);
  time = (time - miliSec) / 1000;
  let seconds = String(time % 60);
  time = (time - seconds) / 60;
  let minutes = String(time % 60);
  time = (time - minutes) / 60;
  let hours = String(time);

  while (miliSec.length != 3 && miliSec.length < 3 && miliSec.length >= 0) {
    miliSec = pad(miliSec, 3);
  }
  while (seconds.length != 2 && seconds.length < 3 && seconds.length >= 0) {
    seconds = pad(seconds, 2);
  }
  while (minutes.length != 2 && minutes.length < 3 && minutes.length >= 0) {
    minutes = pad(minutes, 2);
  }
  while (hours.length != 2 && hours.length < 3 && hours.length >= 0) {
    hours = pad(hours, 2);
  }
  return `${pad(Math.abs(hours), 2)}:${pad(Math.abs(minutes), 2)}:${pad(Math.abs(seconds), 2)}.${pad(Math.abs(miliSec), 3)}`;
};

export const MinuesToDaysHoursMinsSeconds = (num) => {
  let h = Math.floor(num / 60);
  const d = Math.floor(h / 24);
  h = h - d * 24;
  const m = Math.floor(num % 60);
  const s = Math.floor((num - d * 24 * 60 - h * 60 - m) * 60);
  return `${d > 0 ? d + "d" : ""} ${h > 0 ? h + "h" : ""} ${m + "m"} ${s + "s"
    }`;
};

// export const formatTime = (seconds) => {
//     let delta = Math.abs(seconds) / 1000;
//
//     // calculate (and subtract) whole days
//     const days = Math.floor(delta / 86400);
//     delta -= days * 86400;
//
//     // calculate (and subtract) whole hours
//     let hours = Math.floor(delta / 3600) % 24;
//     delta -= hours * 3600;
//
//     hours = hours + (days * 24); // add days in to hours
//
//     // calculate (and subtract) whole minutes
//     let minutes = (Math.floor(delta / 60) % 60);
//     delta -= minutes * 60;
//
//     // what's left is seconds
//     let sec = delta % 60;  // in theory the modulus is not required
//     let second = sec < 10 ? "0" + sec : sec.toString();
//
//     second = !second.includes('.') ? second + '.000' : second;
//     //const secondsNew = (second.split('.')[1].split('').length < 3 ? second  : second;
//     const secondsNew = second.split('.')[1].split('').length < 3 ? second + '000000' : roundOfMilliSec(second).toString();
//
//     return `${hours < 10 ? '0' + hours : hours}:${minutes < 10 ? '0' + minutes : minutes}:${secondsNew.slice(0, 6)}`;
// }

// export const roundOfMilliSec = (second) => {
//     const [sec, milli] = second.split('.');
//     return sec + '.' + Math.round(Number(milli.substr(0, 3) + '.' + milli.substr(4))).toString();
// }

export const formatName = (name) => {
  return name?.toUpperCase();
};

export const getRouteName = (event, dayId) => {
  return !dayId
    ? event?.route_name
    : event?.routeNames?.find((e) => e.DayId === dayId)?.routeName;
};

export const getDistanceAndElevation = (event, dayId, isElevation = false) => {
  if (!dayId) {
    return {
      elevation: event?.elevation || 0,
      distance: event?.distance || 0
    }
  }
  const currentDayData = event?.ListOfDays?.find((e) => e.DayId === dayId)?.Stages?.find(item => item.DayId == dayId)
  return { elevation: currentDayData?.Elevation || 0, distance: currentDayData?.TotalDistance || 0 }
};

export const wattPerKgFormat = (v) => {
  return parseFloat(v).toFixed(1); // + " W/kg";
};

export const powerFormat = (v) => {
  return parseInt(v); // + " W"
};

export const encryptPayload = (payload) => {
  const cipherKey = "D9436E508087E863"; //this keyshould be same as whoosh_result_api decrypt function
  return crypto.AES.encrypt(JSON.stringify(payload), cipherKey).toString();
};

export const getDateTime = () => {
  let dt = new Date().getTime();
  return Math.floor(dt / 1000);
};

export const setUptoDecimalValue = (value) => {
  const n = 2; //upto 2 decimal places
  return Number(Math.floor(value * Math.pow(10, n)) / Math.pow(10, n)).toFixed(
    n
  );
};

// export const generateGatesWithLaps = (currentDay) => {
//     let duplicateGates: any = []
//     if (currentDay.ListOfGates.length) {
//         currentDay.ListOfGates.map((gateItem, index) => {
//             gateItem = gateItem?.totalRepitition || { ...gateItem, totalRepitition: (index % 2 == 0 ? 2 : 1) }  //if any gate doesnt have totalRepitition it will set it as 1
//             for (let i = 1; i <= gateItem.totalRepitition; i++) {
//                 duplicateGates.push({
//                     id: gateItem.GateId,
//                     order: gateItem.GateOrder,
//                     label: gateItem.GateName + (i != 1 ? " - Lap " + i : ""),
//                     type: 'gate',
//                     icon: "",
//                     lapNo: i
//                 })
//             }
//         })
//     }
//     return (duplicateGates.length ? duplicateGates : []).sort((a, b) => a.order - b.order);
// }

// End of gates generation helper function

export const RewardJerseys = [
  // Overall Leaderboard Jerseys
  { Name: "OverallCat1", Id: "114" },
  { Name: "OverallCat2", Id: "115" },
  { Name: "OverallCat3", Id: "116" },
  { Name: "OverallCat4", Id: "117" },

  // Sprint Leaderboard  Jerseys
  { Name: "SegmentOverallType9Cat1", Id: "118" },
  { Name: "SegmentOverallType9Cat2", Id: "119" },
  { Name: "SegmentOverallType9Cat3", Id: "120" },
  { Name: "SegmentOverallType9Cat4", Id: "121" },

  // Mountain Leaderboard  Jerseys
  { Name: "SegmentOverallType4Cat1", Id: "122" },
  { Name: "SegmentOverallType4Cat2", Id: "123" },
  { Name: "SegmentOverallType4Cat3", Id: "124" },
  { Name: "SegmentOverallType4Cat4", Id: "125" },

  // Youth Leaderboard Jerseys
  { Name: "YouthCat1", Id: "126" },
  { Name: "YouthCat2", Id: "127" },
  { Name: "YouthCat3", Id: "128" },
  { Name: "YouthCat4", Id: "129" },

  // Master Leaderboard Jerseys
  { Name: "MasterCat1", Id: "134" },
  { Name: "MasterCat2", Id: "135" },
  { Name: "MasterCat3", Id: "136" },
  { Name: "MasterCat4", Id: "137" },
];

export const eventCategories = [
  {
    id: 0,
    name: "Open",
  },
  {
    id: 1,
    name: "Cat-1",
  },
  {
    id: 2,
    name: "Cat-2",
  },
  {
    id: 3,
    name: "Cat-3",
  },
  {
    id: 4,
    name: "Cat-4",
  },
];

export const calculateAge = (dobYear) => {
  const currentYear = moment().year();
  return currentYear - dobYear || 0;
};


export const getPrizeMoney = (catId, rank, prizeList) => {
  if (!prizeList) return '-'
  const prizeListData = Object.keys(prizeList) || []
  if (prizeListData?.length > 0 && prizeListData?.includes(`${catId}`)) {
    const ranksData = Object.keys(prizeList[catId])
    if (ranksData?.length > 0 && (ranksData.includes(`${rank}`))) {
      return prizeList[catId][rank]
    }
  }
  return '-'
}

export const getPrizeMoneyList = (category, leaderboard, data) => {
  if (!data) return {}  
  let prizes = {};
  const currency = data['currency']
  const conversionRate= data['conversionRate']
  if (category) {
    // Directly check if category and leaderboard exist to avoid unnecessary iterations
    const categoryData = data[category]?.[leaderboard]?.prizes;
    if (categoryData) { 
      prizes[category] = Object.fromEntries(
        categoryData.map((prize, index) => [index + 1, `${currency} ${(prize/conversionRate).toFixed(0)}`])
      );
    }
    return prizes
  }
  // Iterate over all categories to extract leaderboard prizes
  for (const [categoryKey, categoryData] of Object.entries(data)) {
    const leaderboardData = categoryData[leaderboard]?.prizes;
    if (leaderboardData) { 
      prizes[categoryKey] = Object.fromEntries(
        leaderboardData.map((prize, index) => [index + 1, `${currency} ${(prize/conversionRate).toFixed(0)}`])
      );
    }
  }
  return prizes
}


export const getSelectedLeaderboard= (activeItem)=>{

  const {type, gateType} = activeItem

  if([4,9].includes(gateType)){ 
      if(type ==='overallgate'){
          return gateType  //for overall leaderboards like sprint, kom
      }
    return 'segments'   //for each segment
  }

  return activeItem?.id //return teams, individual etc
}

export const getCategoryRank = (players) => {
  const cat = {}
  players.map(p => {
    if (p.selectionStatus === "undefined" || !p.selectionStatus) {
      if (!cat[p.categoryId]) {
        cat[p.categoryId] = 0;
      }
      p.categoryRank = cat[p.categoryId] + 1;
      cat[p.categoryId]++;
    } else {
      p.categoryRank = 0;
    }
    return p;
  })
  return players

}


export const getRedirectionUrl = ({ isAuthLinks }) => {
  const currentHost = window.location.host;
  const splitUrl = currentHost.split(".");
  if (splitUrl.length < 3 && currentHost.includes("mywhoosh")) {
    return `https://event.mywhoosh.com/${isAuthLinks ? "" : "user"}`;
  }
  if (currentHost.includes("localhost")) {
    return `https://service20-dev03.my-whoosh.com/${isAuthLinks ? "" : "user"}`;
  }

  if (currentHost.includes("mywhoosh")) {
    return `https://event.mywhoosh.com/${isAuthLinks ? "" : "user"}`;
  }

  const domains = ["dev", "dev01", "dev02", "dev03", "qa", "int", "stage"];
  const subDomain = splitUrl[0].split("-")[1];
  const selectedEnv = domains[domains.indexOf(subDomain)];
  return `https://service20-${selectedEnv}.my-whoosh.com/${isAuthLinks ? "" : "user"
    }`;
};

export function isTraithlonEvent(event) {
  if (typeof event?.SportsModeType === 'object') {
    return event?.SportsModeType?.length > 1 ? true : false
  }
  return false
}

export function isPointBasedEvent(event) {
  return (event.EventFormatType === EventFormatType.POINTS_BASED) || false
}



export function getSportsType(type) {
  switch (type) {
    case 0:
      return 'Ride'
    case 1:
      return 'Run'
    case 2:
      return 'Swim'
    default:
      return ''
  }
}

export function getSportTypeValue(type) {
  switch (type) {
    case 'ride':
      return '0'
    case 'run':
      return '1'
    case 'swim':
      return '2'
    default:
      return type
  }
}

export const getUniqueLeaderBoards = (event, sportsType, dayId = "") => {


  const day = dayId != "" ? event.ListOfDays.find(item => item.DayId == dayId) : event.ListOfDays?.[0]

  const stages = day?.Stages || []


  // Compute index size (divide stages by sports mode types and round up)
  const indexSize = Math.ceil(stages?.length / event?.SportsModeType?.length);
  let stagesDict = {};
  let index = 1;
  let overallKey = []; // Stores first element from each stage group

  let uniqueStages = new Map(); // To store unique SportsType objects


  for (let i = 0; i < stages?.length; i += indexSize) {
    // Push elements up to indexSize per key, keeping only relevant properties
    let stageGroup = stages.slice(i, i + indexSize).map(stage => ({
      id: getSportsType(stage.SportType).toLowerCase(),
      name: getSportsType(stage.SportType),
      totalDistance: `${(stage.TotalDistance).toFixed(2)} Km`
    }));

    stagesDict[index] = stageGroup;

    // Add unique objects to overallKey based on StageId
    stageGroup.forEach(stage => {
      if (!uniqueStages.has(stage.sportsType)) {
        uniqueStages.set(stage.id, stage);
      }
    });
    // Increment key for the next batch
    index++;
  }

  // Add overall to stagesDict
  stagesDict["overall"] = Array.from(uniqueStages.values());
  return stagesDict?.[sportsType] || []



  // return Object.values(days?.flatMap(day => day.Stages).reduce((acc, stage) => {
  //   if (!acc[stage.SportType]) {
  //     acc[stage.SportType] = {
  //       id: getSportsType(stage.SportType).toLowerCase(),
  //       name: getSportsType(stage.SportType),
  //       totalDistance: `${(stage.TotalDistance).toFixed(2)} Km`
  //     };
  //   }
  //   return acc;
  // }, {}))


}










export function isValueEpochTime(timestamp) {

  // Reject if the timestamp length is less than 4 digits
  if (typeof timestamp == 'number' && timestamp.toString().length >= 4) {
    return true;
  }

  return false


}

export function getAllStages(defaultStage, event) {
  const maxCount = Math.ceil(event?.ListOfDays?.[0]?.Stages?.length / event?.SportsModeType?.length)

  return [...defaultStage, ...Array.from({ length: maxCount }, (_, i) => ({
    label: `Stage ${i + 1}`,
    value: Number(i + 1),
    updateResultValue: event?.ListOfDays?.[0]?.Stages[i]?.StageId
  }))]
}



//ADMIN HELPERS

export function getDate(dateTime) {
  const dt = new Date(dateTime.replace(" ", "T"));
  return dt.toDateString();
}


export function getStageDate(currentDay) {
  return new Date(currentDay?.StartTime * 1000).toDateString();
}

export function convertISODateTime(isoDate) {
  const dateTime = new Date(isoDate);
  return dateTime.toLocaleString();

}


